export interface IUser {
    id: string;
    name: string;
    email: string;
    organisation?: string;
}

export interface IGroup {
    id: string;
    name: string;
    description: string;
    role: ERole;
}

export enum ERole {
    ADMIN = "admin",
    VIEWER = "viewer",
}

export interface IUserInGroup {
    id: string;
    title: string;
    role: string;
}
