import { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { IHotspot } from "../../models/hotspot.models";
import { Params, useNavigate, useParams } from "react-router";
import {
    VTHTMLHotspot,
    VTHTMLHotspotViewerMesh,
    VTHTMLHotspotWithControlsMesh,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackInput,
    setCurrentFeedbackVideo,
    setCurrentHotspot,
    setHotspotAdded,
} from "../../store/slices/current.slice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Vector3 } from "three";
import { IVTHotspotProps } from "./VTHotspot";

function hotspotHasPostClickContent(hotspot: IHotspot): boolean {
    let hasPostClickContent = false;
    if (
        hotspot.contents &&
        (Boolean(hotspot.contents.postClickContent?.icon) || Boolean(hotspot.contents.postClickContent?.title))
    ) {
        hasPostClickContent = true;
    }

    if (
        hotspot.contents &&
        hotspot.contents.postClickContent?.contentData &&
        hotspot.contents.postClickContent?.contentData.replace(/\s+/g, "") !== `<divclass="rte-container"></div>`
    ) {
        hasPostClickContent = true;
    }

    return hasPostClickContent;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Renders the different hotspots
export function VTHotspotHTMLWrapper({
    hotspot,
    handleSaveHotspotLocation,
    handleDeleteHotspotLocation,
    handleProceedVideo,
    handleChangeScene,
    currentAttachedObject,
    setCurrentAttachedObject,
    videoCurrentState,
}: IVTHotspotProps) {
    const ref: any = useRef<any | null>();
    const navigate = useNavigate();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useAppDispatch();

    const {
        currentAttachedHotspot,
        currentHotspot,
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        hotspotAdded,
    } = useAppSelector((state) => state.currentReducer);

    const vtHtmlHotspotContainerRef = useRef<THREE.Mesh | null>(null);

    const [openHotspot, setOpenHotspot] = useState<boolean>(false);
    const [hotspotHovered, setHotspotHovered] = useState<boolean>(false);
    const [proceedMoveScenes, setProceedMoveScenes] = useState<string | undefined>(undefined);
    const { currentlyPreview } = useAppSelector((state) => state.currentReducer);

    // this takes a hotspot and see's if it matches a current attached hotspot (at the time of dropped into scene),
    // then applies the setCurrentAttachedObject ref of that hotspot (child) so it can be positioned
    useEffect(() => {
        if (currentAttachedHotspot && currentAttachedHotspot.id && currentAttachedHotspot.id === hotspot.id) {
            setCurrentAttachedObject(ref);

            // is this line of code here doing anything?
            dispatch(setCurrentAttachedHotspot({} as IHotspot));
        }
    }, [currentAttachedHotspot]);

    useEffect(() => {
        if (ref.current) {
            ref.current.lookAt(0, 0, 0);
        }
    });

    function handleEditHotspotLocation() {
        setCurrentAttachedObject(ref);
    }

    function handleCancelHotspotLocation() {
        setCurrentAttachedObject(undefined);
        // if hotspot is mounted for first time...
        // delete it from the scene in the current scenario
    }

    useEffect(() => {
        if (!hotspotHasPostClickContent(hotspot)) {
            setOpenHotspot(false);
        }
    }, [hotspot]);

    const onRefChange = useCallback((node: THREE.Mesh | null) => {
        // if (node) {
        //     node.geometry.computeBoundingBox();
        // }
    }, []);

    const hotspotIsSelected = currentHotspot.currentHotspot && currentHotspot.currentHotspot.id === hotspot.id;

    return (
        <group ref={ref} position={new THREE.Vector3(hotspot.location?.x, hotspot.location?.y, hotspot.location?.z)}>
            <mesh
                scale={25 * (hotspot.styles?.postClickStyles?.scale ? hotspot.styles.postClickStyles.scale : 1)}
                ref={vtHtmlHotspotContainerRef}
            >
                {!openHotspot && hotspot.contents ? (
                    <>
                        {currentlyPreview ? (
                            <VTHTMLHotspotViewerMesh // transform={false} // to face camera set transform to false
                                editIconAsSettings={hotspotIsSelected}
                                borderOn={hotspotIsSelected}
                                onHover={(mouseEvent: boolean) => {
                                    setHotspotHovered(mouseEvent);
                                }}
                                sx={
                                    hotspotHovered
                                        ? {
                                              "& .css-1y00ugn": {
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  h3: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      } !important`,
                                                  },
                                              },
                                              "& .css-1kcrrit": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                          }
                                        : {
                                              "& .css-1y00ugn": {
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                  }`,
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  h3: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                              : "#fff"
                                                      } !important`,
                                                  },
                                              },
                                              "& .css-1kcrrit": {
                                                  background: `${
                                                      hotspot.styles?.preClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.preClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.preClickStyles?.colours?.text
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                          }
                                }
                                ref={onRefChange}
                                position={new Vector3(0, 0, 0)}
                                title={hotspot.contents.preClickContent.title}
                                // @ts-ignore
                                icon={hotspot.contents.preClickContent.icon}
                                scale={0.5}
                                scale2d={hotspot.styles?.preClickStyles?.scale}
                                vtRichTextEditorContent={
                                    hotspot.contents.preClickContent.contentData &&
                                    hotspot.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                        `<divclass="rte-container"></div>`
                                        ? {
                                              content: hotspot.contents.preClickContent.contentData,
                                          }
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        hotspot.contents.postClickContent &&
                                        (hotspot.contents.postClickContent?.title ||
                                            hotspot.contents.postClickContent?.icon ||
                                            hotspot.contents.postClickContent?.contentData)
                                    ) {
                                        setOpenHotspot(true);
                                    }
                                    if (ref.current && hotspot.styles?.postClickStyles?.lookAt) {
                                        ref.current.lookAt(
                                            hotspot.styles?.postClickStyles.lookAt.x,
                                            hotspot.styles?.postClickStyles.lookAt.y,
                                            hotspot.styles?.postClickStyles.lookAt.z,
                                        );
                                    }
                                    if (hotspot.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                                    }
                                    if (hotspot.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                                    }
                                    if (hotspot.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                                    }
                                    if (hotspot.actions?.appFeedback?.input) {
                                        dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                    }
                                    if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                                    }
                                    if (hotspot.actions?.navigate) {
                                        if (hotspot.actions?.navigate === "/") {
                                            navigate("/");
                                        } else {
                                            window.open(hotspot.actions?.navigate);
                                        }
                                    }
                                    if (hotspot.actions?.autoClose) {
                                        const timer = setTimeout(
                                            () => {
                                                setOpenHotspot(false);
                                            },
                                            hotspot.actions?.autoClose * 1000,
                                        );
                                        return () => clearTimeout(timer);
                                    }
                                    if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                        if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                hotspot.actions?.movement?.sceneId,
                                                hotspot.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                }}
                                handleEditHotspotLocation={handleEditHotspotLocation}
                                handleSaveHotspotLocation={() => {
                                    if (hotspotAdded && hotspotAdded.id === hotspot.id) {
                                        dispatch(setHotspotAdded(undefined));
                                    }
                                    handleSaveHotspotLocation &&
                                        handleSaveHotspotLocation(
                                            hotspot.id,
                                            currentAttachedObject && currentAttachedObject.current
                                                ? currentAttachedObject.current.position
                                                : "o",
                                            videoCurrentState,
                                        );
                                }}
                                handleCancelHotspotLocation={
                                    hotspotAdded && hotspotAdded.id === hotspot.id
                                        ? () => handleDeleteHotspotLocation(hotspot.id)
                                        : handleCancelHotspotLocation
                                }
                                handleDeleteHotspotLocation={() => {
                                    handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                                }}
                                handleEditHotspot={() => {
                                    if (
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id === hotspot.id
                                    ) {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: true,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: false,
                                            }),
                                        );
                                    }
                                }}
                                moving={currentAttachedObject === ref}
                            />
                        ) : (
                            <VTHTMLHotspotWithControlsMesh
                                // transform={false} // to face camera set transform to false
                                editIconAsSettings={hotspotIsSelected}
                                borderOn={hotspotIsSelected}
                                onHover={(mouseEvent: boolean) => {
                                    setHotspotHovered(mouseEvent);
                                }}
                                sx={
                                    hotspotHovered
                                        ? {
                                              "> div:nth-child(2)": {
                                                  "> div:nth-child(1) > div": {
                                                      border: `3px solid ${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                          `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                      }`,
                                                      background: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      h3: {
                                                          color: `${
                                                              hotspot.styles?.preClickStyles?.hover?.colours?.text
                                                                  ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.text.a})`
                                                                  : "#fff"
                                                          } !important`,
                                                      },
                                                  },
                                                  "> div:nth-child(2) > div": {
                                                      background: `${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.background
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      border: `3px solid ${
                                                          hotspot.styles?.preClickStyles?.hover?.colours?.border &&
                                                          `rgba(${hotspot.styles?.preClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.hover?.colours?.border.a})`
                                                      }`,
                                                      div: {
                                                          color: "blue",
                                                      },
                                                  },
                                              },
                                          }
                                        : {
                                              "> div:nth-child(2)": {
                                                  "> div:nth-child(1) > div":
                                                      (hotspot.styles?.preClickStyles?.colours?.border === undefined ||
                                                          (hotspot.styles?.preClickStyles?.colours?.border !==
                                                              undefined &&
                                                              hotspot.styles?.preClickStyles?.colours?.border.a ===
                                                                  0)) &&
                                                      hotspot.styles?.preClickStyles?.colours?.background !==
                                                          undefined &&
                                                      hotspot.styles?.preClickStyles?.colours?.background.a === 0 &&
                                                      hotspot.styles?.preClickStyles?.colours?.text !== undefined &&
                                                      hotspot.styles?.preClickStyles?.colours?.text.a === 0
                                                          ? {
                                                                border: `3px solid ${
                                                                    hotspot.styles?.preClickStyles?.colours?.border &&
                                                                    `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                                }`,
                                                                background: `${
                                                                    hotspot.styles?.preClickStyles?.colours?.background
                                                                        ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                                        : "rgba(30,30,30,0.5)"
                                                                } `,
                                                                h3: {
                                                                    color: `${
                                                                        hotspot.styles?.preClickStyles?.colours?.text
                                                                            ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                                            : "#fff"
                                                                    } !important`,
                                                                },
                                                                boxShadow: "0 0 25px #fff",
                                                            }
                                                          : {
                                                                border: `3px solid ${
                                                                    hotspot.styles?.preClickStyles?.colours?.border &&
                                                                    `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                                }`,
                                                                background: `${
                                                                    hotspot.styles?.preClickStyles?.colours?.background
                                                                        ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                                        : "rgba(30,30,30,0.5)"
                                                                } `,
                                                                h3: {
                                                                    color: `${
                                                                        hotspot.styles?.preClickStyles?.colours?.text
                                                                            ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                                            : "#fff"
                                                                    } !important`,
                                                                },
                                                            },
                                                  "> div:nth-child(2) > div": {
                                                      background: `${
                                                          hotspot.styles?.preClickStyles?.colours?.background
                                                              ? `rgba(${hotspot.styles?.preClickStyles?.colours?.background.r}, ${hotspot.styles?.preClickStyles?.colours?.background.g}, ${hotspot.styles?.preClickStyles?.colours?.background.b}, ${hotspot.styles?.preClickStyles?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      border: `3px solid ${
                                                          hotspot.styles?.preClickStyles?.colours?.border &&
                                                          `rgba(${hotspot.styles?.preClickStyles?.colours?.border.r}, ${hotspot.styles?.preClickStyles?.colours?.border.g}, ${hotspot.styles?.preClickStyles?.colours?.border.b}, ${hotspot.styles?.preClickStyles?.colours?.border.a})`
                                                      }`,
                                                      div: {
                                                          color: `${
                                                              hotspot.styles?.preClickStyles?.colours?.text
                                                                  ? `rgba(${hotspot.styles?.preClickStyles?.colours?.text.r}, ${hotspot.styles?.preClickStyles?.colours?.text.g}, ${hotspot.styles?.preClickStyles?.colours?.text.b}, ${hotspot.styles?.preClickStyles?.colours?.text.a})`
                                                                  : "#fff"
                                                          }`,
                                                      },

                                                      boxShadow: "0 0 15px #fff",
                                                  },
                                              },
                                          }
                                }
                                ref={onRefChange}
                                position={new Vector3(0, 0, 0)}
                                title={hotspot.contents.preClickContent.title}
                                // @ts-ignore
                                icon={hotspot.contents.preClickContent.icon}
                                scale2d={hotspot.styles?.preClickStyles?.scale}
                                scale={0.5}
                                vtRichTextEditorContent={
                                    hotspot.contents.preClickContent.contentData &&
                                    hotspot.contents.preClickContent.contentData.replace(/\s+/g, "") !==
                                        `<divclass="rte-container"></div>`
                                        ? {
                                              content: hotspot.contents.preClickContent.contentData,
                                          }
                                        : undefined
                                }
                                onClick={() => {
                                    if (
                                        hotspot.contents.postClickContent &&
                                        (hotspot.contents.postClickContent?.title ||
                                            hotspot.contents.postClickContent?.icon ||
                                            hotspot.contents.postClickContent?.contentData)
                                    ) {
                                        setOpenHotspot(true);
                                    }
                                    if (ref.current && hotspot.styles?.postClickStyles?.lookAt) {
                                        ref.current.lookAt(
                                            hotspot.styles?.postClickStyles.lookAt.x,
                                            hotspot.styles?.postClickStyles.lookAt.y,
                                            hotspot.styles?.postClickStyles.lookAt.z,
                                        );
                                    }
                                    if (hotspot.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                                    }
                                    if (hotspot.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                                    }
                                    if (hotspot.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                                    }
                                    if (hotspot.actions?.appFeedback?.input) {
                                        dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                    }
                                    if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                                    }
                                    if (hotspot.actions?.navigate) {
                                        if (hotspot.actions?.navigate === "/") {
                                            navigate("/");
                                        } else {
                                            window.open(hotspot.actions?.navigate);
                                        }
                                    }
                                    if (hotspot.actions?.autoClose) {
                                        const timer = setTimeout(
                                            () => {
                                                setOpenHotspot(false);
                                            },
                                            hotspot.actions?.autoClose * 1000,
                                        );
                                        return () => clearTimeout(timer);
                                    }
                                    if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                        if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                hotspot.actions?.movement?.sceneId,
                                                hotspot.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                }}
                                handleEditHotspotLocation={handleEditHotspotLocation}
                                handleSaveHotspotLocation={() => {
                                    if (hotspotAdded && hotspotAdded.id === hotspot.id) {
                                        dispatch(setHotspotAdded(undefined));
                                    }
                                    handleSaveHotspotLocation &&
                                        handleSaveHotspotLocation(
                                            hotspot.id,
                                            currentAttachedObject && currentAttachedObject.current
                                                ? currentAttachedObject.current.position
                                                : "o",
                                            videoCurrentState,
                                        );
                                }}
                                handleCancelHotspotLocation={
                                    hotspotAdded && hotspotAdded.id === hotspot.id
                                        ? () => handleDeleteHotspotLocation(hotspot.id)
                                        : handleCancelHotspotLocation
                                }
                                handleDeleteHotspotLocation={() => {
                                    handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                                }}
                                handleEditHotspot={() => {
                                    if (
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id === hotspot.id
                                    ) {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: true,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: false,
                                            }),
                                        );
                                    }
                                }}
                                moving={currentAttachedObject === ref}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {currentlyPreview ? (
                            <VTHTMLHotspotViewerMesh
                                editIconAsSettings={hotspotIsSelected}
                                borderOn={hotspotIsSelected}
                                onHover={(mouseEvent: boolean) => {
                                    setHotspotHovered(mouseEvent);
                                }}
                                // borderColour={
                                //     hotspot.styles?.postClickStyles?.colours?.background &&
                                //     `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                // }
                                sx={
                                    hotspotHovered
                                        ? {
                                              "& .css-1y00ugn": {
                                                  border: `3px solid ${
                                                      hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  background: `${
                                                      hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  h3: {
                                                      color: `${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      } !important`,
                                                  },
                                              },
                                              "& .css-1kcrrit": {
                                                  background: `${
                                                      hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                          ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                                      `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                          }
                                        : {
                                              "& .css-1y00ugn": {
                                                  border: `3px solid ${
                                                      hotspot.styles?.postClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                                  }`,
                                                  background: `${
                                                      hotspot.styles?.postClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  h3: {
                                                      color: `${
                                                          hotspot.styles?.postClickStyles?.colours?.text
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                              : "#fff"
                                                      } !important`,
                                                  },
                                              },
                                              "& .css-1kcrrit": {
                                                  background: `${
                                                      hotspot.styles?.postClickStyles?.colours?.background
                                                          ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                          : "rgba(30,30,30,0.5)"
                                                  } `,
                                                  border: `3px solid ${
                                                      hotspot.styles?.postClickStyles?.colours?.border &&
                                                      `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                                  }`,
                                                  div: {
                                                      color: `${
                                                          hotspot.styles?.postClickStyles?.colours?.text
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                              : "#fff"
                                                      }`,
                                                  },
                                              },
                                          }
                                }
                                position={new Vector3(0, 0, 0)}
                                title={hotspot.contents ? hotspot.contents.postClickContent?.title : undefined}
                                // @ts-ignore
                                icon={hotspot.contents ? hotspot.contents.postClickContent?.icon : undefined}
                                scale={0.5}
                                scale2d={hotspot.styles?.postClickStyles?.scale}
                                vtRichTextEditorContent={
                                    hotspot.contents &&
                                    hotspot.contents.postClickContent?.contentData &&
                                    hotspot.contents.postClickContent.contentData.replace(/\s+/g, "") !==
                                        `<divclass="rte-container"></div>`
                                        ? {
                                              content: hotspot.contents.postClickContent?.contentData,
                                          }
                                        : undefined
                                }
                                onClick={() => {
                                    if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                        if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                hotspot.actions?.movement?.sceneId,
                                                hotspot.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                    if (hotspot.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                                    }
                                    if (hotspot.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                                    }
                                    if (hotspot.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                                    }
                                    if (hotspot.actions?.appFeedback?.input) {
                                        dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                    }
                                    if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                                    }
                                    if (hotspot.actions?.navigate) {
                                        window.open(hotspot.actions?.navigate);
                                    }
                                }}
                                onClose={
                                    hotspot.actions?.closeable
                                        ? () => {
                                              setOpenHotspot(false);
                                          }
                                        : undefined
                                }
                                handleEditHotspotLocation={handleEditHotspotLocation}
                                handleSaveHotspotLocation={() => {
                                    handleSaveHotspotLocation &&
                                        handleSaveHotspotLocation(
                                            hotspot.id,
                                            currentAttachedObject && currentAttachedObject.current
                                                ? currentAttachedObject.current.position
                                                : "o",
                                            videoCurrentState,
                                        );
                                }}
                                handleCancelHotspotLocation={handleCancelHotspotLocation}
                                handleDeleteHotspotLocation={() => {
                                    handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                                }}
                                handleEditHotspot={() => {
                                    if (
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id === hotspot.id
                                    ) {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: true,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: false,
                                            }),
                                        );
                                    }
                                }}
                                moving={currentAttachedObject === ref}
                            />
                        ) : (
                            <VTHTMLHotspotWithControlsMesh
                                editIconAsSettings={hotspotIsSelected}
                                borderOn={hotspotIsSelected}
                                onHover={(mouseOver: boolean) => {
                                    setHotspotHovered(mouseOver);
                                }}
                                sx={
                                    hotspotHovered
                                        ? {
                                              "> div:nth-child(2)": {
                                                  "> div:nth-child(1) > div": {
                                                      border: `3px solid ${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                                          `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                                      }`,
                                                      background: `${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      h3: {
                                                          color: `${
                                                              hotspot.styles?.postClickStyles?.hover?.colours?.text
                                                                  ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.text.a})`
                                                                  : "#fff"
                                                          } !important`,
                                                      },
                                                  },
                                                  "> div:nth-child(2) > div": {
                                                      background: `${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.background
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      border: `3px solid ${
                                                          hotspot.styles?.postClickStyles?.hover?.colours?.border &&
                                                          `rgba(${hotspot.styles?.postClickStyles?.hover?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.hover?.colours?.border.a})`
                                                      }`,
                                                      div: {
                                                          color: "blue",
                                                      },
                                                  },
                                              },
                                          }
                                        : {
                                              "> div:nth-child(2)": {
                                                  "> div:nth-child(1) > div": {
                                                      border: `3px solid ${
                                                          hotspot.styles?.postClickStyles?.colours?.border &&
                                                          `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                                      }`,
                                                      background: `${
                                                          hotspot.styles?.postClickStyles?.colours?.background
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      h3: {
                                                          color: `${
                                                              hotspot.styles?.postClickStyles?.colours?.text
                                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                                  : "#fff"
                                                          } !important`,
                                                      },
                                                  },
                                                  "> div:nth-child(2) > div": {
                                                      background: `${
                                                          hotspot.styles?.postClickStyles?.colours?.background
                                                              ? `rgba(${hotspot.styles?.postClickStyles?.colours?.background.r}, ${hotspot.styles?.postClickStyles?.colours?.background.g}, ${hotspot.styles?.postClickStyles?.colours?.background.b}, ${hotspot.styles?.postClickStyles?.colours?.background.a})`
                                                              : "rgba(30,30,30,0.5)"
                                                      } `,
                                                      border: `3px solid ${
                                                          hotspot.styles?.postClickStyles?.colours?.border &&
                                                          `rgba(${hotspot.styles?.postClickStyles?.colours?.border.r}, ${hotspot.styles?.postClickStyles?.colours?.border.g}, ${hotspot.styles?.postClickStyles?.colours?.border.b}, ${hotspot.styles?.postClickStyles?.colours?.border.a})`
                                                      }`,
                                                      div: {
                                                          color: `${
                                                              hotspot.styles?.postClickStyles?.colours?.text
                                                                  ? `rgba(${hotspot.styles?.postClickStyles?.colours?.text.r}, ${hotspot.styles?.postClickStyles?.colours?.text.g}, ${hotspot.styles?.postClickStyles?.colours?.text.b}, ${hotspot.styles?.postClickStyles?.colours?.text.a})`
                                                                  : "#fff"
                                                          }`,
                                                      },
                                                  },
                                              },
                                          }
                                }
                                position={new Vector3(0, 0, 0)}
                                title={hotspot.contents ? hotspot.contents.postClickContent?.title : undefined}
                                // @ts-ignore
                                icon={hotspot.contents ? hotspot.contents.postClickContent?.icon : undefined}
                                scale={0.5}
                                scale2d={hotspot.styles?.postClickStyles?.scale}
                                vtRichTextEditorContent={
                                    hotspot.contents &&
                                    hotspot.contents.postClickContent?.contentData &&
                                    hotspot.contents.postClickContent.contentData.replace(/\s+/g, "") !==
                                        `<divclass="rte-container"></div>`
                                        ? {
                                              content: hotspot.contents.postClickContent?.contentData,
                                          }
                                        : undefined
                                }
                                onClick={() => {
                                    if (handleChangeScene && hotspot.actions?.movement?.sceneId) {
                                        if (hotspot.actions?.movement?.sceneId !== "Scene Undefined") {
                                            handleChangeScene(
                                                hotspot.actions?.movement?.sceneId,
                                                hotspot.actions?.movement?.delay,
                                            );
                                        }
                                    }
                                    if (hotspot.actions?.appFeedback?.audio) {
                                        dispatch(setCurrentAudio(hotspot.actions?.appFeedback?.audio));
                                    }
                                    if (hotspot.actions?.appFeedback?.video) {
                                        dispatch(setCurrentFeedbackVideo(hotspot.actions?.appFeedback?.video));
                                    }
                                    if (hotspot.actions?.appFeedback?.image) {
                                        dispatch(setCurrentFeedbackImage(hotspot.actions?.appFeedback?.image));
                                    }
                                    if (hotspot.actions?.appFeedback?.input) {
                                        dispatch(setCurrentFeedbackInput(hotspot.actions?.appFeedback?.input));
                                    }
                                    if (handleProceedVideo && hotspot.actions?.appFeedback?.proceed) {
                                        handleProceedVideo(hotspot.actions?.appFeedback?.delay);
                                    }
                                    if (hotspot.actions?.navigate) {
                                        window.open(hotspot.actions?.navigate);
                                    }
                                }}
                                onClose={
                                    hotspot.actions?.closeable
                                        ? () => {
                                              setOpenHotspot(false);
                                          }
                                        : undefined
                                }
                                handleEditHotspotLocation={handleEditHotspotLocation}
                                handleSaveHotspotLocation={() => {
                                    handleSaveHotspotLocation &&
                                        handleSaveHotspotLocation(
                                            hotspot.id,
                                            currentAttachedObject && currentAttachedObject.current
                                                ? currentAttachedObject.current.position
                                                : "o",
                                            videoCurrentState,
                                        );
                                }}
                                handleCancelHotspotLocation={handleCancelHotspotLocation}
                                handleDeleteHotspotLocation={() => {
                                    handleDeleteHotspotLocation && handleDeleteHotspotLocation(hotspot.id);
                                }}
                                handleEditHotspot={() => {
                                    if (
                                        currentHotspot.currentHotspot &&
                                        currentHotspot.currentHotspot.id === hotspot.id
                                    ) {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: true,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            setCurrentHotspot({
                                                currentHotspot: hotspot,
                                                open: false,
                                            }),
                                        );
                                    }
                                }}
                                moving={currentAttachedObject === ref}
                            />
                        )}
                    </>
                )}
            </mesh>
        </group>
    );
}
