//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useState } from "react";

// Material ui Imports
import { SxProps } from "@mui/system";
import { Grid, Typography, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { IDragOptions } from "../../models/current.models";
import { VTButton, VTTextField } from "@virtus-tech-repository/virtus-tech-repository";

// Media imports
import scene3D from "../../assets/images/defaultTemplates/3D-scene.png";
import scene360 from "../../assets/images/defaultTemplates/360-scene.png";
import sceneDocument from "../../assets/images/defaultTemplates/document-scene.png";
import scenePassthrough from "../../assets/images/defaultTemplates/Passthrough.png";
// import scenePassthrough from "../../assets/images/defaultTemplates/passthrough-scene.png";
import VTImageDraggable from "./VTImageDraggable";
import { useAppSelector } from "../../store/hooks";

export const TemplateSceneImg = styled("img")(({ theme }) => ({
    borderRadius: "15px",
    height: "200px",
    width: "100%",
    objectFit: "cover",
    ":hover": {
        cursor: "pointer",
    },
}));

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    handleDragStart?: (mediaType: EDragContentOptions, src: string, preview?: string) => void;
    handleDragStop?: () => void;
    handleCanvasDrop: (Drag: IDragOptions) => void;
    handleQuestionCanvasDrop: (Drag: IDragOptions, hotspotId?: string) => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

export default function VTTemplateSidePanel({
    handleDragStart,
    handleDragStop,
    sx,
    handleCanvasDrop,
    handleQuestionCanvasDrop,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [dragging, setDragging] = useState<boolean>(false);
    const { organisation } = useAppSelector((state) => state.userReducer);
    const { currentQuestion } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDragContentOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string) {
        if (handleQuestionCanvasDrop && handleCanvasDrop) {
            if (videoCurrentState === "Question") {
                handleQuestionCanvasDrop(
                    {
                        mediaType: mediaType,
                        src: src,
                    },
                    currentQuestion.id,
                );
            } else {
                handleCanvasDrop({
                    mediaType: mediaType,
                    src: src,
                });
            }
        }
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            direction="column"
            sx={{ height: "calc(100vh - 70px)", padding: "10px", ...sx }}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid
                item
                xs={1}
                sx={{
                    padding: "10px",
                    width: "100%",
                    paddingTop: "40px",
                }}
            >
                <VTTextField
                    placeholder={"Search Templates"}
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        width: "100%",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid item container xs={1} sx={{ height: "120px", marginTop: "5px" }}>
                <VTButton
                    onClick={() => handleButtonClick(0)}
                    sx={{
                        borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                        maxWidth: "100%",
                        height: "60px",
                    }}
                    type="underline"
                >
                    <Typography>Template Views</Typography>
                </VTButton>

                <VTButton
                    onClick={() => handleButtonClick(1)}
                    sx={{
                        borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                        maxWidth: "100%",
                    }}
                    type="underline"
                >
                    <Typography> Scenarios</Typography>
                </VTButton>
            </Grid>

            <Grid
                item
                container
                spacing={2}
                sx={{
                    display: "flex",
                    flex: "1",
                    overflowY: "auto",
                }}
            >
                <Grid item xs={12}>
                    <VTImageDraggable
                        item={{
                            name: "Blank Scene",
                            src: "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image",
                        }}
                        organisation={organisation}
                        localHandleDrag={localHandleDrag}
                        handleDoubleClick={handleDoubleClick}
                        xs={12}
                        type={EDragContentOptions.IMAGE_360}
                        displaySrc={scene360}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VTImageDraggable
                        item={{
                            name: "Document",
                        }}
                        organisation={organisation}
                        localHandleDrag={localHandleDrag}
                        handleDoubleClick={handleDoubleClick}
                        xs={12}
                        type={EDragContentOptions.DOCUMENT}
                        displaySrc={sceneDocument}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VTImageDraggable
                        item={{
                            name: "Passthrough Scene",
                        }}
                        organisation={organisation}
                        localHandleDrag={localHandleDrag}
                        handleDoubleClick={handleDoubleClick}
                        xs={12}
                        type={EDragContentOptions.PASSTHROUGH}
                        displaySrc={scenePassthrough}
                    />
                </Grid>
                <Grid item xs={12}>
                    <VTImageDraggable
                        item={{
                            name: "Models Scene",
                        }}
                        organisation={organisation}
                        localHandleDrag={localHandleDrag}
                        handleDoubleClick={handleDoubleClick}
                        xs={12}
                        type={EDragContentOptions.MODEL_3D_BASE}
                        displaySrc={scene3D}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
