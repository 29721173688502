//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import { VTButton, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoScenarios from "../../assets/images/NoScenarios.svg";
// Material ui Imports
import { CircularProgress, Grid, LinearProgress, Skeleton, SxProps, Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    useDeleteMediaMutation,
    useGetAllImagesQuery,
    useGetAllImmersiveModelsQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTImageDraggable from "./VTImageDraggable";
import { IDragOptions } from "../../models/current.models";
import { Params, useParams } from "react-router-dom";
// Media imports
import SearchNotFound from "../../assets/images/upload_image.svg";
import VTImmersiveModelDraggable from "./VTImmersiveModelDraggable";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##

// Layout stuff remains inline, other styling pulled into its own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;

    imagesTitle: string;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDragContentOptions, src: string) => void;
    handleDragStop?: () => void;
    handleCanvasDrop?: (Drag: IDragOptions) => void;
    handleQuestionCanvasDrop?: (Drag: IDragOptions, hotspotId?: string) => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveModelsSidePanel({
    sideDrawWidth,
    imagesTitle,
    handleDragStart,
    handleDragStop,
    sx,
    handleCanvasDrop,
    handleQuestionCanvasDrop,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [createAllImages] = useCreateAllImagesMutation();

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const { data: images, isLoading: imagesLoading } = useGetAllImmersiveModelsQuery(userId);
    const { data: scenarioImages, isLoading: scenarioImagesLoading } = useGetAllImagesQuery(scenarioId!);

    const dispatch = useAppDispatch();
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentQuestion } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImages({
                id: userId,
                media_id: imageId,
                media_type: "images",
                filetype: selectedImage.type.split("/")[1],
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(dragged: boolean, mediaType?: EDragContentOptions, src?: string) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && !imageFile.type.includes("/svg")) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "images",
                media_id: imageId,
                filetype: imageFile.type.split("/")[1],
            });
        } else {
            setSelectedImageError(true);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle image added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string) {
        if (handleQuestionCanvasDrop && handleCanvasDrop) {
            if (videoCurrentState === "Question") {
                handleQuestionCanvasDrop(
                    {
                        mediaType: mediaType,
                        src: src,
                    },
                    currentQuestion.id,
                );
            } else {
                handleCanvasDrop({
                    mediaType: mediaType,
                    src: src,
                });
            }
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <Grid container xs={12} alignContent={"flex-start"} justifyContent={"center"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={12}>
                <VTTextField
                    placeholder={"Search Images"}
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        paddingInline: "16px",
                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={12}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    height: "80px",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Images</Typography>
                    </VTButton>
                </Grid>
                {scenarioId && (
                    <Grid item xs={4}>
                        {scenarioImages && (
                            <VTButton
                                onClick={() => handleButtonClick(2)}
                                sx={{
                                    borderBottom: activeButton === 2 ? "3px solid rgb(150, 28, 255)" : "none",
                                    maxWidth: "100%",
                                }}
                                type="underline"
                            >
                                <Typography>Scenario Image</Typography>
                            </VTButton>
                        )}
                    </Grid>
                )}
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Image</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Image upload */}
            {activeButton === 1 && (
                <>
                    <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                        {selectedImage ? (
                            <Grid container>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTTextField
                                        value={imageName}
                                        onChange={(value) => setImageName(value)}
                                        disabled={mediaUploading || mediaUploadCompleted}
                                    />
                                </Grid>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        width="100%"
                                        height="250px"
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Grid>
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedImage(null);
                                                                        setImageId(uuid());
                                                                        setImageName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                >
                                                                    Upload Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedImage(null)}
                                                        fullWidth={true}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton onClick={() => handleImageUpload()} fullWidth={true}>
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                //TODO: user this as an overlay
                                onDragEnter={() => setHighlight(true)}
                                onDragLeave={() => setHighlight(false)}
                                sx={{
                                    border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                    borderRadius: "15px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography align="center">Drop or choose image to upload</Typography>

                                    {selectedImageError && (
                                        <Typography align="center" sx={{ color: "red" }}>
                                            File types accepted: JPG, JPEG, PNG
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                    <VTButton
                                        onClick={() => {
                                            if (inputFileRef && inputFileRef.current) {
                                                inputFileRef.current.click();
                                            }
                                        }}
                                    >
                                        Choose File
                                    </VTButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </>
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}
                    {imagesLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!images || images.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignContent={"flex-start"}
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {images &&
                                    images?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid container>
                                            {images &&
                                                images
                                                    .filter(
                                                        (item: IMediaItem) =>
                                                            searchValue === "" ||
                                                            item
                                                                .name!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()) ||
                                                            item
                                                                .description!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()),
                                                    )
                                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                    })
                                                    .map((item: IMediaItem) => {
                                                        return (
                                                            <VTImmersiveModelDraggable
                                                                key={item.id}
                                                                item={item}
                                                                organisation={organisation}
                                                                localHandleDrag={localHandleDrag}
                                                                handleDoubleClick={handleDoubleClick}
                                                            />
                                                        );
                                                    })}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {scenarioImagesLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioImages || scenarioImages.length === 0 ? (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignContent={"center"}
                                    justifyContent={"center"}
                                    sx={{
                                        paddingTop: "40px",
                                    }}
                                >
                                    {" "}
                                    <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                    <Typography sx={{ color: "grey", padding: "10px" }}>
                                        Add an image to the scenario
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    alignContent={"flex-start"}
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {scenarioImages &&
                                    scenarioImages?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid container>
                                            {scenarioImages &&
                                                scenarioImages
                                                    .filter(
                                                        (item: IMediaItem) =>
                                                            searchValue === "" ||
                                                            item
                                                                .name!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()) ||
                                                            item
                                                                .description!.toLowerCase()
                                                                .includes(searchValue.toLocaleLowerCase()),
                                                    )
                                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                    })
                                                    .map((item: IMediaItem) => {
                                                        return (
                                                            <>
                                                                {(updateLoading || deleteLoading) &&
                                                                currentItemUpdate == item.id ? (
                                                                    <Grid item xs={6}>
                                                                        <Skeleton
                                                                            sx={{
                                                                                height: "300px",
                                                                                margin: "10px",
                                                                                marginBlock: "-25px",
                                                                                marginTop: "-55px",
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                ) : (
                                                                    <VTImmersiveModelDraggable
                                                                        key={item.id}
                                                                        item={item}
                                                                        organisation={organisation}
                                                                        localHandleDrag={localHandleDrag}
                                                                        handleDoubleClick={handleDoubleClick}
                                                                        addFunction={() => {
                                                                            setCurrentItemUpdate(item.id);
                                                                            updateAllScenarioMedia({
                                                                                id: userId,
                                                                                media_type: EMedia.IMAGE,
                                                                                media_ids: [item.id],
                                                                            });
                                                                        }}
                                                                        deleteFunction={() => {
                                                                            setCurrentItemUpdate(item.id);
                                                                            deleteAllScenarioMedia({
                                                                                mediaType: EMedia.IMAGE,
                                                                                collectionId: scenarioId!,
                                                                                mediaId: item.id,
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
