//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Card, CardMedia, Grid, Skeleton, SxProps } from "@mui/material";
// Custom components imports (and hooks and helpers)

import { Params, useNavigate, useParams } from "react-router-dom";
import { IScenario, IScene } from "../../models/scenario.models";
import { VTTimelineAddScene } from "@virtus-tech-repository/virtus-tech-repository";
import VTTimelineImageItem from "./VTTimelineImageItem";
import VTCreatorDropContainer, { DropPoint } from "./VTCreatorDropContainer";
import { IDragOptions } from "../../models/current.models";
import { useAppSelector } from "../../store/hooks";
import { bottom } from "@popperjs/core";
import VTCreatorReorderDropContainer from "./VTCreatorReorderDropContainer";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { useEffect } from "react";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    scenario: IScenario | undefined;
    sx?: SxProps;
    addSceneOnClick: () => void;
    setSceneName: (sceneId: string, sceneName: string) => void;
    setSceneDuplicate: (sceneId: string) => void;
    setSceneDelete: (sceneId: string) => void;
    setSceneInitialView: (sceneId: string, newPosition: any) => void;
    setSceneStepper: (sceneId: string, value: boolean) => void;
    setSceneReorder: (newIndex: number) => void;
    isLoading: boolean;
    menuOffSet?: { height: number; width: number };
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Panel for the creator timeline
export default function CreatorTimeline({
    sideDrawWidth,
    scenario,
    sx,
    addSceneOnClick,
    setSceneName,
    setSceneDuplicate,
    setSceneDelete,
    setSceneInitialView,
    setSceneStepper,
    setSceneReorder,
    isLoading,
    menuOffSet,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const navigate = useNavigate();
    const { sceneId }: Readonly<Params<string>> = useParams();
    const { currentlyDragging } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to get src and size images based on size
    function renderSceneSkeletons() {
        return (
            <Card elevation={5} sx={{ height: "100px", width: "200px" }}>
                {/*---------------------------------------------------------------------------------------*/}
                {/* Skeleton Card Image */}
                <CardMedia>
                    <Skeleton sx={{ height: "100px" }} animation="wave" variant="rectangular" />
                </CardMedia>
            </Card>
        );
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images

    return (
        <Grid
            sx={{
                height: sideDrawWidth - 6,
                marginTop: "-4px",
                overflowY: "hidden",
                width: "100%",
                userSelect: "none",
                overflowX: "scroll",
                padding: "25px",
                // background: "lime",
                ...sx,
            }}
            container
            alignContent={"flex-start"}
            direction={"column"}
        >
            {!isLoading ? (
                <>
                    <Grid item>
                        {currentlyDragging && currentlyDragging?.mediaType === EDragContentOptions.SCENE && (
                            <VTCreatorReorderDropContainer
                                currentlyDragging={currentlyDragging!}
                                bottomDrawWidth={sideDrawWidth}
                                handleMediaDropped={() => setSceneReorder(0)}
                                index={0}
                                sx={{ marginLeft: "-27px" }}
                            />
                        )}
                    </Grid>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Timeline items */}
                    {scenario &&
                        scenario &&
                        scenario.scenes &&
                        scenario.scenes.map((sceneData: IScene, index: number) => (
                            <Grid
                                key={sceneData.id}
                                container
                                alignContent={"center"}
                                justifyContent={"space-evenly"}
                                item
                                sx={{ width: "245px" }}
                            >
                                {/*------------------------------------------------------------------------------------*/}
                                {/* Image Timeline items */}
                                <Grid item>
                                    <VTTimelineImageItem
                                        size={sideDrawWidth < 220 ? sideDrawWidth - 20 : 200}
                                        titleCutOffLimit={sideDrawWidth < 200 ? (sideDrawWidth < 175 ? 10 : 16) : 20}
                                        active={sceneData.id === sceneId}
                                        onClicked={() => navigate(`/creator/${scenario.id}/${sceneData.id}`)}
                                        currentScene={sceneData}
                                        setSceneName={setSceneName}
                                        setSceneDuplicate={setSceneDuplicate}
                                        setSceneDelete={setSceneDelete}
                                        setSceneInitialView={setSceneInitialView}
                                        setSceneStepper={setSceneStepper}
                                        menuOffSet={menuOffSet}
                                        sx={sideDrawWidth < 182 ? { marginTop: "-11px" } : {}}
                                    />
                                </Grid>
                                <Grid item>
                                    {currentlyDragging &&
                                        currentlyDragging?.mediaType === EDragContentOptions.SCENE && (
                                            <VTCreatorReorderDropContainer
                                                currentlyDragging={currentlyDragging!}
                                                bottomDrawWidth={sideDrawWidth}
                                                handleMediaDropped={() => setSceneReorder(index + 1)}
                                                index={index + 1}
                                            />
                                        )}
                                </Grid>
                            </Grid>
                        ))}
                </>
            ) : (
                <>
                    <Grid item sx={{ marginRight: "10px" }}>
                        {renderSceneSkeletons()}
                    </Grid>
                    <Grid item sx={{ marginRight: "10px" }}>
                        {renderSceneSkeletons()}
                    </Grid>
                    <Grid item sx={{ marginRight: "10px" }}>
                        {renderSceneSkeletons()}
                    </Grid>
                </>
            )}

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Timeline add scene button */}
            <Grid item>
                <VTTimelineAddScene
                    size={sideDrawWidth < 210 ? sideDrawWidth - 80 : 130}
                    titleTypographyVariant={"caption"}
                    onClick={addSceneOnClick}
                    sx={
                        currentlyDragging && currentlyDragging?.mediaType === EDragContentOptions.SCENE
                            ? { marginTop: "10px", marginLeft: "3px" }
                            : { marginTop: "10px", marginLeft: "16px" }
                    }
                />
            </Grid>
        </Grid>
    );
}
