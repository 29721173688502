import { SvgIcon, SxProps } from "@mui/material";

interface IProps {
    sx?: SxProps;
}

export default function Icon360Video({ sx }: IProps) {
    return (
        <SvgIcon viewBox="0 0 530.595 460.06" sx={{ ...sx }}>
            <g id="Group_11516" data-name="Group 11516" transform="translate(-10539.5 -7018.94)">
                <g id="Group_11515" data-name="Group 11515">
                    <g id="circular-arrow-svgrepo-com" transform="translate(9514.5 6581.085)">
                        <g id="Group_11513" data-name="Group 11513" transform="translate(1025 437.855)">
                            <g id="circuit-svgrepo-com" transform="translate(0 0)">
                                <path
                                    id="Path_61480"
                                    data-name="Path 61480"
                                    d="M528.442,57.793a9.876,9.876,0,0,0,1.862-6.047c-.37-6.988-1.041-13.986-2.022-20.8a10.017,10.017,0,1,0-19.832,2.833C509.351,40,509.962,46.4,510.3,52.8a9.767,9.767,0,0,0,2.152,5.406,9.68,9.68,0,0,0-1.882,5.516c0,115.919-94.315,210.235-210.235,210.235-107.12,0-195.618-80.59-208.463-184.3l51.217,51.217a10.01,10.01,0,1,0,14.156-14.156L90.283,59.755c.1-5.056.23-10.141.681-15.117a10.012,10.012,0,0,0-19.942-1.8c-.491,5.456-.641,11-.741,16.538L2.936,126.72a10.01,10.01,0,1,0,14.156,14.156l54.14-54.14c11.583,116.2,109.9,207.241,229.106,207.241,126.972,0,230.257-103.285,230.257-230.257A9.835,9.835,0,0,0,528.442,57.793Z"
                                    transform="translate(0 166.083)"
                                />
                                <path
                                    id="Path_61481"
                                    data-name="Path 61481"
                                    d="M42.977,24.176c5.927,2.082,11.893,4.485,17.73,7.148a9.863,9.863,0,0,0,4.145.9A10.012,10.012,0,0,0,69.016,13.1c-6.4-2.913-12.924-5.546-19.4-7.809a10.007,10.007,0,0,0-6.637,18.881Z"
                                    transform="translate(326.988 7.195)"
                                />
                                <path
                                    id="Path_61482"
                                    data-name="Path 61482"
                                    d="M39.814,23.531c6.327.4,12.714,1.1,18.991,2.082a10.2,10.2,0,0,0,1.542.12A10.015,10.015,0,0,0,61.869,5.821C54.991,4.76,48,3.979,41.075,3.539a10.016,10.016,0,1,0-1.261,19.992Z"
                                    transform="translate(274.169 -3.522)"
                                />
                                <path
                                    id="Path_61483"
                                    data-name="Path 61483"
                                    d="M61,37.3A10.012,10.012,0,1,0,73.93,22.012c-5.326-4.5-10.9-8.79-16.569-12.754A10.014,10.014,0,0,0,45.878,25.666C51.044,29.27,56.14,33.194,61,37.3Z"
                                    transform="translate(374.934 31.825)"
                                />
                                <path
                                    id="Path_61484"
                                    data-name="Path 61484"
                                    d="M59.734,43.167A10.014,10.014,0,0,0,76.283,31.885c-3.894-5.716-8.119-11.343-12.544-16.729A10.014,10.014,0,0,0,48.272,27.88C52.316,32.806,56.18,37.941,59.734,43.167Z"
                                    transform="translate(414.487 68.424)"
                                />
                                <path
                                    id="Path_61485"
                                    data-name="Path 61485"
                                    d="M49.988,30.652c2.563,5.8,4.885,11.783,6.9,17.78a10.012,10.012,0,1,0,18.981-6.377C73.645,35.468,71.1,28.91,68.3,22.553a10.011,10.011,0,1,0-18.31,8.1Z"
                                    transform="translate(442.754 114.296)"
                                />
                                <path
                                    id="Path_61486"
                                    data-name="Path 61486"
                                    d="M28.858,33.6a9.984,9.984,0,0,0,4.625-1.131c5.606-2.933,11.433-5.636,17.309-8.059A10.011,10.011,0,1,0,43.184,5.885C36.737,8.527,30.36,11.5,24.213,14.7A10.013,10.013,0,0,0,28.858,33.6Z"
                                    transform="translate(169.716 10.84)"
                                />
                                <path
                                    id="Path_61487"
                                    data-name="Path 61487"
                                    d="M15,47.569a10.006,10.006,0,0,0,13.755-3.344c3.3-5.436,6.888-10.762,10.652-15.848A10.012,10.012,0,1,0,23.319,16.454c-4.125,5.576-8.059,11.423-11.673,17.369A10,10,0,0,0,15,47.569Z"
                                    transform="translate(91.802 76.546)"
                                />
                                <path
                                    id="Path_61488"
                                    data-name="Path 61488"
                                    d="M34.461,26.858a10.374,10.374,0,0,0,2.062-.21c6.187-1.291,12.534-2.323,18.861-3.073A10.009,10.009,0,0,0,53.061,3.692C46.144,4.5,39.186,5.634,32.418,7.056a10.008,10.008,0,0,0,2.042,19.8Z"
                                    transform="translate(220.436 -2.594)"
                                />
                                <path
                                    id="Path_61489"
                                    data-name="Path 61489"
                                    d="M15.123,56.311a10.106,10.106,0,0,0,2.7.37,10,10,0,0,0,9.631-7.318c1.7-6.107,3.714-12.214,5.977-18.13A10.013,10.013,0,0,0,14.722,24.1C12.25,30.583,10.037,37.28,8.185,43.978A9.987,9.987,0,0,0,15.123,56.311Z"
                                    transform="translate(70.365 123.816)"
                                />
                                <path
                                    id="Path_61490"
                                    data-name="Path 61490"
                                    d="M23.963,41.167a9.986,9.986,0,0,0,6.848-2.713c4.625-4.335,9.5-8.51,14.516-12.424A10.007,10.007,0,0,0,33.013,10.252c-5.5,4.285-10.842,8.86-15.888,13.6a10.016,10.016,0,0,0,6.838,17.319Z"
                                    transform="translate(125.777 37.928)"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        id="Icon_awesome-play"
                        data-name="Icon awesome-play"
                        d="M123.769,62.606,21.114,1.918C12.774-3.011,0,1.772,0,13.962V135.31a13.986,13.986,0,0,0,21.114,12.044L123.769,86.7a13.984,13.984,0,0,0,0-24.089Z"
                        transform="translate(10787.35 7174.306)"
                    />
                </g>
            </g>
        </SvgIcon>
    );
}
