//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
import { useAppSelector } from "../../store/hooks";
import { IDragOptions } from "../../models/current.models";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import VTCreatorDropContainer, { DropPoint } from "../components/VTCreatorDropContainer";
import {
    CircularProgress,
    Grid,
    Skeleton,
    Slider,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { VTButton, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { IScenario, IScene } from "../../models/scenario.models";
import { useEffect, useState } from "react";
import { IHotspot } from "../../models/hotspot.models";
import { setCurrentDragging, setCurrentSidePanel } from "../../store/slices/current.slice";
import { ArrowForwardRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { Params, useNavigate, useParams } from "react-router";
import VTCreatorTimeline from "../components/VTCreatorTimeline";
import * as animationData from "../../assets/icons/vh_logo.json";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { AddRounded, CancelRounded, SaveRounded, DeleteRounded } from "@mui/icons-material";
import VTCreatorReorderDropContainer from "../components/VTCreatorReorderDropContainer";

import useMediaQuery from "@mui/material/useMediaQuery";
import { EOrganisation } from "../../models/organisations.model";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    scenario: IScenario | undefined;
    scenarioLoading: boolean;
    handleMediaTimelineDropped: (timelineMedia: IDragOptions) => void;
    setSceneName: (sceneId: string, sceneName: string) => void;
    setSceneDelete: (sceneId: string) => void;
    setSceneDuplicate: (sceneId: string) => void;
    setSceneInitialView: (sceneId: string, newPosition: any) => void;
    setSceneStepper: (sceneId: string, value: boolean) => void;
    setSceneReorder: (index: number) => void;
    isLoading: boolean;
    bottomDrawWidth: number;
    setBottomDrawWidth: (bottomDrawWidth: number) => void;
    timelineMode: "Scenes" | "Timeline" | "Stepper";
    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    mediaType: any;
    videoNextQuestion: IHotspot | undefined;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
    currentQuestions: IHotspot[];
    videoCurrentTime: number;
    video: any;
    getNextQuestion: (
        time: number,
        hotspots: IHotspot[],
        setVideoNextQuestion: (nextQuestion: IHotspot | undefined) => void,
    ) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;
    currentInterface: IHotspot | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Panel for the creator timeline
export default function VTCreatorTimelineContainer({
    scenario,
    scenarioLoading,
    handleMediaTimelineDropped,
    setSceneName,
    setSceneDelete,
    setSceneDuplicate,
    setSceneInitialView,
    setSceneStepper,
    setSceneReorder,
    isLoading,
    bottomDrawWidth,
    setBottomDrawWidth,
    timelineMode,
    setTimelineMode,
    mediaType,
    videoNextQuestion,
    setVideoCurrentState,
    videoCurrentState,
    currentQuestions,
    videoCurrentTime,
    video,
    getNextQuestion,
    setVideoNextQuestion,
    currentInterface,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [stepperArray, setStepperArray] = useState<IScene[]>([]);
    const matches = useMediaQuery("(min-width:600px)");
    const [stepperOpen, setStepperOpen] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentlyDragging, currentSidePanel, currentlyPreview } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenario && scenario.scenes) {
            let newStepperArray: IScene[] = [];

            scenario?.scenes.map((scene: IScene) => scene.stepper === true && newStepperArray.push(scene));

            if (newStepperArray.length === 0 && timelineMode === "Stepper") {
                setTimelineMode("Scenes");
            }

            setStepperArray(newStepperArray);
        }
    }, [scenario]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Function to get src and size images based on size
    function textCutOffAddElipsis(text: string, cutOffPointCharIndex: number) {
        const charAtIndex = text.charAt(cutOffPointCharIndex);
        if (charAtIndex === " ") {
            return text.substring(0, cutOffPointCharIndex) + "...";
        } else if (charAtIndex === "") {
            return text;
        } else {
            return text.substring(0, cutOffPointCharIndex + 1) + "...";
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render the timeline with a panel
    return (
        <>
            {currentlyPreview ? (
                <>
                    {!stepperOpen && stepperArray && stepperArray.length > 0 && (
                        <VTButton
                            type={"icon"}
                            sx={{
                                position: "absolute",
                                bottom: mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "150px" : "80px",
                                paddingLeft: "20px",
                                backgroundColor: "rgba(100,100,100,1)",
                                cursor: "pointer",
                                ":hover": {
                                    backgroundColor: "rgba(90,90,90,1)",
                                },
                                zIndex: 2000,
                                left: "-20px",
                            }}
                            onClick={() => setStepperOpen(!stepperOpen)}
                        >
                            <KeyboardArrowRightIcon />
                        </VTButton>
                    )}

                    {stepperArray && stepperArray.length > 0 && (
                        <VTButton
                            type={"icon"}
                            sx={{
                                position: "absolute",

                                bottom:
                                    mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "240px" : "170px",
                                left: !stepperOpen ? "-100%" : "calc(48% - " + (currentInterface ? 200 : 0) + "px)",
                                transition: "0.95s",
                                height: "25px",
                                width: "80px",
                                backgroundColor: "rgba(10,10,10,0.6)",
                                borderRadius: "20px 20px 0px 0px",
                                cursor: "pointer",
                                ":hover": {
                                    backgroundColor: "rgba(10,10,10,0.8)",
                                },
                                zIndex: 2000,
                            }}
                            onClick={() => setStepperOpen(!stepperOpen)}
                        >
                            <KeyboardArrowLeftIcon />
                        </VTButton>
                    )}

                    {stepperArray && stepperArray.length > 0 && (
                        <>
                            <Grid
                                item
                                container
                                xs={12}
                                sx={{
                                    position: "absolute",
                                    bottom:
                                        mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360 ? "90px" : "20px",
                                    width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                                    left: !stepperOpen ? "-100%" : 0,
                                    transition: "1s",
                                }}
                                justifyContent={"center"}
                            >
                                <Grid
                                    container
                                    sx={{
                                        zIndex: 1200,
                                        position: "relative",
                                    }}
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                    xs={12}
                                >
                                    <Grid
                                        item
                                        container
                                        justifyContent={"space-between"}
                                        sx={{
                                            backgroundColor: "rgba(10,10,10,0.4)",
                                            borderRadius: "15px",
                                            width: stepperArray.length === 1 ? "300px" : "80%",
                                            minHeight: "150px",
                                        }}
                                    >
                                        <VTButton
                                            sx={{
                                                backgroundColor: "rgba(10,10,10,0.4)",
                                                borderRadius: "15px 0px 0px 15px",
                                                ":hover": {
                                                    backgroundColor: "rgba(10,10,10,0.6)",
                                                },
                                            }}
                                            disabled={
                                                stepperArray.findIndex((scene: IScene) => scene.id === sceneId) === 0
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/viewer/${scenarioId}/${
                                                        stepperArray[
                                                            stepperArray.findIndex(
                                                                (scene: IScene) => scene.id === sceneId,
                                                            ) - 1
                                                        ].id
                                                    }`,
                                                );
                                            }}
                                        >
                                            <ArrowBackIosRounded />
                                        </VTButton>
                                        {stepperArray &&
                                            matches &&
                                            stepperArray.map((scene: IScene, index: number) => {
                                                if (scene.stepper) {
                                                    return (
                                                        <Grid
                                                            item
                                                            container
                                                            sx={{
                                                                width: "100px",
                                                                marginBottom: "-40px",
                                                            }}
                                                            justifyContent={"center"}
                                                            alignContent={"center"}
                                                            direction={"row"}
                                                        >
                                                            <Grid item xs={12} container justifyContent={"center"}>
                                                                <VTButton
                                                                    type="icon"
                                                                    sx={{
                                                                        padding: 0,
                                                                        borderRadius: "50%",
                                                                        width: sceneId === scene.id ? "45px" : "40px",
                                                                        height: sceneId === scene.id ? "45px" : "40px",
                                                                        background:
                                                                            sceneId === scene.id
                                                                                ? "linear-gradient(to bottom right, #0ab5a7,#8e25fa)"
                                                                                : "#7315ce",
                                                                        transition: ".5s",
                                                                        ":hover": {
                                                                            boxShadow: "0 0 25px #2ad5c7",
                                                                        },
                                                                    }}
                                                                    onClick={() => {
                                                                        navigate(`/viewer/${scenarioId}/${scene.id}`);
                                                                    }}
                                                                >
                                                                    <div style={{ margin: "-8px" }}>
                                                                        <img
                                                                            src={
                                                                                scene?.mediaType === "three_sixty_video"
                                                                                    ? scene.src.split(".mp4")[0] +
                                                                                      "_preview.png"
                                                                                    : `${scene?.src}/f.jpg`
                                                                            }
                                                                            height={"100px"}
                                                                            width={"150px"}
                                                                            style={{
                                                                                borderRadius: "15px",
                                                                                marginTop: "5px",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </VTButton>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Tooltip arrow title={scene.name}>
                                                                    <Typography
                                                                        variant={"body1"}
                                                                        align={"center"}
                                                                        sx={{ marginTop: "30px" }}
                                                                    >
                                                                        {scene.name.length <= 20
                                                                            ? scene.name
                                                                            : textCutOffAddElipsis(scene.name, 20)}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                        <VTButton
                                            sx={{
                                                backgroundColor: "rgba(10,10,10,0.4)",
                                                borderRadius: "0px 15px 15px 0px",
                                                ":hover": {
                                                    backgroundColor: "rgba(10,10,10,0.6)",
                                                },
                                            }}
                                            disabled={
                                                stepperArray.findIndex((scene: IScene) => scene.id === sceneId) ===
                                                stepperArray.length - 1
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/viewer/${scenarioId}/${
                                                        stepperArray[
                                                            stepperArray.findIndex(
                                                                (scene: IScene) => scene.id === sceneId,
                                                            ) + 1
                                                        ].id
                                                    }`,
                                                );
                                            }}
                                        >
                                            <ArrowForwardIosRounded />
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </>
            ) : (
                <VTSidePanel
                    anchor={"bottom"}
                    sideMargin={currentSidePanel.width + 100}
                    sx={{ overflow: "hidden" }}
                    currentDrawWidth={bottomDrawWidth}
                    setCurrentDrawWidth={setBottomDrawWidth}
                    minDrawerWidth={120}
                    maxDrawerWidth={250}
                >
                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Dropbox overlay; rendered when media is grabbed from the side panel for images to be
dropped */}
                    {currentlyDragging &&
                        timelineMode === "Scenes" &&
                        (currentlyDragging.mediaType === EDragContentOptions.IMAGE_360 ||
                            currentlyDragging.mediaType === EDragContentOptions.VIDEO_360 ||
                            currentlyDragging.mediaType === EDragContentOptions.VIDEO ||
                            currentlyDragging.mediaType === EDragContentOptions.DOCUMENT ||
                            currentlyDragging.mediaType === EDragContentOptions.PASSTHROUGH ||
                            currentlyDragging.mediaType === EDragContentOptions.MODEL_3D_BASE) && (
                            <VTCreatorDropContainer
                                handleMediaDropped={handleMediaTimelineDropped}
                                currentlyDragging={currentlyDragging}
                                location={DropPoint.DROP_TIMELINE}
                                bottomDrawWidth={bottomDrawWidth}
                            />
                        )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* TimeLine Display */}
                    {/* -------------------------------------------------------------------------------------------- */}

                    {/* Added a throw error so that it could run on my device */}
                    <Grid container sx={{ overflow: "hidden" }}>
                        {currentlyDragging && currentlyDragging.mediaType === EDragContentOptions.SCENE ? (
                            <Grid item container xs={1} sx={{ position: "relative" }}>
                                <Grid item xs={12}>
                                    <VTButton
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: 0,
                                            backgroundColor: timelineMode === "Scenes" ? "#696969" : "#292929",
                                            ":hover": {
                                                backgroundColor: timelineMode === "Scenes" ? "#797979" : "#393939",
                                            },
                                        }}
                                    >
                                        <DeleteRounded />
                                    </VTButton>
                                </Grid>

                                <TextField
                                    value={""}
                                    onDrop={(e: any) => {
                                        setSceneDelete(currentlyDragging.src); // works on chrome/mac also
                                        dispatch(setCurrentDragging(undefined));
                                    }}
                                    variant="standard"
                                    sx={{
                                        position: "absolute",
                                        zIndex: 5,
                                        backgroundColor: "rgba(0,0,0,0.0)",
                                        border: "3px dashed #aa0000",
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "0px",
                                        "& .MuiInputBase-root": {
                                            "&::before": {
                                                borderBottom: "none",
                                            },
                                            "&::after": {
                                                borderBottom: "none",
                                            },
                                        },
                                    }}
                                    fullWidth={true}
                                    inputProps={{
                                        style: {
                                            // Set the content width; effects functionality not styling
                                            height: bottomDrawWidth - 100,
                                            color: "rgba(0,0,0,0)",
                                            border: "none",
                                        },
                                    }}
                                    multiline
                                />
                            </Grid>
                        ) : (
                            <Grid item container xs={1}>
                                <Grid item xs={12}>
                                    <VTButton
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: 0,
                                            backgroundColor: timelineMode === "Scenes" ? "#696969" : "#292929",
                                            ":hover": {
                                                backgroundColor: timelineMode === "Scenes" ? "#797979" : "#393939",
                                            },
                                        }}
                                        onClick={() => setTimelineMode("Scenes")}
                                    >
                                        Scenes
                                    </VTButton>
                                </Grid>
                                {(mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO) &&
                                    currentQuestions.length > 0 && (
                                        <Grid item xs={12}>
                                            <VTButton
                                                sx={{
                                                    width: "100%",
                                                    height: "100%",
                                                    borderRadius: 0,
                                                    backgroundColor:
                                                        timelineMode === "Timeline" ? "#696969" : "#292929",
                                                    ":hover": {
                                                        backgroundColor:
                                                            timelineMode === "Timeline" ? "#797979" : "#393939",
                                                    },
                                                }}
                                                onClick={() => setTimelineMode("Timeline")}
                                            >
                                                Timeline
                                            </VTButton>
                                        </Grid>
                                    )}

                                {stepperArray.length > 0 && (
                                    <Grid item xs={12}>
                                        <VTButton
                                            sx={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: 0,
                                                backgroundColor: timelineMode === "Stepper" ? "#696969" : "#292929",
                                                ":hover": {
                                                    backgroundColor: timelineMode === "Stepper" ? "#797979" : "#393939",
                                                },
                                            }}
                                            onClick={() => setTimelineMode("Stepper")}
                                        >
                                            Stepper
                                        </VTButton>
                                    </Grid>
                                )}
                            </Grid>
                        )}

                        <Grid
                            item
                            container
                            xs={11}
                            sx={{
                                // overflowY: "auto",
                                // background: "red",
                                marginBottom: "10px",
                                height: bottomDrawWidth - 10,
                                overflowY: "auto",
                                position: "relative",
                            }}
                        >
                            {timelineMode === "Scenes" && (
                                <VTCreatorTimeline
                                    sideDrawWidth={bottomDrawWidth}
                                    scenario={scenario}
                                    addSceneOnClick={() => {
                                        if (organisation === EOrganisation.ECO_GOAL) {
                                            handleMediaTimelineDropped({
                                                mediaType: EDragContentOptions.DOCUMENT,
                                                src: "",
                                            });
                                        } else {
                                            handleMediaTimelineDropped({
                                                mediaType: EDragContentOptions.IMAGE_360,
                                                src: "https://medias3.digi-sim-api.co.uk/app_defaults/default_360_image",
                                            });
                                        }
                                        if (currentSidePanel.width === 0) {
                                            dispatch(
                                                setCurrentSidePanel({
                                                    currentTab: "360 Images",
                                                    width: 400,
                                                    option: "AddScene",
                                                }),
                                            );
                                        }
                                    }}
                                    setSceneName={setSceneName}
                                    setSceneDelete={setSceneDelete}
                                    setSceneDuplicate={setSceneDuplicate}
                                    setSceneInitialView={setSceneInitialView}
                                    setSceneStepper={setSceneStepper}
                                    setSceneReorder={setSceneReorder}
                                    isLoading={isLoading}
                                    menuOffSet={{
                                        height: bottomDrawWidth - 20,
                                        width: bottomDrawWidth / 2 + 80,
                                    }}
                                />
                            )}

                            {timelineMode === "Timeline" && (
                                <>
                                    {scenarioLoading || isLoading ? (
                                        <Grid
                                            container
                                            sx={{
                                                zIndex: 1200,
                                            }}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            xs={11}
                                        >
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton
                                                variant="text"
                                                width={200}
                                                height={20}
                                                sx={{ marginTop: "10px", borderRadius: 0 }}
                                            />
                                            <Skeleton variant="circular" width={40} height={40} />
                                            <Skeleton
                                                variant="text"
                                                width={200}
                                                height={20}
                                                sx={{ marginTop: "10px", borderRadius: 0 }}
                                            />
                                            <Skeleton variant="circular" width={40} height={40} />
                                        </Grid>
                                    ) : (
                                        <Grid
                                            container
                                            sx={{
                                                zIndex: 1200,
                                            }}
                                            justifyContent={"center"}
                                            alignContent={"center"}
                                            xs={11}
                                        >
                                            <Grid
                                                item
                                                container
                                                justifyContent={"space-between"}
                                                sx={{
                                                    height: "5px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "15px",
                                                    marginLeft: "50px",
                                                    width:
                                                        currentQuestions.length < 2
                                                            ? "0px"
                                                            : currentQuestions.length > 1 && currentQuestions.length < 4
                                                            ? `400px`
                                                            : "wrap",
                                                }}
                                            >
                                                {currentQuestions &&
                                                    currentQuestions.map((question: IHotspot, index: number) => {
                                                        return (
                                                            <Grid
                                                                item
                                                                container
                                                                sx={{
                                                                    width: "2px",
                                                                    backgroundColor: "#fff",
                                                                    marginLeft: "17px",
                                                                    flexWrap: "wrap",
                                                                    height: `${
                                                                        question.contents.preClickContent.question?.answers.filter(
                                                                            (element: IHotspot) =>
                                                                                element.actions?.movement?.sceneId !==
                                                                                undefined,
                                                                        ).length! * 80
                                                                    }px`,
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    title={question.contents.preClickContent.title}
                                                                    placement="left-start"
                                                                >
                                                                    <Grid item>
                                                                        <VTButton
                                                                            type="icon"
                                                                            sx={{
                                                                                padding: 0,
                                                                                borderRadius: "50%",
                                                                                marginTop:
                                                                                    videoNextQuestion &&
                                                                                    videoNextQuestion.id ===
                                                                                        question.id &&
                                                                                    videoCurrentState === "Question"
                                                                                        ? "-19px"
                                                                                        : "-17px",
                                                                                marginLeft:
                                                                                    videoNextQuestion &&
                                                                                    videoNextQuestion.id ===
                                                                                        question.id &&
                                                                                    videoCurrentState === "Question"
                                                                                        ? "-21px"
                                                                                        : "-19px",
                                                                                width:
                                                                                    videoNextQuestion &&
                                                                                    videoNextQuestion.id ===
                                                                                        question.id &&
                                                                                    videoCurrentState === "Question"
                                                                                        ? "45px"
                                                                                        : "40px",
                                                                                height:
                                                                                    videoNextQuestion &&
                                                                                    videoNextQuestion.id ===
                                                                                        question.id &&
                                                                                    videoCurrentState === "Question"
                                                                                        ? "45px"
                                                                                        : "40px",
                                                                                background:
                                                                                    videoNextQuestion &&
                                                                                    videoNextQuestion.id ===
                                                                                        question.id &&
                                                                                    videoCurrentState === "Question"
                                                                                        ? "linear-gradient(to bottom right, #0ab5a7,#8e25fa)"
                                                                                        : "#7315ce",
                                                                                transition: ".5s",
                                                                                ":hover": {
                                                                                    boxShadow: "0 0 25px #2ad5c7",
                                                                                },
                                                                            }}
                                                                            onClick={() => {
                                                                                video.pause();
                                                                                setVideoCurrentState("Question");
                                                                                video.currentTime =
                                                                                    question.contents.preClickContent
                                                                                        .question?.time! + 0.0001;
                                                                                getNextQuestion(
                                                                                    question.contents.preClickContent
                                                                                        .question?.time! - 0.0001,
                                                                                    scenario?.scenes.find(
                                                                                        (element: any) =>
                                                                                            element.id === sceneId,
                                                                                    )!.hotspots!,
                                                                                    setVideoNextQuestion,
                                                                                );
                                                                            }}
                                                                        >
                                                                            {index + 1}
                                                                        </VTButton>
                                                                    </Grid>
                                                                </Tooltip>
                                                                {question.contents.preClickContent.question?.answers.map(
                                                                    (element: IHotspot) => {
                                                                        if (element.actions?.movement?.sceneId) {
                                                                            let currentMovementHotspot =
                                                                                scenario?.scenes.find(
                                                                                    (findElement: IScene) =>
                                                                                        findElement.id ===
                                                                                        element.actions?.movement
                                                                                            ?.sceneId,
                                                                                );
                                                                            return (
                                                                                <Tooltip
                                                                                    title={
                                                                                        currentMovementHotspot ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    margin: "-8px",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        currentMovementHotspot?.mediaType ===
                                                                                                        "three_sixty_video"
                                                                                                            ? currentMovementHotspot.src.split(
                                                                                                                  ".mp4",
                                                                                                              )[0] +
                                                                                                              "_preview.png"
                                                                                                            : `${currentMovementHotspot?.src}/f.jpg`
                                                                                                    }
                                                                                                    height={"100px"}
                                                                                                    width={"150px"}
                                                                                                    style={{
                                                                                                        borderRadius:
                                                                                                            "15px 15px 0px 0px",
                                                                                                        marginTop:
                                                                                                            "5px",
                                                                                                    }}
                                                                                                />
                                                                                                <Typography
                                                                                                    align="center"
                                                                                                    sx={{
                                                                                                        marginBottom:
                                                                                                            "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        currentMovementHotspot?.name
                                                                                                    }
                                                                                                </Typography>
                                                                                            </div>
                                                                                        ) : (
                                                                                            "No Scene assigned"
                                                                                        )
                                                                                    }
                                                                                    placement="right-start"
                                                                                >
                                                                                    <Grid item>
                                                                                        <VTButton
                                                                                            type="icon"
                                                                                            sx={{
                                                                                                transition: ".5s",
                                                                                                ":hover": {
                                                                                                    boxShadow:
                                                                                                        "0 0 25px #2ad5c7",
                                                                                                },
                                                                                                width: "40px",
                                                                                                height: "40px",
                                                                                                backgroundColor:
                                                                                                    "#7315ce",
                                                                                                marginLeft: "-19px",
                                                                                                marginTop: "40px",
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                navigate(
                                                                                                    `/creator/${scenarioId}/${element.actions?.movement?.sceneId}`,
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <ArrowForwardRounded />
                                                                                        </VTButton>
                                                                                    </Grid>
                                                                                </Tooltip>
                                                                            );
                                                                        }
                                                                    },
                                                                )}
                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            )}

                            {timelineMode === "Stepper" && (
                                <Grid
                                    container
                                    sx={{
                                        zIndex: 1200,
                                    }}
                                    justifyContent={"center"}
                                    alignContent={"center"}
                                    xs={11}
                                >
                                    <Grid
                                        item
                                        container
                                        justifyContent={"space-between"}
                                        sx={{
                                            backgroundColor: "#666666",
                                            borderRadius: "15px",
                                            marginLeft: "50px",
                                            width: stepperArray.length === 1 ? "300px" : "80%",
                                            minHeight: "150px",
                                        }}
                                    >
                                        <VTButton
                                            sx={{
                                                backgroundColor: "#666666",
                                                borderRadius: "15px 0px 0px 15px",
                                                ":hover": {
                                                    backgroundColor: "#555555",
                                                },
                                            }}
                                            disabled={
                                                stepperArray.findIndex((scene: IScene) => scene.id === sceneId) === 0
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/creator/${scenarioId}/${
                                                        stepperArray[
                                                            stepperArray.findIndex(
                                                                (scene: IScene) => scene.id === sceneId,
                                                            ) - 1
                                                        ].id
                                                    }`,
                                                );
                                            }}
                                        >
                                            <ArrowBackIosRounded />
                                        </VTButton>
                                        {stepperArray &&
                                            matches &&
                                            stepperArray.map((scene: IScene, index: number) => {
                                                if (scene.stepper) {
                                                    return (
                                                        <Grid
                                                            item
                                                            container
                                                            sx={{
                                                                width: "100px",
                                                            }}
                                                            justifyContent={"center"}
                                                            alignContent={"center"}
                                                            direction={"row"}
                                                        >
                                                            <Grid item xs={12} container justifyContent={"flex-end"}>
                                                                <CloseIcon
                                                                    sx={{
                                                                        zIndex: 100,
                                                                        cursor: "pointer",
                                                                    }}
                                                                    color="error"
                                                                    onClick={() => setSceneStepper(scene.id, false)}
                                                                />
                                                            </Grid>
                                                            <Tooltip
                                                                title={
                                                                    <div style={{ margin: "-8px" }}>
                                                                        <img
                                                                            src={
                                                                                scene?.mediaType === "three_sixty_video"
                                                                                    ? scene.src.split(".mp4")[0] +
                                                                                      "_preview.png"
                                                                                    : `${scene?.src}/f.jpg`
                                                                            }
                                                                            height={"100px"}
                                                                            width={"150px"}
                                                                            style={{
                                                                                borderRadius: "15px 15px 0px 0px",
                                                                                marginTop: "5px",
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            align="center"
                                                                            sx={{
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            {scene?.name}
                                                                        </Typography>
                                                                    </div>
                                                                }
                                                                placement="top"
                                                            >
                                                                <Grid item xs={12} container justifyContent={"center"}>
                                                                    <VTButton
                                                                        type="icon"
                                                                        sx={{
                                                                            padding: 0,
                                                                            borderRadius: "50%",
                                                                            width:
                                                                                sceneId === scene.id ? "45px" : "40px",
                                                                            height:
                                                                                sceneId === scene.id ? "45px" : "40px",
                                                                            background:
                                                                                sceneId === scene.id
                                                                                    ? "linear-gradient(to bottom right, #0ab5a7,#8e25fa)"
                                                                                    : "#7315ce",
                                                                            transition: ".5s",
                                                                            ":hover": {
                                                                                boxShadow: "0 0 25px #2ad5c7",
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            navigate(
                                                                                `/creator/${scenarioId}/${scene.id}`,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {index + 1}
                                                                    </VTButton>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Grid item xs={12}>
                                                                <Tooltip arrow title={scene.name} placement="right">
                                                                    <Typography variant={"body1"} align={"center"}>
                                                                        {scene.name.length <= 20
                                                                            ? scene.name
                                                                            : textCutOffAddElipsis(scene.name, 20)}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }
                                            })}
                                        <VTButton
                                            sx={{
                                                backgroundColor: "#666666",
                                                borderRadius: "0px 15px 15px 0px",
                                                ":hover": {
                                                    backgroundColor: "#555555",
                                                },
                                            }}
                                            disabled={
                                                stepperArray.findIndex((scene: IScene) => scene.id === sceneId) ===
                                                stepperArray.length - 1
                                            }
                                            onClick={() => {
                                                navigate(
                                                    `/creator/${scenarioId}/${
                                                        stepperArray[
                                                            stepperArray.findIndex(
                                                                (scene: IScene) => scene.id === sceneId,
                                                            ) + 1
                                                        ].id
                                                    }`,
                                                );
                                            }}
                                        >
                                            <ArrowForwardIosRounded />
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {/* -------------------------------------------------------------------------------------------- */}
                </VTSidePanel>
            )}
        </>
    );
}
