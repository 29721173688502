import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/slices/current.slice";
import { MediaWrapper } from "../layouts/MediaWrapper";

// Only display layouts in pages
export default function Media() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("media"));     
    }, []);

    return <MediaWrapper />;
}
