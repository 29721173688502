//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { TextField } from "@mui/material";
// Custom components imports (and hooks and helpers)
import { IDragOptions } from "../../models/current.models";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCurrentDragging } from "../../store/slices/current.slice";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    handleMediaDropped: (index: number) => void;
    currentlyDragging: IDragOptions;
    bottomDrawWidth: number;
    index: number;
    sx?: any;
}

// Location component is used for bespoke styling
export enum DropPoint {
    DROP_CANVAS = "drop canvas",
    DROP_TIMELINE = "drop timeline=",
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorReorderDropContainer({
    handleMediaDropped,
    currentlyDragging,
    bottomDrawWidth,
    index,
    sx,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Drop container for the creator page
    return (
        <TextField
            value={""}
            onChange={(e) => {
                // handleMediaDropped(currentlyDragging, videoNextQuestionID);
            }}
            onDrop={(e) => {
                handleMediaDropped(index); // works on chrome/mac also
            }}
            variant="standard"
            sx={{
                ...sx,
                zIndex: 5,
                backgroundColor: "rgba(0,0,0,0.0)",
                border: "3px dashed #7315ce",
                width: "40px",
                borderRadius: "5px",
                "& .MuiInputBase-root": {
                    "&::before": {
                        borderBottom: "none",
                    },
                    "&::after": {
                        borderBottom: "none",
                    },
                },
            }}
            fullWidth={true}
            inputProps={{
                style: {
                    // Set the content width; effects functionality not styling
                    height: bottomDrawWidth - 100,
                    color: "rgba(0,0,0,0)",
                    border: "none",
                },
            }}
            multiline
        />
    );
}
