import { Grid } from "@react-three/drei";
import { useEffect } from "react";

interface IProps {
    handleSetPosition: (
        positionX: number,
        positionY: number,
        positionZ: number,
        lookAtX: number,
        lookAtY: number,
        lookAtZ: number,
        transition?: boolean,
    ) => void;
}

export default function ThreeDViewer({ handleSetPosition }: IProps) {
    console.log("here ****************************");

    useEffect(() => {
        handleSetPosition(10, 10, 10, 0, 0, 0, true);
    }, []);

    return (
        <>
            <Grid position={[0, 0, 0]} cellColor="green" args={[60, 60]} />
        </>
    );
}
