import {
    VTButton,
    VTCard,
    VTCard2DVideo,
    VTMenu,
    VTTextField,
    VTTypography,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    Typography,
} from "@mui/material";

import { SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoVideos from "../../assets/images/NoVideos.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useCreateAllVideosMutation,
    usePreSignedUrlMutation,
    useUpdateAllVideosMutation,
} from "../../services/media.service";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";

import { v4 as uuid } from "uuid";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { setCurrentToast } from "../../store/slices/current.slice";
interface IProps {
    videos: IMediaItem[] | undefined;
    loading: boolean;
    leftDrawWidth: number;
    deleteMediaItem: (mediaType: EMedia, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
}

export default function VideoCardContainer({ videos, leftDrawWidth, deleteMediaItem, deleteLoading, loading }: IProps) {
    const { organisation, id: userId } = useAppSelector((state) => state.userReducer);

    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    const [filterValue, setFilterValue] = useState<string>("");
    const [showSkeletonForCardById, setShowSkeletonForCardById] = useState<string>("");
    const [changedItem, setChangedItem] = useState<string>("");

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [updateAllVideos, { isLoading: updateAllVideosIsLoading }] = useUpdateAllVideosMutation();
    const [createAllVideos] = useCreateAllVideosMutation();

    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint();

    const dispatch = useAppDispatch();

    // useEffect(() => {
    //     if (!deleteLoading && !imageUpdateLoading) {
    //         setChangedItem("");
    //     }
    // }, [deleteLoading, imageUpdateLoading]);

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            createAllVideos({
                id: userId,
                media_id: videoId,
                media_type: "video",
                filetype: selectedVideo.type.split("/")[1],
                name: videoName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading || !updateAllVideosIsLoading) {
            window.setTimeout(() => {
                setShowSkeletonForCardById("");
            }, 1000);
        }
    }, [deleteLoading, updateAllVideosIsLoading]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain video types
        if (videoFile.type.includes("video/") && ["mp4"].includes(videoFile.name.split(".")[1])) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            // Get presigned url to upload video
            await preSignedUrl({
                organisation,
                media_type: "video",
                media_id: `${videoId}`,
                filetype: videoFile.type.split("/")[1],
            });
        } else {
            setSelectedVideoError(true);
        }
    }

    // Function that handle video added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    marginLeft: leftDrawWidth / 8 + 5,
                    transition: "0.5s",
                }}
            >
                <Grid container item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search Videos"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                        <VTButton
                            onClick={() => {
                                if (activeButton === 0) {
                                    setActiveButton(1);
                                } else {
                                    setActiveButton(0);
                                }
                            }}
                            sx={{
                                borderBottom: "3px solid rgb(150, 28, 255)",
                                maxWidth: "100%",
                                height: "60px",
                            }}
                            type="underline"
                            endIcon={
                                activeButton === 0 ? (
                                    <UploadIcon sx={{ marginLeft: "10px" }} />
                                ) : (
                                    <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                )
                            }
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>{activeButton === 0 ? "Upload Video" : "Return"}</Typography>
                            )}
                        </VTButton>
                    </Grid>
                </Grid>

                {/*-------------------------------------------------------------------------------------*/}
                {/* Card display */}

                {activeButton === 0 && (
                    <>
                        {videos && videos.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                <img src={NoVideos} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    To get started, add first video file (.mp4 format)
                                </VTTypography>
                            </Grid>
                        ) : loading ? (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard2DVideo sx={{ maxWidth: "100%" }} src="" title={""} loading={true} />
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard2DVideo sx={{ maxWidth: "100%" }} src="" title={""} loading={true} />
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard2DVideo sx={{ maxWidth: "100%" }} src="" title={""} loading={true} />
                                </Grid>
                                {currentScreenWidthBreakpoint !== "md" && (
                                    <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                        <VTCard2DVideo sx={{ maxWidth: "100%" }} src="" title={""} loading={true} />
                                    </Grid>
                                )}

                                {currentScreenWidthBreakpoint === "xl" && (
                                    <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                        <VTCard2DVideo sx={{ maxWidth: "100%" }} src="" title={""} loading={true} />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {/* @ts-ignore */}
                                {videos
                                    ?.filter((video: IMediaItem) => {
                                        return (
                                            (nameBoxChecked &&
                                                video.name?.toLowerCase().includes(filterValue.toLowerCase())) ||
                                            (tagsBoxChecked &&
                                                video.tags.filter((tag: string) => {
                                                    return tag.toLowerCase().includes(filterValue.toLowerCase());
                                                }).length > 0)
                                        );
                                    })
                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                    })
                                    .map((video: IMediaItem, i: number) => (
                                        <Grid
                                            key={video.id}
                                            xl={2}
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            item
                                            container
                                            justifyContent={"center"}
                                        >
                                            <VTCard2DVideo
                                                ref={i === 0 ? vtCardRef : undefined}
                                                titleCutOffPoint={titleCutOffPoint}
                                                title={video.name ? video.name : "no name"}
                                                sx={{ width: "100%", maxWidth: "none" }}
                                                src={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${video.id}.${video.filetype}`}
                                                loading={showSkeletonForCardById === video.id}
                                                onTitleEdited={(editedTitle: string) => {
                                                    // setChangedItem(image.id);
                                                    setShowSkeletonForCardById(video.id);
                                                    updateAllVideos({
                                                        id: video.id,
                                                        name: editedTitle,
                                                        media_type: EMedia.VIDEO,
                                                    });
                                                }}
                                                menuOptions={{
                                                    menuOptionsList: [
                                                        {
                                                            component: (
                                                                <>
                                                                    {organisation === "virtus-tech" && (
                                                                        <VTButton
                                                                            type={"list"}
                                                                            startIcon={<DownloadIcon />}
                                                                            onClick={() => {
                                                                                const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/video/${video.id}.${video.filetype}`;

                                                                                const link =
                                                                                    document.createElement("a");
                                                                                link.href = imageUrl;

                                                                                document.body.appendChild(link);
                                                                                link.click();

                                                                                document.body.removeChild(link);
                                                                            }}
                                                                        >
                                                                            Download
                                                                        </VTButton>
                                                                    )}
                                                                </>
                                                            ),
                                                        },

                                                        {
                                                            component: (
                                                                <VTButton
                                                                    type={"list"}
                                                                    startIcon={<DeleteIcon />}
                                                                    disabled={false}
                                                                    onClick={() => {
                                                                        setShowSkeletonForCardById(video.id);
                                                                        deleteMediaItem(EMedia.VIDEO, userId, video.id);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </VTButton>
                                                            ),
                                                        },
                                                    ],
                                                    menuPopOutZIndex: 100,
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    </>
                )}

                {activeButton === 1 && (
                    <Grid container justifyContent={"center"} alignContent={"center"}>
                        <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                            {selectedVideo ? (
                                <Grid container>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <VTTextField
                                            value={videoName}
                                            onChange={(value) => setVideoName(value)}
                                            disabled={mediaUploading || mediaUploadCompleted}
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <video width="100%" height="100%" controls>
                                            <source src={URL.createObjectURL(selectedVideo)} type="video/mp4" />
                                        </video>
                                    </Grid>
                                    {preSignedUrlLoading ? (
                                        <Grid
                                            xs={12}
                                            sx={{
                                                margin: "10px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <>
                                            {mediaUploading || mediaUploadCompleted ? (
                                                <>
                                                    {mediaUploadError ? (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    Error Uploading Video
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    Cancel
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={mediaUploadPercentage}
                                                                />
                                                            </Grid>
                                                            {mediaUploadCompleted && (
                                                                <Grid
                                                                    xs={6}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "2px",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        fullWidth={true}
                                                                        onClick={() => {
                                                                            setSelectedVideo(null);
                                                                            setVideoId(uuid());
                                                                            setVideoName("");
                                                                            dispatch(setMediaUploadCleanUp());
                                                                        }}
                                                                    >
                                                                        Upload Another
                                                                    </VTButton>
                                                                </Grid>
                                                            )}
                                                            <Grid
                                                                xs={mediaUploadCompleted ? 6 : 12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={
                                                                        mediaUploadCompleted ? "primary" : "secondary"
                                                                    }
                                                                    fullWidth={true}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    {mediaUploadCompleted ? "Done" : "Cancel"}
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            color={"secondary"}
                                                            onClick={() => setSelectedVideo(null)}
                                                            fullWidth={true}
                                                        >
                                                            Cancel
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            onClick={() => {
                                                                if (videoName.length > 0) {
                                                                    handleVideoUpload();
                                                                } else {
                                                                    dispatch(
                                                                        setCurrentToast({
                                                                            id: "2d videos",
                                                                            message: "must enter a name for 2d video",
                                                                        }),
                                                                    );
                                                                }
                                                            }}
                                                            fullWidth={true}
                                                        >
                                                            upload
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    //TODO: user this as an overlay
                                    onDragEnter={() => setHighlight(true)}
                                    onDragLeave={() => setHighlight(false)}
                                    sx={{
                                        border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                        borderRadius: "15px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <img
                                            src={"/media/images/upload.svg"}
                                            alt="upload media"
                                            style={{ maxHeight: "200px" }}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <Typography align="center">
                                            Drop or choose Video to upload (.mp4 format)
                                        </Typography>

                                        {selectedVideoError && (
                                            <Typography align="center" sx={{ color: "red" }}>
                                                file type not accepted
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <input
                                            accept="video/mp4"
                                            type="file"
                                            id="file"
                                            ref={inputFileRef}
                                            style={{ display: "none" }}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                        <VTButton
                                            onClick={() => {
                                                if (inputFileRef && inputFileRef.current) {
                                                    inputFileRef.current.click();
                                                }
                                            }}
                                        >
                                            Choose File
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
