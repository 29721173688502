//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    CurrentPage,
    EditHotSpotDialogTab,
    ICurrentHotspot,
    ICurrentSidePanel,
    ICurrentState,
    IDragOptions,
} from "../../models/current.models";
import { IScene } from "../../models/scenario.models";
import { IHotspot } from "../../models/hotspot.models";
import { uploadMedia } from "../thunks/upload.thunks";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
const currentInitialState: ICurrentState = {
    currentScene: {} as IScene,
    currentHotspot: { currentHotspot: undefined, open: false },
    currentSidePanel: { currentTab: undefined, width: 0, option: undefined },
    currentQuestion: {} as IHotspot,
    currentlyDragging: undefined,
    currentAttachedHotspot: {} as IHotspot,
    currentAudio: "",
    currentFeedbackImage: "",
    currentFeedbackVideo: "",
    currentFeedbackInput: "",
    currentLookAt: undefined,
    //TODO; Replace uses with currentHotpsot
    editHotspotDialogIsCurrentlyOpen: false,
    editHotspotDialogTabCurrentlyOpen: undefined,
    editHotspotDialogMediaTransfer: undefined,
    currentPage: undefined,
    hotspotAdded: undefined,
    currentToast: undefined,
    currentSettingStartLocation: false,
    currentlyPreview: false,
};

const currentReducer = createSlice({
    name: "current",
    initialState: currentInitialState,
    reducers: {
        setCurrentScene: (state: ICurrentState, { payload }: PayloadAction<IScene>) => {
            state.currentScene = payload;
        },

        setCurrentPage: (state: ICurrentState, { payload }: PayloadAction<CurrentPage>) => {
            state.currentPage = payload;
        },
        setCurrentHotspot: (state: ICurrentState, { payload }: PayloadAction<ICurrentHotspot>) => {
            state.currentHotspot = payload;
        },
        setCurrentSidePanel: (state: ICurrentState, { payload }: PayloadAction<ICurrentSidePanel>) => {
            state.currentSidePanel = payload;
        },
        setCurrentQuestion: (state: ICurrentState, { payload }: PayloadAction<IHotspot | undefined>) => {
            if (payload) {
                state.currentQuestion = payload;
            }
        },
        setCurrentDragging: (state: ICurrentState, { payload }: PayloadAction<IDragOptions | undefined>) => {
            state.currentlyDragging = payload;
        },

        setCurrentAttachedHotspot: (state: ICurrentState, { payload }: PayloadAction<IHotspot>) => {
            state.currentAttachedHotspot = payload;
        },

        setCurrentAudio: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentAudio = payload;
        },
        setCurrentFeedbackImage: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackImage = payload;
        },
        setCurrentFeedbackVideo: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackVideo = payload;
        },
        setCurrentFeedbackInput: (state: ICurrentState, { payload }: PayloadAction<string>) => {
            state.currentFeedbackInput = payload;
        },
        setCurrentLookAt: (state: ICurrentState, { payload }: PayloadAction<number[] | undefined>) => {
            state.currentLookAt = payload;
        },
        setEditHotspotDialogIsCurrentlyOpen: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.editHotspotDialogIsCurrentlyOpen = payload;
        },
        setEditHotspotDialogTabCurrentlyOpen: (
            state: ICurrentState,
            { payload }: PayloadAction<EditHotSpotDialogTab | undefined>,
        ) => {
            state.editHotspotDialogTabCurrentlyOpen = payload;
        },
        setEditHotspotDialogMediaTransfer: (
            state: ICurrentState,
            { payload }: PayloadAction<{ src: string; type: EDragContentOptions } | undefined>,
        ) => {
            state.editHotspotDialogMediaTransfer = payload;
        },
        setHotspotAdded: (state: ICurrentState, { payload }: PayloadAction<IHotspot | undefined>) => {
            state.hotspotAdded = payload;
        },
        setCurrentToast: (
            state: ICurrentState,
            { payload }: PayloadAction<{ id: string; message?: string; time?: number; component?: any } | undefined>,
        ) => {
            state.currentToast = payload;
        },
        setCurrentStartingLocation: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.currentSettingStartLocation = payload;
        },
        setCurrentlyPreview: (state: ICurrentState, { payload }: PayloadAction<boolean>) => {
            state.currentlyPreview = payload;
        },
    },
});

export const {
    setCurrentScene,
    setCurrentHotspot,
    setCurrentQuestion,
    setCurrentDragging,
    setCurrentSidePanel,
    setCurrentAttachedHotspot,
    setCurrentAudio,
    setEditHotspotDialogIsCurrentlyOpen,
    setEditHotspotDialogTabCurrentlyOpen,
    setCurrentFeedbackImage,
    setCurrentFeedbackVideo,
    setCurrentFeedbackInput,
    setCurrentLookAt,
    setEditHotspotDialogMediaTransfer,
    setCurrentPage,
    setHotspotAdded,
    setCurrentToast,
    setCurrentStartingLocation,
    setCurrentlyPreview,
} = currentReducer.actions;

export default currentReducer.reducer;
