import {
    VTButton,
    VTCard,
    VTMenu,
    VTTextField,
    VTTypography,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    Typography,
} from "@mui/material";

import { SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    usePreSignedUrlMutation,
    useUpdateAllImagesMutation,
} from "../../services/media.service";

import { v4 as uuid } from "uuid";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";
import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import { setCurrentToast } from "../../store/slices/current.slice";
interface IProps {
    images: IMediaItem[] | undefined;
    leftDrawWidth: number;
    deleteMediaItem: (mediaType: EMedia, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
    loading: boolean;
}

export default function ImagesPageCardContainer({
    images,
    leftDrawWidth,
    deleteMediaItem,
    deleteLoading,
    loading,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();

    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint();

    console.log("images data", images);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [createAllImages] = useCreateAllImagesMutation();
    const [updateAllImages, { isLoading: imageUpdateLoading }] = useUpdateAllImagesMutation();

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    const [filterValue, setFilterValue] = useState<string>("");
    const [changedItem, setChangedItem] = useState<string>("");

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            createAllImages({
                id: userId,
                media_id: imageId,
                media_type: "images",
                filetype: selectedImage.type.split("/")[1],
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
            });
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading && !imageUpdateLoading) {
            setChangedItem("");
        }
    }, [deleteLoading, imageUpdateLoading]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && ["jpeg", "jpg", "png"].includes(imageFile.name.split(".")[1])) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "images",
                media_id: imageId,
                filetype: imageFile.type.split("/")[1],
            });
        } else {
            setSelectedImageError(true);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle image added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    marginLeft: leftDrawWidth / 8 + 5,
                    transition: "0.5s",
                }}
            >
                <Grid container item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search Images"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                        <VTButton
                            onClick={() => {
                                if (activeButton === 0) {
                                    setActiveButton(1);
                                } else {
                                    setActiveButton(0);
                                }
                            }}
                            sx={{
                                borderBottom: "3px solid rgb(150, 28, 255)",
                                maxWidth: "100%",
                                height: "60px",
                            }}
                            type="underline"
                            endIcon={
                                activeButton === 0 ? (
                                    <UploadIcon sx={{ marginLeft: "10px" }} />
                                ) : (
                                    <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                )
                            }
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>{activeButton === 0 ? "Upload Image" : "Return"}</Typography>
                            )}
                        </VTButton>
                    </Grid>
                </Grid>
                {/*-------------------------------------------------------------------------------------*/}
                {/* Card display */}
                {activeButton === 0 && (
                    <>
                        {images && images.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                <img src={NoScenarios} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    To get started, add first image file (.png, .jpg and .jpeg formats)
                                </VTTypography>
                            </Grid>
                        ) : loading ? (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                {currentScreenWidthBreakpoint !== "md" && (
                                    <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                )}

                                {currentScreenWidthBreakpoint === "xl" && (
                                    <Grid item sm={6} md={4} lg={3} xl={2} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {/* @ts-ignore */}
                                {images
                                    ?.filter((image: IMediaItem) => {
                                        return (
                                            (nameBoxChecked &&
                                                image.name?.toLowerCase().includes(filterValue.toLowerCase())) ||
                                            (tagsBoxChecked &&
                                                image.tags.filter((tag: string) => {
                                                    return tag.toLowerCase().includes(filterValue.toLowerCase());
                                                }).length > 0)
                                        );
                                    })
                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                    })
                                    .map((image: IMediaItem, i: number) => (
                                        <Grid
                                            key={image.id}
                                            xl={2}
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            item
                                            container
                                            justifyContent={"center"}
                                        >
                                            <VTCard
                                                ref={i === 0 ? vtCardRef : undefined}
                                                titleCutOffPoint={titleCutOffPoint}
                                                onTitleEdited={(editedTitle: string) => {
                                                    setChangedItem(image.id);
                                                    updateAllImages({
                                                        id: image.id,
                                                        name: editedTitle,
                                                        media_type: EMedia.IMAGE,
                                                    });
                                                }}
                                                key={image.id}
                                                sx={{ width: "100%", maxWidth: "none" }}
                                                title={image.name ? image.name : "no name"}
                                                mediaType={{
                                                    imageUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${image.id}.${image.filetype}`,
                                                }}
                                                loading={
                                                    (deleteLoading || imageUpdateLoading) && changedItem === image.id
                                                }
                                                onWholeCardClicked={() => {}}
                                                menuOptions={{
                                                    menuOptionsList: [
                                                        {
                                                            component: (
                                                                <>
                                                                    {organisation === "virtus-tech" && (
                                                                        <VTButton
                                                                            type={"list"}
                                                                            startIcon={<DownloadIcon />}
                                                                            onClick={() => {
                                                                                const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/images/${image.id}.${image.filetype}`;

                                                                                const link =
                                                                                    document.createElement("a");
                                                                                link.href = imageUrl;

                                                                                document.body.appendChild(link);
                                                                                link.click();

                                                                                document.body.removeChild(link);
                                                                            }}
                                                                        >
                                                                            Download
                                                                        </VTButton>
                                                                    )}
                                                                </>
                                                            ),
                                                        },
                                                        {
                                                            component: (
                                                                <VTButton
                                                                    type={"list"}
                                                                    startIcon={<DeleteIcon />}
                                                                    disabled={false}
                                                                    onClick={() => {
                                                                        setChangedItem(image.id);
                                                                        deleteMediaItem(EMedia.IMAGE, userId, image.id);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </VTButton>
                                                            ),
                                                        },
                                                    ],
                                                    menuPopOutZIndex: 100,
                                                }}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    </>
                )}

                {activeButton === 1 && (
                    <Grid container justifyContent={"center"} alignContent={"center"}>
                        <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                            {selectedImage ? (
                                <Grid container>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <VTTextField
                                            value={imageName}
                                            onChange={(value) => setImageName(value)}
                                            disabled={mediaUploading || mediaUploadCompleted}
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Selected"
                                            width="100%"
                                            height="250px"
                                            style={{
                                                borderRadius: "15px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Grid>
                                    {preSignedUrlLoading ? (
                                        <Grid
                                            xs={12}
                                            sx={{
                                                margin: "10px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <>
                                            {mediaUploading || mediaUploadCompleted ? (
                                                <>
                                                    {mediaUploadError ? (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    Error Uploading Image
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    Cancel
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={mediaUploadPercentage}
                                                                />
                                                            </Grid>
                                                            {mediaUploadCompleted && (
                                                                <Grid
                                                                    xs={6}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "2px",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        fullWidth={true}
                                                                        onClick={() => {
                                                                            setSelectedImage(null);
                                                                            setImageId(uuid());
                                                                            setImageName("");
                                                                            dispatch(setMediaUploadCleanUp());
                                                                        }}
                                                                    >
                                                                        Upload Another
                                                                    </VTButton>
                                                                </Grid>
                                                            )}
                                                            <Grid
                                                                xs={mediaUploadCompleted ? 6 : 12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={
                                                                        mediaUploadCompleted ? "primary" : "secondary"
                                                                    }
                                                                    fullWidth={true}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    {mediaUploadCompleted ? "Done" : "Cancel"}
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            color={"secondary"}
                                                            onClick={() => setSelectedImage(null)}
                                                            fullWidth={true}
                                                        >
                                                            Cancel
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            onClick={() => {
                                                                if (imageName.length > 0) {
                                                                    handleImageUpload();
                                                                } else {
                                                                    dispatch(
                                                                        setCurrentToast({
                                                                            id: "2d images",
                                                                            message: "must enter a name for image",
                                                                        }),
                                                                    );
                                                                }
                                                            }}
                                                            fullWidth={true}
                                                        >
                                                            upload
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    //TODO: user this as an overlay
                                    onDragEnter={() => setHighlight(true)}
                                    onDragLeave={() => setHighlight(false)}
                                    sx={{
                                        border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                        borderRadius: "15px",
                                        MaxWidth: "100%",
                                    }}
                                >
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <img
                                            src={"/media/images/upload.svg"}
                                            alt="upload media"
                                            style={{ maxHeight: "200px" }}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <Typography align="center">
                                            Drop or choose image to upload (.png, .jpg and .jpeg formats)
                                        </Typography>

                                        {selectedImageError && (
                                            <Typography align="center" sx={{ color: "red" }}>
                                                file type not accepted
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <input
                                            accept="image/*"
                                            type="file"
                                            id="file"
                                            ref={inputFileRef}
                                            style={{ display: "none" }}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                        <VTButton
                                            onClick={() => {
                                                if (inputFileRef && inputFileRef.current) {
                                                    inputFileRef.current.click();
                                                }
                                            }}
                                        >
                                            Choose File
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
