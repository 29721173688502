//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, useMediaQuery } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import ReactPlayer from "react-player";
import { getAudioClipDuration, getDurationOfAudioInSeconds } from "../../utils/getAudioClipDuration";
import { IHotspot } from "../../models/hotspot.models";
import { useGetScenarioQuery, useUpdateScenarioMutation } from "../../services/scenario.service";
import { Params, useParams } from "react-router-dom";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentAudio: string;
    setCurrentAudio: (currentAudio: string) => void;
    disabled?: boolean;
    sx?: SxProps;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTAudioDropper({ currentAudio, setCurrentAudio, disabled = false, sx }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { currentSidePanel, currentlyDragging } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px", ...sx }}>
            {/*----------------------------------------------------------------------------------------*/}
            {/* Relative container to allow the drop box to overlap the image */}
            <Grid item container sx={{ position: "relative" }} xs={12} justifyContent={"center"}>
                {(currentAudio !== "" || (currentSidePanel.width !== 0 && currentSidePanel.currentTab === "Audio")) && (
                    <>
                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Shows the current preview image or a default image is none set */}

                        {currentAudio === "" ? (
                            <img
                                src={
                                    "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                }
                                style={{
                                    width: "80%",
                                    height: "193px",
                                    objectFit: "cover",
                                    borderRadius: 15,
                                }}
                            />
                        ) : (
                            <ReactPlayer url={currentAudio} width={"80%"} height={"194px"} controls />
                        )}
                        {/*-------------------------------------------------------------------------------------*/}
                        {/* Drop field, accepts urls from dragged images to set image, style to be less visible */}
                        {currentlyDragging && (
                            <TextField
                                value={""}
                                onChange={async (e) => {
                                    if (currentlyDragging && !disabled) {
                                        // currentlyDragging && setCurrentAudio(currentlyDragging.src);

                                        const audioDurationInSecs = await getDurationOfAudioInSeconds(
                                            currentlyDragging.src,
                                        );

                                        const audioDurationRoundedUpToNearestSecond = Math.ceil(audioDurationInSecs);

                                        setCurrentAudio(currentlyDragging.src);
                                    }
                                }}
                                sx={{
                                    width: "80%",
                                    position: "absolute",
                                    top: 0,
                                    backgroundColor: "rgba(0,0,0,0)",
                                    border: "2px solid #7315ce",
                                    borderRadius: "15px",
                                    color: "red",
                                }}
                                inputProps={{ style: { color: "#7315ce" } }}
                                multiline
                                rows={7}
                            />
                        )}
                    </>
                )}

                <Grid item container xs={12} justifyContent={"space-evenly"}>
                    {/*----------------------------------------------------------------------------*/}
                    {/* Button to open the image side panel */}
                    {(currentSidePanel.width === 0 || currentSidePanel.currentTab !== "Audio") && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Audio",
                                            width: 400,
                                            option: undefined,
                                        }),
                                    )
                                }
                            >
                                Choose Audio
                            </VTButton>
                        </Grid>
                    )}

                    {currentAudio !== "" && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() => setCurrentAudio("")}
                            >
                                Remove Audio
                            </VTButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
