//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Card, CardContent, CardMedia, Grid, Skeleton } from "@mui/material";
// Custom components imports (and hooks and helpers)

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTSkeletonCard({}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function returnSkeletonCard() {
        return (
            <Card elevation={5} sx={{ height: "310px", width: "100%" }}>
                {/*---------------------------------------------------------------------------------------*/}
                {/* Skeleton Card Image */}
                <CardMedia>
                    <Skeleton sx={{ height: "200px" }} animation="wave" variant="rectangular" />
                </CardMedia>

                {/*---------------------------------------------------------------------------------------*/}
                {/* Skeleton Card Title */}
                <CardContent sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Grid container>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Skeleton
                                animation="wave"
                                height={"30px"}
                                sx={{
                                    width: "85%",
                                    marginTop: "0px",
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {/*---------------------------------------------------------------------------------------*/}
                            {/* Skeleton Card Text */}
                            <Skeleton animation="wave" height={25} width="100%" sx={{ marginTop: "10px" }} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Render skeleton cards
    return (
        <Grid container justifyContent={"space-evenly"} spacing={3} sx={{}}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                {returnSkeletonCard()}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                {returnSkeletonCard()}
            </Grid>
            <Grid item sm={4} lg={3} sx={{ display: { md: "block", xs: "none" } }}>
                {returnSkeletonCard()}
            </Grid>
            <Grid item lg={3} sx={{ display: { lg: "block", xs: "none" } }}>
                {returnSkeletonCard()}
            </Grid>
        </Grid>
    );
}
