//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import * as THREE from "three";

// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    Switch,
    SxProps,
    Tooltip,
    Typography,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import {
    VTButton,
    VTCanvas,
    VTTextField,
    VTTypography,
    VTVideoControls,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview } from "@mui/icons-material";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllImagesMutation,
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import VTImmersiveVideoDraggable from "./VTImmersiveVideoDraggable";
import { IDragOptions } from "../../models/current.models";
import VTCreatorVideoControls from "./VTCreatorVideoControls";
import { Canvas } from "@react-three/fiber";
// Media imports
import NoVideos from "../../assets/images/NoVideos.svg";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import VTSphere from "./VTSphere";
import { ExposedCameraControlFunctions } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCanvasComponents/VTCameraControls";
import { setCurrentToast } from "../../store/slices/current.slice";
//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    handleDragStart: any;
    handleDragStop: () => void;
    handleTimelineDrop: (Drag: IDragOptions) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveVideoSidePanel({ handleDragStart, handleDragStop, sx, handleTimelineDrop }: any) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const cameraControlsRef = useRef<ExposedCameraControlFunctions>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const [createImmersiveVideos] = useCreateAllImagesMutation();

    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [capturePreview, setCapturePreview] = useState<boolean>(false);

    const [highlight, setHighlight] = useState(false);

    const { data: videos, isLoading: videosLoading } = useGetAll360VideosQuery(userId);
    const { data: scenarioVideos, isLoading: scenarioVideosLoading } = useGetAll360VideosQuery(scenarioId!);

    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    const dispatch = useAppDispatch();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [checked, setChecked] = useState(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            if (checked) {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                    chopping: "processing",
                });
            } else {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                });
            }
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    function handleDoubleClick(mediaType: EDragContentOptions, src: string, preview?: string) {
        handleTimelineDrop({
            mediaType: mediaType,
            src: src,
            preview: preview,
        });
    }

    //-------------------------------------------------------------------------------------------------
    // Function to get src and size images based on size
    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    //-------------------------------------------------------------------------------------------------
    // Function to assign image widths
    function colSizeSwitch(param: number) {
        switch (param) {
            case 0:
                return 2;
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 2;
            case 4:
                return 2;
            case 5:
                return 2;
            case 6:
                return 1;
            case 7:
                return 1;
            case 8:
                return 2;
        }
    }

    //-------------------------------------------------------------------------------------------------
    // Function to assign image heights
    function rowSizeSwitch(param: number) {
        switch (param) {
            case 0:
                return 2;
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 1;
            case 4:
                return 1;
            case 5:
                return 2;
            case 6:
                return 1;
            case 7:
                return 1;
            case 8:
                return 1;
        }
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDragContentOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain videos types
        if (videoFile.type.includes("video/")) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            if (checked) {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/raw`,
                    filetype: videoFile.type.split("/")[1],
                });
            } else {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/${videoId}`,
                    filetype: videoFile.type.split("/")[1],
                });
            }
        } else {
            setSelectedVideoError(true);
        }
    }

    async function handleGetPreviewURL() {
        // Get presigned url to upload image
        await preSignedUrl({
            organisation,
            media_type: "three_sixty_video",
            media_id: `${videoId}/${videoId}_preview`,
            filetype: "png",
        });
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    async function handlePreviewUpload() {
        if (videoPreview) {
            const file = await dataUrlToFile(videoPreview, "preview");

            if (uploadUrl && videoPreview) {
                dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                );
            }
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
        setCapturePreview(false);
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setChecked(event.target.checked);

        if (event.target.checked) {
            dispatch(
                setCurrentToast({
                    id: "360_video_process_toast",
                    message: "This will proccess the 360 video metadata and upload for you.",
                }),
            );
        }
    }
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available Videos
    return (
        <Grid container xs={12} justifyContent={"center"} alignContent={"flex-start"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Videos"}
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    height: "80px",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Videos</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    {scenarioVideos && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? "3px solid rgb(150, 28, 255)" : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography>Scenario Videos</Typography>
                        </VTButton>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Videos</Typography>
                    </VTButton>
                </Grid>
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video upload */}
            {activeButton === 1 && (
                <>
                    {capturePreview && selectedVideo ? (
                        <Grid
                            item
                            container
                            sx={{
                                marginTop: "20px",
                                padding: "20px",
                                overflowY: "auto",
                                overflowX: "hidden",
                                height: "calc(100vh - 300px)",
                                width: "100%",
                            }}
                        >
                            <Grid item xs={12} sx={{ height: "300px", position: "relative" }}>
                                <VTCanvas
                                    ref={cameraControlsRef}
                                    screenShot={(preview) => setVideoPreview(preview)}
                                    style={{ borderRadius: "15px" }}
                                >
                                    <VTSphere
                                        video={video}
                                        setVideo={setVideo}
                                        videoSrc={`${
                                            process.env.REACT_APP_NINJA_MEDIA
                                        }/${organisation}/three_sixty_video/${videoId}/${videoId}.${
                                            selectedVideo!.type.split("/")[1]
                                        }`}
                                        setVideoCurrentTime={setVideoCurrentTime}
                                    />
                                </VTCanvas>
                                <VTVideoControls
                                    videoCurrentTime={videoCurrentTime}
                                    video={video}
                                    videoCurrentState={videoCurrentState}
                                    setVideoCurrentState={setVideoCurrentState}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent={"flex-start"}
                                sx={{ height: "200px", paddingTop: "10px" }}
                            >
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading && (
                                            <Grid
                                                xs={12}
                                                sx={{
                                                    margin: "10px 0",
                                                    width: "100%",
                                                }}
                                            >
                                                <LinearProgress variant="determinate" value={mediaUploadPercentage} />
                                            </Grid>
                                        )}

                                        {mediaUploadCompleted ? (
                                            <>
                                                <Grid item xs={6}>
                                                    <VTButton
                                                        color={"secondary"}
                                                        fullWidth={true}
                                                        onClick={() => {
                                                            setSelectedVideo(null);
                                                            setVideoId(uuid());
                                                            setVideoName("");
                                                            dispatch(setMediaUploadCleanUp());
                                                            setCapturePreview(false);
                                                        }}
                                                    >
                                                        Upload Another
                                                    </VTButton>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <VTButton
                                                        fullWidth={true}
                                                        onClick={() => {
                                                            handleUploadCleanUp();
                                                        }}
                                                    >
                                                        Done
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={6} sx={{ paddingRight: "2px" }}>
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => handleUploadCleanUp()}
                                                        fullWidth
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid item xs={6} sx={{ paddingLeft: "2px" }}>
                                                    <VTButton
                                                        disabled={mediaUploading}
                                                        onClick={() => {
                                                            if (cameraControlsRef.current) {
                                                                cameraControlsRef.current.takeScreenShot();
                                                                handlePreviewUpload();
                                                            }
                                                        }}
                                                        fullWidth
                                                    >
                                                        Save Preview
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    ) : (
                        <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                            {selectedVideo ? (
                                <Grid container>
                                    <Grid item xs={12} sx={{ margin: "10px 5px" }}>
                                        <VTTextField
                                            value={videoName}
                                            onChange={(value) => setVideoName(value)}
                                            disabled={mediaUploading || mediaUploadCompleted}
                                        />
                                    </Grid>
                                    {!checked && (
                                        <Grid item xs={12} sx={{ margin: "10px 5px" }}>
                                            <video width="100%" height="100%" controls>
                                                <source src={URL.createObjectURL(selectedVideo)} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </Grid>
                                    )}

                                    {preSignedUrlLoading ? (
                                        <Grid
                                            xs={12}
                                            item
                                            sx={{
                                                margin: "10px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <>
                                            {mediaUploading || mediaUploadCompleted ? (
                                                <>
                                                    {mediaUploadError ? (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                item
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    Error Uploading Image
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={12}
                                                                item
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    Cancel
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={mediaUploadPercentage}
                                                                />
                                                            </Grid>
                                                            {mediaUploadCompleted ? (
                                                                <>
                                                                    <Grid
                                                                        xs={12}
                                                                        item
                                                                        sx={{
                                                                            margin: "10px 0",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            padding: "2px",
                                                                        }}
                                                                    >
                                                                        <VTButton
                                                                            color={"secondary"}
                                                                            fullWidth={true}
                                                                            onClick={() => {
                                                                                handleUploadCleanUp();
                                                                            }}
                                                                        >
                                                                            Done
                                                                        </VTButton>
                                                                    </Grid>
                                                                    {/* <Grid
                                                                        xs={6}
                                                                        item
                                                                        sx={{
                                                                            margin: "10px 0",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            padding: "2px",
                                                                        }}
                                                                    >
                                                                        <VTButton
                                                                            color={
                                                                                mediaUploadCompleted
                                                                                    ? "primary"
                                                                                    : "secondary"
                                                                            }
                                                                            fullWidth={true}
                                                                            onClick={() => {
                                                                                setCapturePreview(true);
                                                                                dispatch(setMediaUploadCleanUp());
                                                                                handleGetPreviewURL();
                                                                            }}
                                                                        >
                                                                            Set Preview
                                                                        </VTButton>
                                                                    </Grid> */}
                                                                </>
                                                            ) : (
                                                                <Grid
                                                                    xs={12}
                                                                    item
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "2px",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        fullWidth={true}
                                                                        onClick={() => handleUploadCleanUp()}
                                                                    >
                                                                        Cancel
                                                                    </VTButton>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Grid
                                                        xs={6}
                                                        item
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            color={"secondary"}
                                                            onClick={() => setSelectedVideo(null)}
                                                            fullWidth={true}
                                                        >
                                                            Cancel
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid
                                                        xs={6}
                                                        item
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton onClick={() => handleVideoUpload()} fullWidth={true}>
                                                            upload
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    //TODO: user this as an overlay
                                    onDragEnter={() => setHighlight(true)}
                                    onDragLeave={() => setHighlight(false)}
                                    sx={{
                                        border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                        borderRadius: "15px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        <Typography sx={{ marginTop: "6px" }}>Procces 360 Video</Typography>
                                        <Switch
                                            checked={checked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="center">Drop or choose video to upload</Typography>

                                        {selectedVideoError && (
                                            <Typography align="center" sx={{ color: "red" }}>
                                                File types accepted: MP4
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <input
                                            accept="video/mp4"
                                            type="file"
                                            id="file"
                                            ref={inputFileRef}
                                            style={{ display: "none" }}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                        <VTButton
                                            onClick={() => {
                                                if (inputFileRef && inputFileRef.current) {
                                                    inputFileRef.current.click();
                                                }
                                            }}
                                        >
                                            Choose File
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    )}
                </>
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {videosLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!videos || videos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"} justifyContent={"center"}>
                                    <img src={NoVideos} style={{ maxWidth: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {videos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <>
                                            {videos
                                                ?.filter(
                                                    (item: IMediaItem) =>
                                                        searchValue === "" ||
                                                        item
                                                            .name!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()) ||
                                                        item
                                                            .description!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()),
                                                )
                                                .sort((a: IMediaItem, b: IMediaItem) => {
                                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                })
                                                .map((item: IMediaItem) => (
                                                    <VTImmersiveVideoDraggable
                                                        key={item.id}
                                                        item={item}
                                                        organisation={organisation}
                                                        localHandleDrag={localHandleDrag}
                                                        handleDoubleClick={handleDoubleClick}
                                                    />
                                                ))}
                                        </>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {scenarioVideosLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioVideos || scenarioVideos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"} justifyContent={"center"}>
                                    <img src={NoVideos} style={{ maxWidth: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {scenarioVideos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        scenarioVideos
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: IMediaItem, index: number) => (
                                                <>
                                                    {(updateLoading || deleteLoading) &&
                                                    currentItemUpdate == item.id ? (
                                                        <Grid item xs={6}>
                                                            <Skeleton
                                                                sx={{
                                                                    height: "300px",
                                                                    margin: "10px",
                                                                    marginBlock: "-25px",
                                                                    marginTop: "-55px",
                                                                }}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <VTImmersiveVideoDraggable
                                                            item={item}
                                                            organisation={organisation}
                                                            localHandleDrag={localHandleDrag}
                                                            handleDoubleClick={handleDoubleClick}
                                                            addFunction={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                updateAllScenarioMedia({
                                                                    id: userId,
                                                                    media_type: EMedia.VIDEO_360,
                                                                    media_ids: [item.id],
                                                                });
                                                            }}
                                                            deleteFunction={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                deleteAllScenarioMedia({
                                                                    mediaType: EMedia.VIDEO_360,
                                                                    collectionId: scenarioId!,
                                                                    mediaId: item.id,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            ))
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
