//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IScenario, IScenarioOverview, IScene } from "../models/scenario.models";

const isDev: boolean = process.env.REACT_APP_IS_LOCAL === "true";

// Custom components imports (and hooks and helpers)
export const ScenarioApi = createApi({
    reducerPath: "scenario",
    tagTypes: ["scenarioOverview", "scene", "hotspot"],
    baseQuery: fetchBaseQuery({
        baseUrl: isDev ? `${process.env.PUBLIC_URL}/static` : process.env.REACT_APP_NINJA_ENDPOINT,
    }),
    endpoints: (builder) => ({
        getAllScenarioOverview: builder.query<IScenarioOverview[], string>({
            query: (userId) => ({
                url: isDev ? "scenarioOverview.json" : `scenarioOverview/${userId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }: IScenarioOverview) => ({
                              type: "scenarioOverview" as const,
                              id,
                          })),
                          { type: "scenarioOverview", id: "LIST" },
                      ]
                    : [{ type: "scenarioOverview", id: "LIST" }],
        }),
        updateScenarioOverview: builder.mutation<IScenarioOverview[], IScenarioOverview>({
            query: (body) => ({
                url: "scenarioOverview",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "scenarioOverview", id: "LIST" }],
        }),
        publishScenario: builder.mutation<IScenario, string>({
            query: (id) => ({
                url: `publish/${id}`,
                method: "POST",
            }),
            invalidatesTags: [{ type: "scenarioOverview", id: "LIST" }],
        }),
        getScenario: builder.query<IScenario, string>({
            query: (id) => ({
                url: `/scenario/${id}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result && result.scenes && result.scenes.length > 0 && result.scenes !== undefined
                    ? [
                          ...result.scenes.map(({ id }: IScene) => ({
                              type: "scene" as const,
                              id,
                          })),
                          // ...result.scenes.map(({ hotspots }: IScene) => [
                          //   ...hotspots.map(({ id }: IHotspot) => ({
                          //     type: "hotspot" as const,
                          //     id,
                          //   })),
                          // ]),
                          { type: "scene", id: "LIST" },
                          // { type: "hotspot", id: "LIST" },
                      ]
                    : [
                          { type: "scene", id: "LIST" },
                          // { type: "hotspot", id: "LIST" },
                      ],
        }),
        updateScenario: builder.mutation<IScenario, Partial<IScenario>>({
            query: (body) => ({
                url: `scenario/${body.id}`,
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "scene", id: "LIST" }],
        }),
        deleteScenario: builder.mutation<IScenario, Partial<{ scenarioId: string; accessId: string }>>({
            query: ({ scenarioId, accessId }) => ({
                url: `scenario/${scenarioId}/${accessId}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "scenarioOverview", id: "LIST" }],
        }),
    }),
});

export const {
    useGetAllScenarioOverviewQuery,
    useUpdateScenarioOverviewMutation,
    usePublishScenarioMutation,
    useGetScenarioQuery,
    useUpdateScenarioMutation,
    useDeleteScenarioMutation,
} = ScenarioApi;
