//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, TextField } from "@mui/material";
// Custom components imports (and hooks and helpers)
import { IDragOptions } from "../../models/current.models";
import { useEffect } from "react";

import DropImage from "../../assets/images/image_drop.svg";
import DropVideo from "../../assets/images/video_drop.svg";
import DropImmersiveImage from "../../assets/images/360_image_drop.svg";
import DropImmersiveVideo from "../../assets/images/360_video_drop.svg";
import DropHotspot from "../../assets/images/hotspot_drop.svg";
import DropAudio from "../../assets/images/audio_drop.svg";
import DropAnswer from "../../assets/images/answer_drop.svg";
import DropInfo from "../../assets/images/info_drop.svg";
import DropProceed from "../../assets/images/arrow_right.svg";
import DropComplete from "../../assets/images/check_circle.svg";
import { VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { useAppSelector } from "../../store/hooks";
import { IHotspot } from "../../models/hotspot.models";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    handleMediaDropped: (timelineMedia: IDragOptions, hotspotId?: string) => void;
    currentlyDragging: IDragOptions;
    location: DropPoint;
    bottomDrawWidth: number;
    videoNextQuestionID?: string;
    currentInterface?: IHotspot | undefined;
}

// Location component is used for bespoke styling
export enum DropPoint {
    DROP_CANVAS = "drop canvas",
    DROP_TIMELINE = "drop timeline=",
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorDropContainer({
    handleMediaDropped,
    currentlyDragging,
    location,
    bottomDrawWidth,
    videoNextQuestionID,
    currentInterface,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { currentHotspot } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Drop container for the creator page
    return (
        <>
            <TextField
                value={""}
                onChange={(e) => {
                    // handleMediaDropped(currentlyDragging, videoNextQuestionID);
                }}
                onDrop={(e) => {
                    handleMediaDropped(currentlyDragging, videoNextQuestionID); // works on chrome/mac also
                }}
                variant="standard"
                sx={{
                    position: "absolute",
                    top: 0.5,
                    right: 0,
                    zIndex: 5,
                    backgroundColor: "rgba(0,0,0,0.0)",

                    // Styling based on location; dynamically calc height for the canvas and timeline panel

                    // Styling based on location; dynamically calc width for the canvas and timeline panel
                    height: location === DropPoint.DROP_CANVAS ? "100%" : "calc(100% - " + 4 + "px)",
                }}
                fullWidth={true}
                inputProps={{
                    style: {
                        // Set the content width; effects functionality not styling
                        height:
                            location === DropPoint.DROP_CANVAS
                                ? "calc(100vh - " + 85.5 + "px - " + bottomDrawWidth + "px)"
                                : bottomDrawWidth - 10,
                        color: "rgba(0,0,0,0)",
                        border: "none",
                    },
                    disableUnderline: true,
                }}
                multiline
            />

            <Grid
                sx={{
                    position: "absolute",
                    top: currentHotspot.currentHotspot ? "41px" : 0.5,
                    right:
                        location === DropPoint.DROP_CANVAS
                            ? "calc(5% + " + (currentInterface ? "400px" : "0px") + ")"
                            : 0,
                    zIndex: 4,
                    border: "4px dashed #9325fe",
                    marginTop: location === DropPoint.DROP_CANVAS ? "50px" : 0,
                    borderRadius: "15px",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    // Styling based on location; dynamically calc height for the canvas and timeline panel
                    width:
                        location === DropPoint.DROP_CANVAS
                            ? "calc(90% - " + (currentInterface ? 400 : 0) + "px)"
                            : "100%",
                    // Styling based on location; dynamically calc width for the canvas and timeline panel
                    height:
                        location === DropPoint.DROP_CANVAS
                            ? "calc(90vh - " + 85.5 + "px - " + bottomDrawWidth + "px)"
                            : "calc(100% - " + 4 + "px)",
                }}
                container
                justifyContent={"center"}
                alignContent={"center"}
                direction={"column"}
            >
                {location === DropPoint.DROP_CANVAS && (
                    <img
                        src={
                            currentlyDragging.mediaType === EDragContentOptions.IMAGE_360
                                ? DropImmersiveImage
                                : currentlyDragging.mediaType === EDragContentOptions.IMAGE
                                ? DropImage
                                : currentlyDragging.mediaType === EDragContentOptions.VIDEO_360
                                ? DropImmersiveVideo
                                : currentlyDragging.mediaType === EDragContentOptions.VIDEO
                                ? DropVideo
                                : currentlyDragging.mediaType === EDragContentOptions.AUDIO
                                ? DropAudio
                                : currentlyDragging.mediaType === EDragContentOptions.INFORMATION
                                ? DropInfo
                                : currentlyDragging.mediaType === EDragContentOptions.ANSWER
                                ? DropAnswer
                                : currentlyDragging.mediaType === EDragContentOptions.COMPLETE
                                ? DropComplete
                                : currentlyDragging.mediaType === EDragContentOptions.PROCEED
                                ? DropProceed
                                : DropHotspot
                        }
                        height={"40%"}
                    />
                )}
                <VTTypography type="Heading3" sx={{ marginTop: "10px" }}>
                    {location === DropPoint.DROP_CANVAS
                        ? `Please drop a ${currentlyDragging.mediaType.replaceAll("_", " ")} to add to the scene`
                        : `Please drop a ${currentlyDragging.mediaType.replaceAll("_", " ")} to add new scene`}
                </VTTypography>
            </Grid>
        </>
    );
}
