import { useEffect, useRef, useState } from "react";
import { Container, Grid, Paper } from "@mui/material";

import {
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    useGetAll360VideosQuery,
    useGetAllAudioQuery,
    useGetAllImagesQuery,
    useGetAllImmersiveModelsQuery,
    useGetAllVideos2dQuery,
} from "../../services/media.service";
import HomeRouteSidePanel from "../components/HomeRouteSidePanel";
import VideoCardContainer from "../containers/VideoCardContainer";
import { useAppSelector } from "../../store/hooks";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useDispatch } from "react-redux";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import MenuIcon from "@mui/icons-material/Menu";
import { Params, useParams } from "react-router-dom";
import Scenario360ImageCardsContainer from "../containers/Scenes360ImageCardsContainer";
import ImmersiveVideosRepoContainer from "../containers/ImmersiveVideosRepoContainer";
import ImmersiveModelsCardContainer from "../containers/ImmersiveModelsCardContainer";
import ImagesPageCardContainer from "../containers/ImagesPageCardContainer";
import AudioCardContainer from "../containers/AudioCardContainer";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReactGA from "react-ga4";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";

export function MediaWrapper() {
    const { id: userId } = useAppSelector((state) => state.userReducer);

    const { mediaPage }: Readonly<Params<string>> = useParams();
    const dispatch = useDispatch();
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);

    const { data: immersiveImageData, isLoading: immersiveImagesLoading } = useGetAll360ImagesQuery(userId);
    const { data: immersiveVideoData, isLoading: immersiveVideosLoading } = useGetAll360VideosQuery(userId);
    const { data: imageData, isLoading: imagesLoading } = useGetAllImagesQuery(userId);
    const { data: videoData, isLoading: videosLoading } = useGetAllVideos2dQuery(userId);
    const { data: audioData, isLoading: audioLoading } = useGetAllAudioQuery(userId);
    const { data: modelData, isLoading: modelsLoading } = useGetAllImmersiveModelsQuery(userId);

    const gridContainerRef = useRef<any>();

    const [deleteMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    useEffect(() => {
        if (gridContainerRef.current) {
            gridContainerRef.current.scrollTo(0, 0);
        }

        ReactGA.send({ hitType: "pageview", page: "/Media", title: mediaPage });
    }, [mediaPage]);

    function deleteMediaItem(mediaType: EMedia, collectionId: string, mediaId: string) {
        deleteMedia({ mediaType: mediaType, collectionId: collectionId, mediaId: mediaId });
    }

    return (
        <Grid container xs={12} sx={{ overflowY: "auto", height: "100%" }}>
            <Grid ref={gridContainerRef} xs={12} item container>
                <Paper
                    sx={{
                        zIndex: 1330,
                        position: "absolute",
                        top: "68px",
                        left: currentSidePanel.width ? 0 : -200,
                        bottom: 0,
                        borderRadius: 0,
                        width: 200,
                        transition: "0.5s",
                        backgroundColor: "#303030",
                    }}
                >
                    {currentSidePanel.width === 0 ? (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "right",
                                toolTipText: "Expand",
                            }}
                            sx={{
                                position: "absolute",
                                top: "5px",
                                left: 200,
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                backgroundColor: "#444",
                                fontSize: "0.3em",
                                fontWeight: "bold",
                                ":hover": {
                                    cursor: "pointer",
                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Routes",
                                        width: 200,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <KeyboardArrowRightRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    ) : (
                        <VTButton
                            type={"icon"}
                            toolTipOptions={{
                                toolTipPlacement: "top-end",
                                toolTipText: "Collapse",
                            }}
                            sx={{
                                position: "absolute",
                                left: currentSidePanel.width,
                                top: "5.5px",
                                width: "30px",
                                height: "30px",
                                borderRadius: "0px 15px 15px 0px",
                                zIndex: 10,
                                backgroundColor: "#444",
                                fontSize: "0.3em",
                                ":hover": {
                                    cursor: "pointer",
                                },
                            }}
                            onClick={() =>
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        width: 0,
                                        option: undefined,
                                    }),
                                )
                            }
                        >
                            <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                        </VTButton>
                    )}

                    <HomeRouteSidePanel />
                </Paper>

                {mediaPage === "ImmersiveImages" && (
                    <Scenario360ImageCardsContainer
                        loading={immersiveImagesLoading}
                        images360={immersiveImageData}
                        leftDrawWidth={currentSidePanel.width}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {mediaPage === "Images" && (
                    <ImagesPageCardContainer
                        loading={imagesLoading}
                        leftDrawWidth={currentSidePanel.width}
                        images={imageData}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {mediaPage === "ImmersiveVideos" && (
                    <ImmersiveVideosRepoContainer
                        loading={immersiveVideosLoading}
                        immersiveVideos={immersiveVideoData}
                        leftDrawWidth={currentSidePanel.width}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {mediaPage === "Videos" && (
                    <VideoCardContainer
                        loading={videosLoading}
                        leftDrawWidth={currentSidePanel.width}
                        videos={videoData}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                    />
                )}

                {mediaPage === "ImmersiveModels" && (
                    <ImmersiveModelsCardContainer
                        leftDrawWidth={currentSidePanel.width}
                        deleteMediaItem={deleteMediaItem}
                        deleteLoading={deleteLoading}
                        loading={modelsLoading}
                        models={modelData}
                    />
                )}

                {mediaPage === "Audio" && (
                    // <Container maxWidth={false}>
                    <AudioCardContainer
                        audio={audioData}
                        leftDrawWidth={currentSidePanel.width}
                        deleteMediaItem={deleteMediaItem}
                        loading={audioLoading}
                        deleteLoading={deleteLoading}
                    />
                    // </Container>
                )}
            </Grid>
        </Grid>
    );
}
