//-----------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";

// Material ui Imports
import { useEffect, useState } from "react";
import { VTCube } from "@virtus-tech-repository/virtus-tech-repository";

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
export interface iSphereProps {
    video: HTMLVideoElement;
    setVideo: (video: HTMLVideoElement) => void;
    videoSrc?: string;
    setVideoCurrentTime?: (currentTime: number) => void;
    loading?: boolean;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// VTSphere for immersive videos
export default function VTVideo({
    video,
    setVideo,
    videoSrc,
    setVideoCurrentTime = () => {},
    loading = false,
}: iSphereProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scene } = useThree();
    useFrame(() => {
        setVideoCurrentTime(video.currentTime);
    });

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE, USE MEMO ##
    const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    // Creates the video
    useEffect(() => {
        if (videoSrc) {
            let vid: HTMLVideoElement = document.createElement("video");
            vid.src = videoSrc;
            vid.crossOrigin = "Anonymous";
            vid.loop = false;
            vid.autoplay = false;
            vid.muted =
                localStorage.getItem("video_muted") && localStorage.getItem("video_muted") === "true" ? true : false;

            // listener to check once loaded
            vid.addEventListener(
                "canplay",
                function () {
                    setVideoLoaded(true);
                    setVideo(vid);
                },
                false,
            );
            setVideo(vid);

            // Cleanup on leave
            return () => {
                vid.pause();
                vid.removeAttribute("src"); // empty source
                vid.load();
            };
        }
    }, [videoSrc]);

    //-------------------------------------------------------------------------------------------------
    // ## Render ##
    // Returns default cube
    if (videoSrc === undefined) {
        return <VTCube />;
    }

    //-------------------------------------------------------------------------------------------------
    // Shows the sphere with the mapped video as a texture
    else if (videoLoaded && !loading) {
        const videTexture = new THREE.VideoTexture(video);

        videTexture.encoding = THREE.sRGBEncoding;

        // scene.background = videTexture;
        return (
            <mesh position={[0, 0, 100]} rotation={[0, Math.PI, 0]}>
                <planeGeometry args={[240, 100, 4, 4]} />
                <meshBasicMaterial>
                    <videoTexture encoding={THREE.sRGBEncoding} attach="map" args={[video!]} />
                </meshBasicMaterial>
            </mesh>
        );
    }

    //-------------------------------------------------------------------------------------------------
    // Shows loading cube
    else {
        return <VTCube loading />;
    }
}
