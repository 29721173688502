//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, MenuItem, Select, Tooltip } from "@mui/material";
import { IHotspot, IUserInterface } from "../../models/hotspot.models";
import { VTButton, VTRichTextEditor, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect, useRef, useState } from "react";
import { AddRounded, DeleteRounded, SaveRounded, CancelRounded } from "@mui/icons-material";
import { IScene } from "../../models/scenario.models";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Params, useParams } from "react-router-dom";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentInterface: IHotspot;
    setCurrentInterface: (currentInteface: IHotspot | undefined) => void;
    handleDeleteHotspotLocation: (hotspotId: string) => void;
    handleUpdateInterface: (hotspotId: string, newInterface: IHotspot) => void;
    scenario: any;
    videoCurrentState: any;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function VTUserInterface({
    currentInterface,
    setCurrentInterface,
    handleDeleteHotspotLocation,
    handleUpdateInterface,
    scenario,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    const { sceneId }: Readonly<Params<string>> = useParams();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [localUserInterface, setLocalUserInterface] = useState<IHotspot>(currentInterface);
    const [userInterfaceIndex, setUserInterfaceIndex] = useState<number>(0);

    const [localUserInterfaceTitle, setLocalUserInterfaceTitle] = useState<string>("");
    const [localUserInterfaceTitleEdit, setLocalUserInterfaceTitleEdit] = useState<boolean>(false);

    const [localSectionTitle, setLocalSectionTitle] = useState<string>("");
    const [localSectionTitleEdit, setLocalSectionTitleEdit] = useState<boolean>(false);

    const [localSectionDescription, setLocalSectionDescription] = useState<string | undefined>(undefined);
    const [localSectionDescriptionEdit, setLocalSectionDescriptionEdit] = useState<boolean>(false);

    const [localSectionFeedback, setLocalSectionFeedback] = useState<string | undefined>(undefined);
    const [localSectionFeedbackEdit, setLocalSectionFeedbackEdit] = useState<boolean>(false);

    const [actionSceneId, setActionSceneId] = useState<string | undefined>("");
    const [actionProceed, setActionProceed] = useState<boolean | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        setLocalSectionTitle(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionTitle!,
        );
        setLocalSectionDescription(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionDescription!,
        );
        setLocalSectionFeedback(
            //@ts-ignore
            localUserInterface.contents.preClickContent.userInterface[userInterfaceIndex]!.sectionFeedback!,
        );

        setActionSceneId(localUserInterface.actions?.movement?.sceneId);
        setActionProceed(localUserInterface.actions?.appFeedback?.proceed);
    }, [userInterfaceIndex]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Add new Section to interface
    function handlesAddSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: [
                        ...localUserInterface.contents.preClickContent.userInterface!,
                        {
                            sectionTitle: `New Section ${localUserInterface.contents.preClickContent.userInterface
                                ?.length!}`,
                        },
                    ],
                },
            },
            actions: {
                ...localUserInterface.actions,
                movement: { ...localUserInterface.actions?.movement, sceneId: actionSceneId },
            },
        });
    }

    // Delete Section from interface
    function handlesDeleteSectionToLocalInterface() {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.filter(
                        (section: IUserInterface, index: number) => index !== userInterfaceIndex,
                    ),
                },
            },
        });
    }

    // Update interfaces overall title
    function handleUpdateInterfaceTitle(newTitle: string) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    title: newTitle,
                },
            },
            actions: { movement: { sceneId: actionSceneId }, appFeedback: { proceed: actionProceed } },
        });
    }

    // Update the current section of the interface
    function handleUpdateSection(value: string, newContent: string | undefined) {
        setLocalUserInterface({
            ...localUserInterface,
            contents: {
                ...localUserInterface.contents,
                preClickContent: {
                    ...localUserInterface.contents.preClickContent,
                    userInterface: localUserInterface.contents.preClickContent.userInterface!.map(
                        (userSection: IUserInterface, index: number) =>
                            index === userInterfaceIndex
                                ? {
                                      ...userSection,
                                      [value]: newContent,
                                  }
                                : userSection,
                    ),
                },
            },
        });
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid
            container
            sx={{
                position: "absolute",
                right: 0,
                width: "400px",
                height: "100%",
                backgroundColor: "#3a3b3d",
                zIndex: 10,
                padding: "15px",
            }}
            justifyContent={"center"}
            alignContent={"space-between"}
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title Section */}
            {/*------------------------------------------------------------------------------------*/}
            {/* Interface Title */}
            <Grid item container justifyContent={"center"} alignContent={"center"}>
                {/*--------------------------------------------------------------------------------*/}
                {/* If editing interface title show text field and data management options */}
                {localUserInterfaceTitleEdit ? (
                    <form
                        onSubmit={() => {
                            setLocalUserInterfaceTitleEdit(false);
                            handleUpdateInterfaceTitle(localUserInterfaceTitle);
                        }}
                    >
                        <VTTextField
                            value={localUserInterfaceTitle}
                            onChange={(e) => setLocalUserInterfaceTitle(e)}
                            sx={{ marginTop: "-8px" }}
                            autoFocus
                            endIcon={
                                <>
                                    <CancelRounded
                                        sx={{
                                            marginRight: "10px",
                                            ":hover": { cursor: "pointer" },
                                        }}
                                        color={"error"}
                                        onClick={() => {
                                            setLocalUserInterfaceTitleEdit(false);
                                            setLocalUserInterfaceTitle(
                                                localUserInterface.contents.preClickContent.title!,
                                            );
                                        }}
                                    />
                                    <SaveRounded
                                        sx={{
                                            ":hover": { cursor: "pointer" },
                                        }}
                                        color={"success"}
                                        type={"submit"}
                                        onClick={() => {
                                            setLocalUserInterfaceTitleEdit(false);
                                            handleUpdateInterfaceTitle(localUserInterfaceTitle);
                                        }}
                                    />
                                </>
                            }
                        />
                    </form>
                ) : (
                    <>
                        {/*----------------------------------------------------------------------------*/}
                        {/* If not currently editing show normal title */}
                        <Grid
                            item
                            sx={{ marginTop: "8px", cursor: "pointer" }}
                            onClick={() => setLocalUserInterfaceTitleEdit(true)}
                        >
                            {localUserInterface.contents.preClickContent.title}
                        </Grid>
                    </>
                )}
                {/*------------------------------------------------------------------------------------*/}
                {/* Interface Delete */}
                <Tooltip title={"Delete Interface"}>
                    <Grid item>
                        <VTButton
                            onClick={() => {
                                handleDeleteHotspotLocation(currentInterface.id);
                                setCurrentInterface(undefined);
                            }}
                            type={"icon"}
                            sx={{
                                backgroundColor: "#ff0000",
                                marginLeft: "15px",
                                ":hover": { backgroundColor: "#990000" },
                            }}
                        >
                            <DeleteRounded />
                        </VTButton>
                    </Grid>
                </Tooltip>
                <Grid xs={12} container justifyContent={"center"}>
                    <Tooltip title={"Change scene on click"} placement="top">
                        <Grid item>
                            <Select
                                value={actionSceneId}
                                onChange={(e) => {
                                    setActionSceneId(e.target.value);
                                    setLocalUserInterface({
                                        ...localUserInterface,
                                        actions: {
                                            ...localUserInterface.actions,
                                            movement: { sceneId: e.target.value },
                                        },
                                    });
                                }}
                                sx={{
                                    width: "200px",
                                    marginLeft: "10px",
                                    marginTop: "3px",
                                }}
                            >
                                {scenario &&
                                    scenario.scenes
                                        .filter((sceneElement: IScene) => sceneElement.id !== sceneId)
                                        .map((scene: IScene) => <MenuItem value={scene.id}>{scene.name}</MenuItem>)}
                                <MenuItem value={undefined}>{"No Scene"}</MenuItem>
                            </Select>
                        </Grid>
                    </Tooltip>

                    {videoCurrentState && (
                        <Tooltip title={"Proceed video on click"} placement="top">
                            <Grid item>
                                <VTButton
                                    type={"icon"}
                                    onClick={() => {
                                        setLocalUserInterface({
                                            ...localUserInterface,
                                            actions: {
                                                ...localUserInterface.actions,
                                                appFeedback: { proceed: !actionProceed },
                                            },
                                        });
                                        setActionProceed(!actionProceed);
                                    }}
                                    sx={
                                        actionProceed
                                            ? {
                                                  marginTop: "10px",
                                                  marginLeft: "10px",
                                                  backgroundColor: "green",
                                                  ":hover": { backgroundColor: "green" },
                                              }
                                            : {
                                                  marginTop: "10px",
                                                  marginLeft: "10px",
                                              }
                                    }
                                >
                                    {actionProceed ? <DoneRoundedIcon /> : <CloseRoundedIcon />}
                                </VTButton>
                            </Grid>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline section */}
            {/*----------------------------------------------------------------------------------------*/}
            {/* Interface Timeline */}
            <Grid item xs={12} container justifyContent={"center"}>
                <Grid
                    item
                    container
                    justifyContent={"space-between"}
                    sx={{
                        width: `${localUserInterface.contents.preClickContent.userInterface?.length! * 60 + 60}px `,
                        backgroundColor: "#fff",
                        marginLeft: "17px",
                        flexWrap: "wrap",
                        height: "2px",
                    }}
                >
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Map interface sections to timeline for navigation */}
                    {localUserInterface.contents.preClickContent.userInterface?.map(
                        (section: IUserInterface, index: number) => (
                            <Tooltip title={section.sectionTitle}>
                                <Grid item>
                                    <VTButton
                                        type={"icon"}
                                        sx={{
                                            padding: 0,
                                            marginTop: userInterfaceIndex === index ? "-21px" : "-19px",
                                            marginLeft: userInterfaceIndex === index ? "-21px" : "-19px",
                                            width: userInterfaceIndex === index ? "45px" : "40px",
                                            height: userInterfaceIndex === index ? "45px" : "40px",
                                            background:
                                                userInterfaceIndex === index
                                                    ? "linear-gradient(to bottom right, #0ab5a7,#8e25fa)"
                                                    : "#7315ce",
                                            transition: ".5s",
                                            ":hover": {
                                                boxShadow: "0 0 25px #2ad5c7",
                                            },
                                        }}
                                        onClick={() => {
                                            setLocalSectionTitleEdit(false);
                                            setLocalSectionDescriptionEdit(false);
                                            setLocalSectionFeedbackEdit(false);
                                            setUserInterfaceIndex(index);
                                        }}
                                    >
                                        {index}
                                    </VTButton>
                                </Grid>
                            </Tooltip>
                        ),
                    )}

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Add section button on the end of the timeline */}
                    <Tooltip title={"Add Section"}>
                        <Grid item>
                            <VTButton
                                type={"icon"}
                                sx={{
                                    padding: 0,
                                    marginTop: "-19px",
                                    width: "40px",
                                    height: "40px",
                                    background: "#7315ce",
                                    transition: ".5s",
                                    ":hover": {
                                        boxShadow: "0 0 25px #2ad5c7",
                                    },
                                    marginLeft: "-19px",
                                    marginRight: "-19px",
                                }}
                                onClick={() => handlesAddSectionToLocalInterface()}
                            >
                                <AddRounded />
                            </VTButton>
                        </Grid>
                    </Tooltip>
                </Grid>
            </Grid>

            {/*----------------------------------------------------------------------------------------*/}
            {/* Section Title area */}
            {/*----------------------------------------------------------------------------------------*/}
            <Grid container item xs={12} sx={{ height: "calc(100% - 200px)", overflowY: "auto" }}>
                <Grid item container xs={12}>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* If editing section title */}
                    {localSectionTitleEdit ? (
                        <form
                            onSubmit={() => {
                                if (localSectionTitle !== "") {
                                    setLocalSectionTitleEdit(false);
                                    handleUpdateSection("sectionTitle", localSectionTitle);
                                }
                            }}
                        >
                            <VTTextField
                                value={localSectionTitle}
                                onChange={(e) => setLocalSectionTitle(e)}
                                autoFocus
                                endIcon={
                                    <>
                                        <CancelRounded
                                            sx={{
                                                marginRight: "10px",
                                                ":hover": { cursor: "pointer" },
                                            }}
                                            color={"error"}
                                            onClick={() => {
                                                setLocalSectionTitleEdit(false);
                                                setLocalSectionTitle(
                                                    localUserInterface.contents.preClickContent.userInterface![
                                                        userInterfaceIndex
                                                    ].sectionTitle!,
                                                );
                                            }}
                                        />
                                        <SaveRounded
                                            sx={{
                                                ":hover": { cursor: "pointer" },
                                            }}
                                            color={"success"}
                                            type={"submit"}
                                            onClick={() => {
                                                if (localSectionTitle !== "") {
                                                    setLocalSectionTitleEdit(false);
                                                    handleUpdateSection("sectionTitle", localSectionTitle);
                                                }
                                            }}
                                        />
                                    </>
                                }
                            />
                        </form>
                    ) : (
                        <Grid
                            item
                            sx={{ marginTop: "8px", cursor: "pointer" }}
                            onClick={() => setLocalSectionTitleEdit(true)}
                        >
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Display section title */}
                            {
                                localUserInterface.contents.preClickContent.userInterface![userInterfaceIndex]
                                    .sectionTitle
                            }
                        </Grid>
                    )}
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Delete section */}
                    <Tooltip title={"Delete Section"}>
                        <Grid
                            item
                            sx={{
                                height: "35px",
                                marginTop: localSectionTitleEdit ? "10px" : 0,
                                width: "70px",
                            }}
                        >
                            <VTButton
                                onClick={() => {
                                    setLocalSectionDescription(undefined);
                                    setLocalSectionFeedback(undefined);
                                    if (userInterfaceIndex !== 0) {
                                        setUserInterfaceIndex(userInterfaceIndex - 1);
                                    }
                                    handlesDeleteSectionToLocalInterface();
                                }}
                                type={"icon"}
                                sx={{
                                    backgroundColor: "#ff0000",
                                    marginLeft: "15px",
                                    ":hover": { backgroundColor: "#990000" },
                                }}
                                disabled={localUserInterface.contents.preClickContent.userInterface?.length! === 1}
                            >
                                <DeleteRounded />
                            </VTButton>
                        </Grid>
                    </Tooltip>
                </Grid>

                {/*----------------------------------------------------------------------------------------*/}
                {/* Section Question/description area */}
                {/*----------------------------------------------------------------------------------------*/}
                <Grid item xs={12}>
                    {/* Allow user to set as undefined and to force re-render to update rich text editor */}
                    {localSectionDescription !== undefined ? (
                        <Grid item container sx={{ height: "100%" }}>
                            <VTTypography type="Heading4">Section Question</VTTypography>

                            {/*----------------------------------------------------------------------------*/}
                            {/* If editing the desc show data management controls, else allow desc remove */}
                            {localSectionDescriptionEdit ? (
                                <>
                                    <CancelRounded
                                        sx={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            ":hover": { cursor: "pointer" },
                                        }}
                                        color={"error"}
                                        onClick={() => {
                                            setLocalSectionDescriptionEdit(false);
                                            setLocalSectionDescription(undefined);
                                            const timer = setTimeout(() => {
                                                setLocalSectionDescription(
                                                    localUserInterface.contents.preClickContent.userInterface![
                                                        userInterfaceIndex
                                                    ].sectionDescription,
                                                );
                                            }, 100);
                                            return () => clearTimeout(timer);
                                        }}
                                    />
                                    <SaveRounded
                                        sx={{
                                            ":hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        color={"success"}
                                        type={"submit"}
                                        onClick={() => {
                                            handleUpdateSection("sectionDescription", localSectionDescription);
                                            setLocalSectionDescriptionEdit(false);
                                        }}
                                    />

                                    <VTRichTextEditor
                                        sx={{}}
                                        startingHtmlContent={
                                            localUserInterface.contents.preClickContent.userInterface![
                                                userInterfaceIndex
                                            ].sectionDescription
                                        }
                                        onChange={(e) => {
                                            setLocalSectionDescription(e);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Tooltip title={"Remove Section Question"}>
                                        {/*------------------------------------------------------------------------*/}
                                        {/* Set desc to be undefined */}
                                        <Grid item>
                                            <CancelRounded
                                                onClick={() => {
                                                    setLocalSectionDescription(undefined);
                                                    handleUpdateSection("sectionDescription", undefined);
                                                }}
                                                sx={{ marginLeft: "10px", marginTop: "4px", cursor: "pointer" }}
                                            />
                                        </Grid>
                                    </Tooltip>

                                    <div
                                        style={{ width: "100%", cursor: "pointer" }}
                                        onClick={() => {
                                            if (localSectionFeedbackEdit) {
                                                handleUpdateSection("sectionFeedback", localSectionFeedback);
                                                setLocalSectionFeedbackEdit(false);
                                            }
                                            setLocalSectionDescriptionEdit(true);
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: localSectionDescription,
                                        }}
                                    />
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Tooltip title={"Add User Question"}>
                            {/*----------------------------------------------------------------------------*/}
                            {/* Add desc to section */}
                            <Grid item sx={{ width: "40px" }}>
                                <VTButton
                                    type={"icon"}
                                    onClick={() => setLocalSectionDescription("New Section question")}
                                >
                                    <AddRounded />
                                </VTButton>
                            </Grid>
                        </Tooltip>
                    )}
                </Grid>

                {/*----------------------------------------------------------------------------------------*/}
                {/* Section Question/description area */}
                {/*----------------------------------------------------------------------------------------*/}
                <Grid item xs={12}>
                    {/* Allow user to set as undefined and to force re-render to update rich text editor */}
                    {localSectionFeedback !== undefined ? (
                        <Grid item container sx={{ height: "100%", marginTop: "40px" }}>
                            <VTTypography type={"Heading4"}>Section Feedback</VTTypography>

                            {/*----------------------------------------------------------------------------*/}
                            {/* If editing the feedback show data management controls, else allow desc remove */}
                            {localSectionFeedbackEdit ? (
                                <>
                                    <CancelRounded
                                        sx={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                            ":hover": { cursor: "pointer" },
                                        }}
                                        color={"error"}
                                        onClick={() => {
                                            setLocalSectionFeedbackEdit(false);
                                            setLocalSectionFeedback(undefined);
                                            const timer = setTimeout(() => {
                                                setLocalSectionFeedback(
                                                    localUserInterface.contents.preClickContent.userInterface![
                                                        userInterfaceIndex
                                                    ].sectionFeedback,
                                                );
                                            }, 100);
                                            return () => clearTimeout(timer);
                                        }}
                                    />
                                    <SaveRounded
                                        sx={{
                                            ":hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        color={"success"}
                                        type={"submit"}
                                        onClick={() => {
                                            handleUpdateSection("sectionFeedback", localSectionFeedback);
                                            setLocalSectionFeedbackEdit(false);
                                        }}
                                    />
                                    {/*----------------------------------------------------------------------------*/}
                                    {/* Feedback text editor */}
                                    <VTRichTextEditor
                                        sx={{}}
                                        startingHtmlContent={
                                            localUserInterface.contents.preClickContent.userInterface![
                                                userInterfaceIndex
                                            ].sectionFeedback
                                        }
                                        onChange={(e) => {
                                            setLocalSectionFeedback(e);
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <Tooltip title={"Remove Section Feedback"}>
                                        {/*------------------------------------------------------------------------*/}
                                        {/* Set feedback to be undefined */}
                                        <Grid item>
                                            <CancelRounded
                                                onClick={() => {
                                                    setLocalSectionFeedback(undefined);
                                                    handleUpdateSection("sectionFeedback", undefined);
                                                }}
                                                sx={{ marginLeft: "10px", marginTop: "4px", cursor: "pointer" }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                    <div
                                        style={{ width: "100%", cursor: "pointer" }}
                                        onClick={() => {
                                            if (localSectionDescriptionEdit) {
                                                handleUpdateSection("sectionDescription", localSectionDescription);
                                                setLocalSectionDescriptionEdit(false);
                                            }
                                            setLocalSectionFeedbackEdit(true);
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: localSectionFeedback,
                                        }}
                                    />
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Tooltip title={"Add User Feedback"}>
                            {/*----------------------------------------------------------------------------*/}
                            {/* Add feedback to section */}
                            <Grid item sx={{ width: "40px", marginTop: "40px" }}>
                                <VTButton type={"icon"} onClick={() => setLocalSectionFeedback("New Section feedback")}>
                                    <AddRounded />
                                </VTButton>
                            </Grid>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>

            {/*------------------------------------------------------------------------------------------*/}
            {/* Closes the interface panel */}
            <VTButton
                sx={{
                    width: "10px",
                    height: "40px",
                    borderRadius: "15px 0px 0px 15px",
                    backgroundColor: "#a9a9a9",
                    ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#888888",
                        boxShadow: "0 0 25px #2ad5c7",
                    },
                    marginBlock: "15px",
                    position: "absolute",
                    left: "-64px",
                    top: "42%",
                }}
                onClick={() => setCurrentInterface(undefined)}
            >
                <LogoutIcon />
            </VTButton>

            <Grid item container xs={12} justifyContent={"space-around"} sx={{ marginTop: "-35px" }}>
                {/*------------------------------------------------------------------------------------------*/}
                {/* Resets the data with the sever */}
                <Grid item>
                    <VTButton
                        sx={{ width: "150px" }}
                        disabled={localUserInterface === currentInterface}
                        onClick={() => {
                            setLocalSectionDescription(
                                currentInterface.contents.preClickContent.userInterface![userInterfaceIndex]
                                    .sectionDescription,
                            );
                            setLocalSectionFeedback(
                                currentInterface.contents.preClickContent.userInterface![userInterfaceIndex]
                                    .sectionFeedback,
                            );
                            setUserInterfaceIndex(0);
                            setLocalUserInterface(currentInterface);
                        }}
                        color={"secondary"}
                    >
                        Cancel
                    </VTButton>
                </Grid>
                {/*------------------------------------------------------------------------------------------*/}
                {/* sets data on the server */}
                <Grid item>
                    <VTButton
                        sx={{ width: "150px" }}
                        disabled={localUserInterface === currentInterface}
                        onClick={() => {
                            handleUpdateInterface(currentInterface.id, localUserInterface);
                            setCurrentInterface(localUserInterface);
                        }}
                    >
                        Save
                    </VTButton>
                </Grid>
            </Grid>
        </Grid>
    );
}
