//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports
import { Grid, Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

// Custom components imports (and hooks and helpers)
import { SerializedError, current } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { VTButton, VTCard, VTTypography, useGetMuiBreakPointLabel } from "@virtus-tech-repository/virtus-tech-repository";

import VTSkeletonCard from "../components/VTSkeletonCard";
import { IScenarioOverview } from "../../models/scenario.models";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import NoScenarios from "../../assets/images/NoScenarios.svg";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { ContentCopyRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import { MouseEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { useUpdateScenarioOverviewMutation } from "../../services/scenario.service";

import { ExposedVTCardRefMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCard";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useGetAllGroupsQuery } from "../../services/users.service";
import { v4 as uuid } from "uuid";
import { ERole, IGroup } from "../../models/user.model";
// import { useGetBreakPointLabel } from "../../hooks/useGetBreakpointLabel";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        // border: `1px solid ${theme.palette.divider}`,
        borderRadius: "10px",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
    }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "#222326",
    height: "40vh",
    overflowY: "auto",
}));

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    data: IScenarioOverview[] | undefined;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
    leftDrawWidth: number;
    searchValue: string;
    setScenarioDialog: (scenarioDialog: IScenarioOverview | undefined) => void;
    // scenarioDialog: IScenarioOverview | undefined;
    handleDeleteScenario: (id: string, accessId: string[]) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeCardGridContainer({
    data,
    isLoading,
    error,
    leftDrawWidth,
    searchValue,
    setScenarioDialog,
    handleDeleteScenario,
}: IProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const { data: groups, isLoading: groupsLoading } = useGetAllGroupsQuery(userId);
    const isAboveMdScreenWidth = useMediaQuery(theme.breakpoints.up("md"));

    const breakPointLabel = useGetMuiBreakPointLabel();

    function shouldNotAddPaddingRightToGridItem(gridItemNumber: number) {
        if (gridItemNumber % 4 === 0 && breakPointLabel === "lg") {
            return true;
        } else if (gridItemNumber % 3 === 0 && breakPointLabel === "md") {
            return true;
        } else if (gridItemNumber % 2 === 0 && breakPointLabel === "sm") {
            return true;
        } else if (gridItemNumber % 1 === 0 && breakPointLabel === "xs") {
            return true;
        } else {
            return false;
        }
    }

    const [updateScenarioOverview] = useUpdateScenarioOverviewMutation();

    const { id } = useAppSelector((state) => state.userReducer);

    const vtCardRefs = useRef<Map<string, ExposedVTCardRefMethodsObject | null>>(new Map());

    const [expanded, setExpanded] = useState<string | false>("panel1");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Grid container xs={12} sx={{}}>
            <Grid
                // item
                container
                // xs={12}
                spacing={3}
                rowSpacing={1}
                justifyContent={"space-evenly"}
                sx={{
                    // background: 'red',
                    marginTop: 3,
                    minHeight: 400,
                    marginLeft: leftDrawWidth / 8 + (!isLoading ? 2 : 5),
                    marginRight: 5,
                    // display: "flex",
                    transition: "0.5s",
                    // gap: "10px"
                }}
                // justifyContent={"space-evenly"}
            >
                {!isLoading ? (
                    <>
                        {data && data.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"}>
                                <img src={NoScenarios} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    {" "}
                                    To get started, click add button below
                                </VTTypography>
                                <VTButton
                                    sx={{ padding: "10px", marginTop: "10px" }}
                                    onClick={() => {
                                        setScenarioDialog(blankScenarioOverview(id));
                                        dispatch(
                                            setCurrentSidePanel({
                                                currentTab: undefined,
                                                width: 0,
                                                option: undefined,
                                            }),
                                        );
                                    }}
                                >
                                    <AddRoundedIcon sx={{ marginRight: "12px" }} />{" "}
                                    <VTTypography> New Scenario</VTTypography>{" "}
                                </VTButton>
                            </Grid>
                        ) : (
                            <>
                                {groupsLoading ? (
                                    <VTSkeletonCard />
                                ) : (
                                    groups
                                        ?.filter((element: IGroup) => element.role === ERole.ADMIN)
                                        .map((group: any) => (
                                            <Grid item xs={12}>
                                                <Accordion
                                                    key={group.id}
                                                    defaultExpanded={true}
                                                    // expanded={expanded === group.id}
                                                    // onChange={handleChange(group.id)}
                                                >
                                                    <AccordionSummary
                                                        aria-controls="panel1d-content"
                                                        id="panel1d-header"
                                                    >
                                                        <Typography sx={{ textTransform: "capitalize" }}>
                                                            {group.title}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Grid container spacing={2}>
                                                            {data
                                                                ?.filter(
                                                                    (element: any) =>
                                                                        (element.admins.includes(group.id) &&
                                                                            element.title
                                                                                .toLowerCase()
                                                                                .includes(searchValue.toLowerCase())) ||
                                                                        (element.admins.includes(group.id) &&
                                                                            element.description
                                                                                .toLowerCase()
                                                                                .includes(searchValue.toLowerCase())),
                                                                )
                                                                .sort((a, b) => {
                                                                    // TODO: this can be removed once all scenario have a date
                                                                    const dateA = a.date ? a.date : 0;
                                                                    const dateB = b.date ? b.date : 0;

                                                                    return (
                                                                        new Date(dateB).valueOf() -
                                                                        new Date(dateA).valueOf()
                                                                    );
                                                                })
                                                                .map(
                                                                    (
                                                                        scenarioOverview: IScenarioOverview,
                                                                        i: number,
                                                                    ) => {
                                                                        let menuOptions = [
                                                                            {
                                                                                component: (
                                                                                    <VTButton
                                                                                        type={"list"}
                                                                                        startIcon={<EditRounded />}
                                                                                        onClick={() => {
                                                                                            vtCardRefs.current
                                                                                                .get(
                                                                                                    scenarioOverview.id,
                                                                                                )
                                                                                                ?.closePopUpOptionsMenu();
                                                                                            setScenarioDialog(
                                                                                                scenarioOverview,
                                                                                            );
                                                                                            dispatch(
                                                                                                setCurrentSidePanel({
                                                                                                    currentTab:
                                                                                                        undefined,
                                                                                                    width: 0,
                                                                                                    option: undefined,
                                                                                                }),
                                                                                            );
                                                                                            // setMenuOpen(false);
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </VTButton>
                                                                                ),
                                                                            },
                                                                            // {
                                                                            //     component: (
                                                                            //         <VTButton
                                                                            //             type={"list"}
                                                                            //             startIcon={<ContentCopyRounded />}
                                                                            //             onClick={() => {
                                                                            //                 setScenarioDialog({
                                                                            //                     ...data?.find(
                                                                            //                         (
                                                                            //                             element: IScenarioOverview,
                                                                            //                         ) =>
                                                                            //                             element.id ===
                                                                            //                             scenarioOverview.id,
                                                                            //                     ),
                                                                            //                     id: uuid(),
                                                                            //                     owner: userId,
                                                                            //                 } as IScenarioOverview);
                                                                            //                 dispatch(
                                                                            //                     setCurrentSidePanel({
                                                                            //                         currentTab: undefined,
                                                                            //                         width: 0,
                                                                            //                         option: undefined,
                                                                            //                     }),
                                                                            //                 );
                                                                            //                 // setMenuOpen(false);
                                                                            //             }}
                                                                            //         >
                                                                            //             Duplicate
                                                                            //         </VTButton>
                                                                            //     ),
                                                                            // },
                                                                        ];

                                                                        if (scenarioOverview.owner === id) {
                                                                            menuOptions.push({
                                                                                component: (
                                                                                    <VTButton
                                                                                        type={"list"}
                                                                                        startIcon={<DeleteRounded />}
                                                                                        onClick={() => {
                                                                                            if (
                                                                                                window.confirm(
                                                                                                    "Are you sure you want to delete this item?",
                                                                                                )
                                                                                            ) {
                                                                                                handleDeleteScenario(
                                                                                                    scenarioOverview.id,
                                                                                                    scenarioOverview.admins,
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        Delete
                                                                                    </VTButton>
                                                                                ),
                                                                            });
                                                                        }

                                                                        return (
                                                                            <Grid
                                                                                lg={3}
                                                                                md={4}
                                                                                sm={6}
                                                                                xs={12}
                                                                                key={scenarioOverview.id}
                                                                                item
                                                                                sx={{
                                                                                    marginBottom: "20px",
                                                                                    // paddingRight: shouldNotAddPaddingRightToGridItem(i + 1) ? "none" : "20px",
                                                                                }}
                                                                            >
                                                                                <VTCard
                                                                                    ref={(ref) => {
                                                                                        vtCardRefs.current.set(
                                                                                            scenarioOverview.id,
                                                                                            ref,
                                                                                        );
                                                                                    }}
                                                                                    sx={{
                                                                                        width: "100%",
                                                                                        maxWidth: "none",
                                                                                        height: "360px",
                                                                                    }}
                                                                                    title={scenarioOverview.title}
                                                                                    titleCutOffPoint={
                                                                                        isAboveMdScreenWidth ? 17 : 13
                                                                                    }
                                                                                    textCutOffPoint={25}
                                                                                    text={scenarioOverview.description}
                                                                                    onWholeCardClicked={() => {
                                                                                        updateScenarioOverview({
                                                                                            ...scenarioOverview,
                                                                                            date: new Date(),
                                                                                        });

                                                                                        navigate(
                                                                                            `/Creator/${scenarioOverview.id}`,
                                                                                        );
                                                                                    }}
                                                                                    mediaType={{
                                                                                        imageUrl:
                                                                                            scenarioOverview.previewImage
                                                                                                ? scenarioOverview.previewImage
                                                                                                : ``,
                                                                                    }}
                                                                                    menuOptions={{
                                                                                        menuOptionsList: menuOptions,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    },
                                                                )}
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <VTSkeletonCard />
                )}
            </Grid>
        </Grid>
    );
}
