//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useRef, useState } from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { TextureLoader, Vector3 } from "three";
// Material ui Imports
// Custom components imports (and hooks and helpers)
import {
    VTBlurSphere,
    VTButton,
    VTCanvas,
    VTCreatorTimeline,
    VTCube,
    VTRichTextEditor,
    VTDialog,
    VTSidePanel,
    VTTextField,
    VTTypography,
    VTRichTextEditorContentPreview,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppSelector } from "../../store/hooks";
import { IDragOptions } from "../../models/current.models";
import VTCreatorDropContainer, { DropPoint } from "../components/VTCreatorDropContainer";
import handleRenderHotspots from "../../helpers/canvasHotspotRenderer";
import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Slider,
    TextField,
    Tooltip,
} from "@mui/material";
import { iImageProps } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCube";
import { element } from "prop-types";
import {
    AddRounded,
    CancelRounded,
    DeleteRounded,
    EditRounded,
    SaveRounded,
    ArrowForwardRounded,
    Volcano,
    VolumeUpRounded,
    CloseRounded,
    VolumeOffRounded,
} from "@mui/icons-material";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import VTCreatorVideoControls from "../components/VTCreatorVideoControls";
import VTCreatorVideoTimeline from "../components/VTCreatorVideoTimeline";
import { IScenario, IScene } from "../../models/scenario.models";
import { EHotspotType, IHotspot, IUserInterface } from "../../models/hotspot.models";
import VTHotpsot from "../components/VTHotspot";
import { useDispatch } from "react-redux";
import { elementRoles } from "aria-query";
import {
    setCurrentAudio,
    setCurrentFeedbackImage,
    setCurrentFeedbackVideo,
    setCurrentHotspot,
    setCurrentQuestion,
    setCurrentSidePanel,
    setCurrentStartingLocation,
    setEditHotspotDialogTabCurrentlyOpen,
} from "../../store/slices/current.slice";
import VTHotspotQuickEdit from "../components/VTHotspotQuickEdit";
import ReactAudioPlayer from "react-audio-player";
import VTCreatorQuestion from "../components/VTCreatorQuestion";
import VTHotspotEditDialog from "../components/VTHotspotEditDialog";
import { Html } from "@react-three/drei";
import VTVideo from "../components/VTVideo";
import VTCreatorAudioControls from "../components/VTCreatorAudioControls";
import VTCreatorTimelineContainer from "./CreatorTimelineContainer";
import VTSphere from "../components/VTSphere";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import VTUserInterface from "../components/VTUserInterface";
import { VTHotspotHTMLWrapper } from "../components/VTHotspotHTMLWrapper";
import * as THREE from "three";
import ImmersiveModel from "../components/threeDModels.tsx/ImmersiveModel";
import VTViewerUserInterface from "../components/VTViewerUserInterface";
import ThreeDViewer from "../components/threeD/ThreeDViewer";
import Passthrough from "../components/threeD/Passthrough";

// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    scenarioLoading: boolean;
    scene: IScene;
    handleMediaCanvasDropped: (timelineMedia: IDragOptions) => void;
    handleMediaQuestionCanvasDropped: (timelineMedia: IDragOptions, hotspotId?: string) => void;
    handleAddStopPoint: (sceneId: string, time: number, setVideoNextQuestion: any) => void;
    handleDeleteStopPoint: (sceneId: string, hotspotId: string) => void;
    isLoading: boolean;
    handleSaveHotspotLocation: (hotspotId: string, newPosition: Vector3, videoCurrentState: string | undefined) => void;
    handleDeleteHotspotLocation: (hotspotId: string, questionId?: string) => void;
    handleAudioDelete: (sceneId: string, questionId?: string) => void;
    handleEditQuestionTitle: (questionId: string, newTitle: string) => void;
    handleEditQuestionTimer: (questionId: string, newTimer: number) => void;
    scenario: IScenario;
    updateScenario: any;
    currentAttachedObject: any;
    setCurrentAttachedObject: any;
    handleMediaTimelineDropped: (timelineMedia: IDragOptions) => void;
    setSceneName: (sceneId: string, sceneName: string) => void;
    setSceneDelete: (sceneId: string) => void;
    setSceneDuplicate: (sceneId: string) => void;
    setSceneInitialView: (sceneId: string, newPosition: any) => void;
    setSceneStepper: (sceneId: string, value: boolean) => void;
    setSceneReorder: (index: number) => void;
    handleUpdateInterface: (hotspotId: string, newInterface: IHotspot) => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
    setVideoCurrentState: (videoCurrentState: "Paused" | "Playing" | "Question" | undefined) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function CreatorCanvasContainer({
    scenarioLoading,
    scene,
    handleMediaCanvasDropped,
    handleMediaQuestionCanvasDropped,
    handleAddStopPoint,
    handleDeleteStopPoint,
    handleSaveHotspotLocation,
    handleDeleteHotspotLocation,
    handleAudioDelete,
    handleEditQuestionTitle,
    handleEditQuestionTimer,
    isLoading,
    scenario,
    updateScenario,
    currentAttachedObject,
    setCurrentAttachedObject,
    handleMediaTimelineDropped,
    setSceneName,
    setSceneDelete,
    setSceneDuplicate,
    setSceneInitialView,
    setSceneStepper,
    setSceneReorder,
    handleUpdateInterface,
    videoCurrentState,
    setVideoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();
    const canvasControlsRef = useRef<any>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const { src, hotspots, mediaType, id, startLocation } = scene;
    const [canvasHovered, setCanvasHovered] = useState<boolean>(false);
    const [richTextEditorContent, setRichTextEditorContent] = useState<string>("");
    const [richTextEditorChanged, setRichTextEditorChanged] = useState<boolean>(false);

    // Image states
    const [currentImage, setCurrentImage] = useState<iImageProps | undefined>(undefined);

    // Video states

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoLoadingState, setVideoLoadingState] = useState<boolean>(false);

    const [videoNextQuestion, setVideoNextQuestion] = useState<IHotspot | undefined>(undefined);
    const [currentQuestions, setCurrentQuestions] = useState<IHotspot[]>([]);

    const [currentQuestionsTimes, setCurrentQuestionsTimes] = useState<number[]>([]);
    const [proceedVideoOnComplete, setProceedVideoOnComplete] = useState<boolean>(false);
    const [changeSceneOnComplete, setChangeSceneOnComplete] = useState<string | undefined>(undefined);

    const {
        currentlyDragging,
        currentHotspot,
        currentSidePanel,
        currentAudio,
        currentFeedbackImage,
        currentFeedbackVideo,
        currentFeedbackInput,
        currentSettingStartLocation,
        currentlyPreview,
    } = useAppSelector((state) => state.currentReducer);

    const [bottomDrawWidth, setBottomDrawWidth] = useState<number>(250);
    const [timelineMode, setTimelineMode] = useState<"Scenes" | "Timeline" | "Stepper">("Scenes");

    const [currentInterface, setCurrentInterface] = useState<IHotspot | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    document.addEventListener("visibilitychange", function () {
        if (document.hidden && (mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360)) {
            if (!video.paused) {
                setVideoCurrentState("Paused");
                video.pause();
            }
        }
    });

    useEffect(() => {
        if (timelineMode === "Timeline") {
            setTimelineMode("Scenes");
        }
        if (mediaType === EMedia.VIDEO || mediaType === EMedia.VIDEO_360) {
            setCanvasHovered(false);
            const timer = setTimeout(() => {
                setCanvasHovered(true);
            }, 100);
            return () => clearTimeout(timer);
        }

        if (startLocation) {
            handleLookAt(
                parseFloat(startLocation[0]),
                parseFloat(startLocation[1]),
                parseFloat(startLocation[2]),
                false,
            );
        }
    }, [sceneId]);

    //-------------------------------------------------------------------------------------------------
    // Test data image loading and video question loading
    useEffect(() => {
        if (src && (mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO) && hotspots) {
            getNextQuestion(0, hotspots, setVideoNextQuestion);
        }

        if (src && (mediaType === EMedia.IMAGE_360 || mediaType === EMedia.PASSTHROUGH)) {
            const loader = new TextureLoader();
            const backTexture = loader.load(src + "/b.jpg");
            const frontTexture = loader.load(src + "/f.jpg");
            const leftTexture = loader.load(src + "/l.jpg");
            const rightTexture = loader.load(src + "/r.jpg");
            const upTexture = loader.load(src + "/u.jpg");
            const downTexture = loader.load(src + "/d.jpg");

            setCurrentImage({
                left: leftTexture,
                right: rightTexture,
                up: upTexture,
                down: downTexture,
                front: frontTexture,
                back: backTexture,
            });
        }
        setVideoCurrentState(undefined);
        setCurrentInterface(undefined);
    }, [src, scenarioId]);

    useEffect(() => {
        if (window.innerHeight / 4 > 250) {
            setBottomDrawWidth(250);
        } else if (window.innerHeight / 4 < 130) {
            setBottomDrawWidth(130);
        } else {
            setBottomDrawWidth(155);
        }
    }, []);

    useEffect(() => {
        if (startLocation) {
            if (startLocation[0] !== 0 && startLocation[1] !== 0 && startLocation[2] !== 0) {
                handleLookAt(
                    parseFloat(startLocation[0]),
                    parseFloat(startLocation[1]),
                    parseFloat(startLocation[2]),
                    currentSettingStartLocation,
                );
            } else {
                handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
            }
        } else {
            handleLookAt(0.1, 0.1, 10, currentSettingStartLocation);
        }

        dispatch(setCurrentStartingLocation(false));
    }, [startLocation, src]);

    //-------------------------------------------------------------------------------------------------
    // Pause the video on question
    useEffect(() => {
        if (src && (mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO)) {
            if (
                videoNextQuestion?.contents?.preClickContent.question?.time &&
                videoCurrentTime >= videoNextQuestion?.contents?.preClickContent.question?.time
            ) {
                video.pause();
                if (videoNextQuestion.contents && videoNextQuestion.contents.preClickContent.question.audio) {
                    dispatch(setCurrentAudio(videoNextQuestion.contents.preClickContent.question.audio));
                }
                setVideoCurrentState("Question");
                dispatch(setCurrentQuestion(videoNextQuestion));
                dispatch(
                    setCurrentHotspot({
                        currentHotspot: undefined,
                        open: false,
                    }),
                );
            }
        }
    }, [videoCurrentTime]);

    useEffect(() => {
        if (src && (mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO)) {
            getNextQuestion(videoCurrentTime - 0.5, hotspots, setVideoNextQuestion);
        }
    }, [hotspots]);

    useEffect(() => {
        if (
            video &&
            videoCurrentTime &&
            scene &&
            scene.hotspots &&
            setVideoNextQuestion &&
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            proceedVideoOnComplete
        ) {
            getNextQuestion(videoCurrentTime + 0.05, scene.hotspots, setVideoNextQuestion);
            setVideoCurrentState("Playing");
            video.play();
            setProceedVideoOnComplete(false);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, proceedVideoOnComplete]);

    useEffect(() => {
        if (
            !currentAudio &&
            !currentFeedbackImage &&
            !currentFeedbackVideo &&
            !currentFeedbackInput &&
            changeSceneOnComplete !== undefined
        ) {
            setChangeSceneOnComplete(undefined);
            navigate(`/creator/${scenarioId}/${changeSceneOnComplete}`);
        }
    }, [currentAudio, currentFeedbackImage, currentFeedbackVideo, currentFeedbackInput, changeSceneOnComplete]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function getNextQuestion(
        time: number,
        hotspots: IHotspot[],
        setVideoNextQuestion: (nextQuestion: IHotspot | undefined) => void,
    ) {
        let nextQuestion: IHotspot | undefined;
        let localQuestions: IHotspot[] = [];
        let localQuestionsTimes: number[] = [];

        hotspots.forEach((element: IHotspot) => {
            if (element.type === EHotspotType.QUESTION) {
                localQuestions.push(element);
                if (element.contents?.preClickContent.question?.time) {
                    if (element.contents?.preClickContent.question?.time > time) {
                        if (
                            nextQuestion === undefined ||
                            nextQuestion.contents?.preClickContent.question?.time! >
                                element?.contents?.preClickContent.question?.time!
                        ) {
                            nextQuestion = element;
                        }
                    }
                }
            }
        });
        localQuestions.forEach((question: IHotspot) => {
            localQuestionsTimes.push(question.contents.preClickContent.question?.time!);
        });

        setCurrentQuestions(localQuestions);
        setCurrentQuestionsTimes(localQuestionsTimes);
        setVideoNextQuestion(nextQuestion);
    }

    function handleLookAt(x: number, y: number, z: number, transition?: boolean) {
        if (canvasControlsRef.current) {
            canvasControlsRef.current.lookAt(x, y, z, transition);
        }
    }

    function handleSetPosition(
        positionX: number,
        positionY: number,
        positionZ: number,
        lookAtX: number,
        lookAtY: number,
        lookAtZ: number,
        transition?: boolean,
    ) {
        if (canvasControlsRef.current) {
            canvasControlsRef.current.moveTo(positionX, positionY, positionZ, lookAtX, lookAtY, lookAtZ, transition);
        }
    }
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <div
            // ref={creatorCanvasContainerRef}
            id="creator-canvas-container"
            style={{
                backgroundColor: "#a9a9a9",
                height: currentlyPreview
                    ? "calc(100vh - " + 69 + "px)"
                    : "calc(100vh - " + 69 + "px - " + bottomDrawWidth + "px)",
                width: currentlyPreview ? "calc(100vw)" : "calc(100% - " + (currentSidePanel.width + 100) + "px)",
                marginTop: "68px",
                userSelect: "none",
                position: "relative",
                transition: "0.1s",
                overflow: "hidden",
            }}
            onMouseEnter={() => setCanvasHovered(true)}
            onMouseLeave={() => setCanvasHovered(false)}
        >
            {/*##############################################################################################*/}
            {/* Outside the canvas on image Hotspot */}
            {/*##############################################################################################*/}
            {/*---------------------------------------------------------------------------------------*/}
            {/* VT Drop container to accept media from the left side panel */}
            {videoCurrentState !== "Question" && (
                <>
                    {currentlyDragging &&
                        currentlyDragging.mediaType !== EDragContentOptions.SCENE &&
                        scenario.scenes.length > 0 &&
                        !(
                            mediaType === EMedia.DOCUMENT &&
                            (currentlyDragging.mediaType === EDragContentOptions.IMAGE ||
                                currentlyDragging.mediaType === EDragContentOptions.VIDEO ||
                                currentlyDragging.mediaType === EDragContentOptions.AUDIO ||
                                currentlyDragging.mediaType === EDragContentOptions.MOVEMENT ||
                                currentlyDragging.mediaType === EDragContentOptions.INFORMATION ||
                                currentlyDragging.mediaType === EDragContentOptions.ANSWER ||
                                currentlyDragging.mediaType === EDragContentOptions.MOVEMENT_3D ||
                                currentlyDragging.mediaType === EDragContentOptions.INTERFACE ||
                                currentlyDragging.mediaType === EDragContentOptions.COMPLETE ||
                                currentlyDragging.mediaType === EDragContentOptions.PROCEED)
                        ) && (
                            <VTCreatorDropContainer
                                handleMediaDropped={handleMediaCanvasDropped}
                                currentlyDragging={currentlyDragging}
                                location={DropPoint.DROP_CANVAS}
                                bottomDrawWidth={bottomDrawWidth}
                                videoNextQuestionID={videoNextQuestion && videoNextQuestion.id}
                                currentInterface={currentInterface}
                            />
                        )}

                    {scene.audio && (
                        <VTCreatorAudioControls
                            audio={scene.audio}
                            handleAudioDelete={handleAudioDelete}
                            questionId={undefined}
                        />
                    )}

                    {currentInterface ? (
                        <>
                            {currentlyPreview ? (
                                <VTViewerUserInterface
                                    currentInterface={currentInterface}
                                    setCurrentInterface={setCurrentInterface}
                                    handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                    handleUpdateInterface={handleUpdateInterface}
                                    handleProceedVideo={(delayInSeconds?: number) => {
                                        if (currentHotspot.currentHotspot) {
                                            dispatch(
                                                setCurrentHotspot({
                                                    currentHotspot: undefined,
                                                    open: false,
                                                }),
                                            );
                                        }
                                        setTimeout(
                                            () => {
                                                if (
                                                    currentAudio !== undefined ||
                                                    currentFeedbackImage !== undefined ||
                                                    currentFeedbackVideo !== undefined ||
                                                    currentFeedbackInput !== undefined
                                                ) {
                                                    setProceedVideoOnComplete(true);
                                                } else {
                                                    getNextQuestion(
                                                        videoCurrentTime + 0.05,
                                                        scene.hotspots,
                                                        setVideoNextQuestion,
                                                    );
                                                    setVideoCurrentState("Playing");
                                                    video.play();
                                                }
                                            },
                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                        );
                                        // return () => clearTimeout(timer);
                                    }}
                                    handleChangeScene={(sceneId: string, delayInSeconds?: number) => {
                                        setTimeout(
                                            () => {
                                                if (
                                                    currentAudio !== undefined ||
                                                    currentFeedbackImage !== undefined ||
                                                    currentFeedbackVideo !== undefined ||
                                                    currentFeedbackInput !== undefined
                                                ) {
                                                    setChangeSceneOnComplete(sceneId);
                                                } else {
                                                    navigate(`/viewer/${scenarioId}/${sceneId}`);
                                                }
                                            },
                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                        );

                                        // return () => clearTimeout(timer);
                                    }}
                                />
                            ) : (
                                <VTUserInterface
                                    currentInterface={currentInterface}
                                    setCurrentInterface={setCurrentInterface}
                                    handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                    handleUpdateInterface={handleUpdateInterface}
                                    scenario={scenario}
                                    videoCurrentState={videoCurrentState}
                                />
                            )}
                        </>
                    ) : (
                        <Grid
                            sx={{
                                position: "absolute",
                                right: "50px",
                                width: 0,
                                height: "100%",
                            }}
                            container
                            alignContent={"center"}
                        >
                            {hotspots &&
                                hotspots.map(
                                    (hotspot: IHotspot) =>
                                        hotspot.type === EHotspotType.INTERFACE && (
                                            <Tooltip title={hotspot.contents.preClickContent.title}>
                                                <Grid item>
                                                    <VTButton
                                                        sx={{
                                                            zIndex: 10,
                                                            width: "10px",
                                                            height: "40px",
                                                            borderRadius: "15px 0px 0px 15px",
                                                            backgroundColor: "#a9a9a9",
                                                            ":hover": {
                                                                cursor: "pointer",
                                                                backgroundColor: "#888888",
                                                                boxShadow: "0 0 25px #2ad5c7",
                                                            },
                                                            marginBlock: "15px",
                                                        }}
                                                        onClick={() => setCurrentInterface(hotspot)}
                                                    >
                                                        <TextSnippetIcon />
                                                    </VTButton>
                                                </Grid>
                                            </Tooltip>
                                        ),
                                )}
                        </Grid>
                    )}
                </>
            )}

            {/*##############################################################################################*/}
            {/* Outside the canvas on Video  */}
            {/*##############################################################################################*/}
            {/*---------------------------------------------------------------------------------------*/}
            {/* On video add overlay video controls and question test */}
            {(mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO) && (
                <>
                    {/*-----------------------------------------------------------------------------------*/}
                    {/* video controls */}
                    {canvasHovered && (
                        <VTCreatorVideoControls
                            videoCurrentTime={videoCurrentTime}
                            video={video}
                            videoNextQuestion={videoNextQuestion}
                            getNextQuestion={getNextQuestion}
                            setVideoNextQuestion={setVideoNextQuestion}
                            handleAddStopPoint={handleAddStopPoint}
                            scene={scene}
                            setTimelineMode={setTimelineMode}
                            handleDeleteStopPoint={(sceneId: string, hotspotId: string) => {
                                handleDeleteStopPoint(sceneId, hotspotId);
                                if (currentQuestions.length === 1) {
                                    setTimelineMode("Scenes");
                                }
                            }}
                            videoCurrentState={videoCurrentState}
                            setVideoCurrentState={setVideoCurrentState}
                            currentInterfaceTrue={currentInterface !== undefined}
                            currentQuestionsTimes={currentQuestionsTimes}
                            bottomDrawWidth={bottomDrawWidth}
                        />
                    )}

                    {videoLoadingState && (
                        <>
                            <CircularProgress sx={{ zIndex: 1000, position: "absolute", left: "48%", top: "48%" }} />
                        </>
                    )}

                    {/*---------------------------------------------------------------------------------*/}
                    {/* Question hotspot overlay */}
                    {videoNextQuestion && videoCurrentState === "Question" && (
                        <>
                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question display */}
                            <VTCreatorQuestion
                                title={videoNextQuestion.contents?.preClickContent.title}
                                countdown={videoNextQuestion.contents?.preClickContent.question?.countdown}
                                questionId={videoNextQuestion.id}
                                handleEditQuestionTitle={handleEditQuestionTitle}
                                handleEditQuestionTimer={handleEditQuestionTimer}
                                marginTop={currentHotspot.currentHotspot ? "90px" : "5%"}
                            />

                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question drop container */}
                            {currentlyDragging && currentlyDragging.mediaType !== EDragContentOptions.SCENE && (
                                <VTCreatorDropContainer
                                    handleMediaDropped={handleMediaQuestionCanvasDropped}
                                    currentlyDragging={currentlyDragging}
                                    location={DropPoint.DROP_CANVAS}
                                    bottomDrawWidth={bottomDrawWidth}
                                    videoNextQuestionID={videoNextQuestion && videoNextQuestion.id}
                                    currentInterface={currentInterface}
                                />
                            )}

                            {/*---------------------------------------------------------------------------------*/}
                            {/* Question audio controls */}
                            {videoNextQuestion.contents &&
                                videoNextQuestion.contents.preClickContent.question?.audio && (
                                    <VTCreatorAudioControls
                                        audio={videoNextQuestion.contents.preClickContent.question?.audio}
                                        handleAudioDelete={handleAudioDelete}
                                        questionId={videoNextQuestion.id}
                                    />
                                )}

                            {currentInterface ? (
                                <>
                                    {currentlyPreview ? (
                                        <VTViewerUserInterface
                                            currentInterface={currentInterface}
                                            setCurrentInterface={setCurrentInterface}
                                            handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                            handleUpdateInterface={handleUpdateInterface}
                                            handleProceedVideo={(delayInSeconds?: number) => {
                                                if (currentHotspot.currentHotspot) {
                                                    dispatch(
                                                        setCurrentHotspot({
                                                            currentHotspot: undefined,
                                                            open: false,
                                                        }),
                                                    );
                                                }
                                                setTimeout(
                                                    () => {
                                                        if (
                                                            currentAudio !== undefined ||
                                                            currentFeedbackImage !== undefined ||
                                                            currentFeedbackVideo !== undefined ||
                                                            currentFeedbackInput !== undefined
                                                        ) {
                                                            setProceedVideoOnComplete(true);
                                                        } else {
                                                            getNextQuestion(
                                                                videoCurrentTime + 0.05,
                                                                scene.hotspots,
                                                                setVideoNextQuestion,
                                                            );
                                                            setVideoCurrentState("Playing");
                                                            video.play();
                                                        }
                                                    },
                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                );
                                                // return () => clearTimeout(timer);
                                            }}
                                            handleChangeScene={(sceneId: string, delayInSeconds?: number) => {
                                                setTimeout(
                                                    () => {
                                                        if (
                                                            currentAudio !== undefined ||
                                                            currentFeedbackImage !== undefined ||
                                                            currentFeedbackVideo !== undefined ||
                                                            currentFeedbackInput !== undefined
                                                        ) {
                                                            setChangeSceneOnComplete(sceneId);
                                                        } else {
                                                            navigate(`/viewer/${scenarioId}/${sceneId}`);
                                                        }
                                                    },
                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                );

                                                // return () => clearTimeout(timer);
                                            }}
                                        />
                                    ) : (
                                        <VTUserInterface
                                            currentInterface={currentInterface}
                                            setCurrentInterface={setCurrentInterface}
                                            handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                            handleUpdateInterface={handleUpdateInterface}
                                            scenario={scenario}
                                            videoCurrentState={videoCurrentState}
                                        />
                                    )}
                                </>
                            ) : (
                                <Grid
                                    sx={{
                                        position: "absolute",
                                        right: "50px",
                                        width: 0,
                                        height: "100%",
                                    }}
                                    container
                                    alignContent={"center"}
                                >
                                    {videoNextQuestion &&
                                        videoNextQuestion.contents &&
                                        videoNextQuestion.contents.preClickContent.question?.answers.map(
                                            (hotspot: IHotspot) =>
                                                hotspot.type === EHotspotType.INTERFACE && (
                                                    <Tooltip title={hotspot.contents.preClickContent.title}>
                                                        <Grid item>
                                                            <VTButton
                                                                sx={{
                                                                    zIndex: 10,
                                                                    width: "10px",
                                                                    height: "40px",
                                                                    borderRadius: "15px 0px 0px 15px",
                                                                    backgroundColor: "#a9a9a9",
                                                                    ":hover": {
                                                                        cursor: "pointer",
                                                                        backgroundColor: "#888888",
                                                                        boxShadow: "0 0 25px #2ad5c7",
                                                                    },
                                                                    marginBlock: "15px",
                                                                }}
                                                                onClick={() => setCurrentInterface(hotspot)}
                                                            >
                                                                <TextSnippetIcon />
                                                            </VTButton>
                                                        </Grid>
                                                    </Tooltip>
                                                ),
                                        )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {mediaType !== EMedia.DOCUMENT && (
                <>
                    {/*---------------------------------------------------------------------------------------*/}
                    {/* VT canvas */}
                    <VTCanvas
                        debug={false}
                        style={{
                            width: "calc(100% - " + (currentInterface ? 400 : 0) + "px)",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                        }}
                        attachToCameraRef={currentAttachedObject}
                        ref={canvasControlsRef}
                        limitZoom={mediaType !== EMedia.MODEL_3D_BASE}
                    >
                        <>
                            {/*-------------------------------------------------------------------------------------*/}
                            {/* App image cube */}
                            {(mediaType === EMedia.IMAGE_360 || (mediaType === EMedia.PASSTHROUGH && src)) && (
                                <VTCube sideLength={1000} loading={!src} image={currentImage} />
                            )}

                            {/*-------------------------------------------------------------------------------------*/}
                            {/* Show default grid for 3D scene*/}
                            {mediaType === EMedia.MODEL_3D_BASE && (
                                <ThreeDViewer handleSetPosition={handleSetPosition} />
                            )}

                            {/*-------------------------------------------------------------------------------------*/}
                            {/* Show default sphere for AR scene*/}
                            {mediaType === EMedia.PASSTHROUGH && <Passthrough />}

                            {/*-------------------------------------------------------------------------------------*/}
                            {/* Show default scene in none has been added */}
                            {!scene.id && <VTCube sideLength={300} image={undefined} />}

                            {/*-------------------------------------------------------------------------------------*/}
                            {/* App video sphere */}
                            {(mediaType === EMedia.VIDEO_360 || mediaType === EMedia.VIDEO) && (
                                <>
                                    {mediaType === EMedia.VIDEO_360 && (
                                        <VTSphere
                                            video={video}
                                            setVideo={setVideo}
                                            videoSrc={src}
                                            setVideoCurrentTime={setVideoCurrentTime}
                                            setVideoLoadingState={setVideoLoadingState}
                                        />
                                    )}

                                    {mediaType === EMedia.VIDEO && (
                                        <VTVideo
                                            video={video}
                                            setVideo={setVideo}
                                            videoSrc={src}
                                            setVideoCurrentTime={setVideoCurrentTime}
                                        />
                                    )}

                                    {/*---------------------------------------------------------------------------------*/}
                                    {/* video paused */}
                                    {videoNextQuestion &&
                                        videoCurrentState === "Question" &&
                                        videoNextQuestion.contents &&
                                        videoNextQuestion.contents.preClickContent.question!.answers.map(
                                            (hotspot: IHotspot) => (
                                                <>
                                                    {hotspot.type !== EHotspotType.INTERFACE &&
                                                        hotspot.type !== EHotspotType.QUESTION && (
                                                            <VTHotspotHTMLWrapper
                                                                key={hotspot.id}
                                                                hotspot={hotspot}
                                                                handleSaveHotspotLocation={handleSaveHotspotLocation}
                                                                handleDeleteHotspotLocation={
                                                                    handleDeleteHotspotLocation
                                                                }
                                                                handleProceedVideo={(delayInSeconds?: number) => {
                                                                    if (currentlyPreview) {
                                                                        if (currentHotspot.currentHotspot) {
                                                                            dispatch(
                                                                                setCurrentHotspot({
                                                                                    currentHotspot: undefined,
                                                                                    open: false,
                                                                                }),
                                                                            );
                                                                        }
                                                                        setTimeout(
                                                                            () => {
                                                                                if (
                                                                                    currentAudio !== undefined ||
                                                                                    currentFeedbackImage !==
                                                                                        undefined ||
                                                                                    currentFeedbackVideo !==
                                                                                        undefined ||
                                                                                    currentFeedbackInput !== undefined
                                                                                ) {
                                                                                    setProceedVideoOnComplete(true);
                                                                                } else {
                                                                                    getNextQuestion(
                                                                                        videoCurrentTime + 0.05,
                                                                                        scene.hotspots,
                                                                                        setVideoNextQuestion,
                                                                                    );
                                                                                    setVideoCurrentState("Playing");
                                                                                    video.play();
                                                                                }
                                                                            },
                                                                            delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                        );
                                                                    }
                                                                    // return () => clearTimeout(timer);
                                                                }}
                                                                handleChangeScene={(
                                                                    sceneId: string,
                                                                    delayInSeconds?: number,
                                                                ) => {
                                                                    setTimeout(
                                                                        () => {
                                                                            if (
                                                                                currentAudio !== undefined ||
                                                                                currentFeedbackImage !== undefined ||
                                                                                currentFeedbackVideo !== undefined ||
                                                                                currentFeedbackInput !== undefined
                                                                            ) {
                                                                                setChangeSceneOnComplete(sceneId);
                                                                            } else {
                                                                                navigate(
                                                                                    `/creator/${scenarioId}/${sceneId}`,
                                                                                );
                                                                            }
                                                                        },
                                                                        delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                    );

                                                                    // return () => clearTimeout(timer);
                                                                }}
                                                                setCurrentAttachedObject={setCurrentAttachedObject}
                                                                currentAttachedObject={currentAttachedObject}
                                                                videoCurrentState={videoCurrentState}
                                                            />
                                                        )}
                                                </>
                                            ),
                                        )}
                                </>
                            )}

                            {/*-------------------------------------------------------------------------------------*/}
                            {/* Render hotspots */}
                            {hotspots &&
                                (videoCurrentState !== "Question" ||
                                    (mediaType !== EMedia.VIDEO_360 && mediaType !== EMedia.VIDEO)) &&
                                hotspots.map((hotspot: IHotspot, i: number) => (
                                    <>
                                        {hotspot.type !== EHotspotType.QUESTION &&
                                            hotspot.type !== EHotspotType.INTERFACE && (
                                                <>
                                                    {hotspot.type === EHotspotType.IMMERSIVE_MODEL ? (
                                                        <ImmersiveModel
                                                            handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                                            handleSaveHotspotLocation={handleSaveHotspotLocation}
                                                            videoCurrentState={videoCurrentState}
                                                            hotspot={hotspot}
                                                            setCurrentHotspot={setCurrentHotspot}
                                                            handleChangeScene={(
                                                                sceneId: string,
                                                                delayInSeconds?: number,
                                                            ) => {
                                                                setTimeout(
                                                                    () => {
                                                                        if (
                                                                            currentAudio !== undefined ||
                                                                            currentFeedbackImage !== undefined ||
                                                                            currentFeedbackVideo !== undefined ||
                                                                            currentFeedbackInput !== undefined
                                                                        ) {
                                                                            setChangeSceneOnComplete(sceneId);
                                                                        } else {
                                                                            navigate(
                                                                                `/creator/${scenarioId}/${sceneId}`,
                                                                            );
                                                                        }
                                                                    },
                                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                );

                                                                // return () => clearTimeout(timer);
                                                            }}
                                                        />
                                                    ) : (
                                                        <VTHotspotHTMLWrapper
                                                            key={hotspot.id}
                                                            hotspot={hotspot}
                                                            handleSaveHotspotLocation={handleSaveHotspotLocation}
                                                            handleDeleteHotspotLocation={handleDeleteHotspotLocation}
                                                            currentAttachedObject={currentAttachedObject}
                                                            setCurrentAttachedObject={setCurrentAttachedObject}
                                                            videoCurrentState={videoCurrentState}
                                                            handleChangeScene={(
                                                                sceneId: string,
                                                                delayInSeconds?: number,
                                                            ) => {
                                                                setTimeout(
                                                                    () => {
                                                                        if (
                                                                            currentAudio !== undefined ||
                                                                            currentFeedbackImage !== undefined ||
                                                                            currentFeedbackVideo !== undefined ||
                                                                            currentFeedbackInput !== undefined
                                                                        ) {
                                                                            setChangeSceneOnComplete(sceneId);
                                                                        } else {
                                                                            navigate(
                                                                                `/creator/${scenarioId}/${sceneId}`,
                                                                            );
                                                                        }
                                                                    },
                                                                    delayInSeconds ? delayInSeconds * 1000 : 0,
                                                                );

                                                                // return () => clearTimeout(timer);
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                    </>
                                ))}
                        </>
                    </VTCanvas>
                </>
            )}

            {mediaType === EMedia.DOCUMENT && (
                <>
                    {currentlyPreview ? (
                        <VTRichTextEditorContentPreview
                            sx={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#333333",
                                overflowY: "auto",
                                padding: "150px 50px",
                            }}
                            htmlContent={scene.src}
                        />
                    ) : (
                        // TODO: add container back in
                        // <Container>
                        <VTRichTextEditor
                            save={{
                                onSave: () => {
                                    updateScenario({
                                        ...scenario,
                                        scenes: scenario.scenes.map((element: IScene) =>
                                            sceneId === element.id
                                                ? {
                                                      ...element,
                                                      src: richTextEditorContent,
                                                  }
                                                : element,
                                        ),
                                    });
                                    setRichTextEditorChanged(false);
                                },
                                disable: !richTextEditorChanged,
                            }}
                            //currentlyDragging={currentlyDraggingObj}
                            onClickImageIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Images",
                                        width: 400,
                                        option: undefined,
                                    }),
                                );
                            }}
                            onClickVideoIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Videos",
                                        width: 400,
                                        option: undefined,
                                    }),
                                );
                            }}
                            onClickAudioIcon={() => {
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Audio",
                                        width: 400,
                                        option: undefined,
                                    }),
                                );
                            }}
                            startingHtmlContent={scene.src}
                            onChange={(htmlStringContent: string) => {
                                // if (currentPrePostTab === "post") {
                                setRichTextEditorContent(htmlStringContent);
                                // } else {
                                //   setPreClickContent(htmlStringContent);
                                // }
                                if (!richTextEditorChanged) {
                                    setRichTextEditorChanged(true);
                                }
                            }}
                            sx={{
                                height: "100%",
                                borderRadius: 0,
                                "& .MuiBox-root": {
                                    "& .css-1sj301d": {
                                        paddingLeft: "40px",
                                    },
                                },
                            }}
                        />
                        // </Container>
                    )}
                </>
            )}

            <VTCreatorTimelineContainer
                scenario={scenario}
                scenarioLoading={scenarioLoading}
                handleMediaTimelineDropped={handleMediaTimelineDropped}
                setSceneName={setSceneName}
                setSceneDelete={setSceneDelete}
                setSceneInitialView={setSceneInitialView}
                setSceneStepper={setSceneStepper}
                setSceneReorder={setSceneReorder}
                isLoading={isLoading}
                bottomDrawWidth={bottomDrawWidth}
                setBottomDrawWidth={setBottomDrawWidth}
                timelineMode={timelineMode}
                setTimelineMode={setTimelineMode}
                mediaType={mediaType}
                videoNextQuestion={videoNextQuestion}
                videoCurrentState={videoCurrentState}
                setVideoCurrentState={setVideoCurrentState}
                currentQuestions={currentQuestions}
                videoCurrentTime={videoCurrentTime}
                video={video}
                getNextQuestion={getNextQuestion}
                setVideoNextQuestion={setVideoNextQuestion}
                setSceneDuplicate={setSceneDuplicate}
                currentInterface={currentInterface}
            />

            {currentHotspot.currentHotspot && (
                <VTHotspotQuickEdit
                    scenario={scenario}
                    updateScenario={updateScenario}
                    videoCurrentState={videoCurrentState}
                    currentInterface={currentInterface}
                />
            )}

            <VTHotspotEditDialog
                sx={{
                    "& .MuiDialogContent-root": {
                        height: "1000px", // make dialog a fixed height
                    },
                    position: "absolute",
                    left: currentSidePanel.width + 100,
                    height: "90vh",
                    marginTop: "80px",
                }}
                scenario={scenario}
                updateScenario={updateScenario}
                videoNextQuestion={videoNextQuestion}
                videoCurrentTime={videoCurrentTime}
                videoCurrentState={videoCurrentState}
            />
        </div>
    );
}
