import {
    VTButton,
    VTCanvas,
    VTCard,
    VTMenu,
    VTTextField,
    VTTypography,
    VTVideoControls,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    Switch,
    Typography,
} from "@mui/material";

import { SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import NoVideos from "../../assets/images/NoVideos.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { v4 as uuid } from "uuid";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    useCreateImmersiveVideosMutation,
    usePreSignedUrlMutation,
    useUpdateAllImmersiveVideosMutation,
    useUpdateAllVideosMutation,
} from "../../services/media.service";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import VTSphere from "../components/VTSphere";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";
import { ExposedCameraControlFunctions } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTCanvasComponents/VTCameraControls";
import { setCurrentToast } from "../../store/slices/current.slice";
interface IProps {
    loading: boolean;
    immersiveVideos: IMediaItem[] | undefined;
    leftDrawWidth: number;
    deleteMediaItem: (mediaType: EMedia, collectionId: string, mediaId: string) => void;
    deleteLoading: boolean;
}

export default function ImmersiveVideosRepoContainer({
    immersiveVideos,
    leftDrawWidth,
    deleteMediaItem,
    deleteLoading,
    loading,
}: IProps) {
    console.log("immersive videos", immersiveVideos);
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const cameraControlsRef = useRef<ExposedCameraControlFunctions>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [createImmersiveVideos] = useCreateImmersiveVideosMutation();
    const [updateAllImmersiveVideos, { isLoading: immersiveVideoUpdateLoading }] =
        useUpdateAllImmersiveVideosMutation();

    const dispatch = useAppDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    const [filterValue, setFilterValue] = useState<string>("");
    const [changedItem, setChangedItem] = useState<string>("");

    const [video, setVideo] = useState<HTMLVideoElement>(document.createElement("video"));
    const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
    const [videoCurrentState, setVideoCurrentState] = useState<"Paused" | "Playing" | "Question" | undefined>(
        undefined,
    );
    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());
    const [videoPreview, setVideoPreview] = useState<string | null>(null);
    const [capturePreview, setCapturePreview] = useState<boolean>(false);

    const [checked, setChecked] = useState(false);
    const [highlight, setHighlight] = useState(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            if (checked) {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                    chopping: "processing",
                });
            } else {
                createImmersiveVideos({
                    id: userId,
                    media_id: videoId,
                    media_type: "three_sixty_video",
                    filetype: selectedVideo.type.split("/")[1],
                    name: videoName,
                    description: "",
                    date: Date.now(),
                    tags: [],
                    preview: capturePreview,
                    organisation: organisation,
                });
            }
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading) {
            setChangedItem("");
        }
    }, [deleteLoading]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain videos types
        if (videoFile.type.includes("video/") && ["mp4"].includes(videoFile.name.split(".")[1])) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            if (checked) {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/raw`,
                    filetype: videoFile.type.split("/")[1],
                });
            } else {
                // Get presigned url to upload video
                await preSignedUrl({
                    organisation,
                    media_type: "three_sixty_video",
                    media_id: `${videoId}/${videoId}`,
                    filetype: videoFile.type.split("/")[1],
                });
            }
        } else {
            setSelectedVideoError(true);
        }
    }

    async function handleGetPreviewURL() {
        // Get presigned url to upload image
        await preSignedUrl({
            organisation,
            media_type: "three_sixty_video",
            media_id: `${videoId}/${videoId}_preview`,
            filetype: "png",
        });
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    async function handlePreviewUpload() {
        if (videoPreview) {
            const file = await dataUrlToFile(videoPreview, "preview");

            if (uploadUrl && videoPreview) {
                dispatch(
                    uploadMedia({
                        media: file,
                        url: uploadUrl.url,
                        dispatch: dispatch,
                    }),
                );
            }
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
        setCapturePreview(false);
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setChecked(event.target.checked);

        if (event.target.checked) {
            dispatch(
                setCurrentToast({
                    id: "360_video_process_toast",
                    message: "This will proccess the 360 video metadata and upload for you.",
                }),
            );
        }
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    marginLeft: leftDrawWidth / 8 + 5,
                    transition: "0.5s",
                }}
            >
                <Grid container item sx={{ marginBottom: "25px" }} justifyContent={"space-between"}>
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search 360 Videos"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                        <VTButton
                            onClick={() => {
                                if (activeButton === 0) {
                                    setActiveButton(1);
                                } else {
                                    setActiveButton(0);
                                }
                            }}
                            sx={{
                                borderBottom: "3px solid rgb(150, 28, 255)",
                                maxWidth: "100%",
                                height: "60px",
                            }}
                            type="underline"
                            endIcon={
                                activeButton === 0 ? (
                                    <UploadIcon sx={{ marginLeft: "10px" }} />
                                ) : (
                                    <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                )
                            }
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>{activeButton === 0 ? "Upload 360 Videos" : "Return"}</Typography>
                            )}
                        </VTButton>
                    </Grid>
                </Grid>
                {/*-------------------------------------------------------------------------------------*/}
                {/* Card display */}

                {activeButton === 0 && (
                    <>
                        {immersiveVideos && immersiveVideos.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                <img src={NoVideos} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    To get started, add first 360 video file (.mp4 format)
                                </VTTypography>
                            </Grid>
                        ) : loading ? (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>
                                <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                    <VTCard
                                        sx={{ maxWidth: "100%" }}
                                        mediaType={{ imageUrl: "" }}
                                        title={""}
                                        loading={true}
                                    />
                                </Grid>

                                {(currentScreenWidthBreakpoint === "xl" || currentScreenWidthBreakpoint === "md") && (
                                    <Grid item sm={12} md={6} lg={4} xl={3} xs={12}>
                                        <VTCard
                                            sx={{ maxWidth: "100%" }}
                                            mediaType={{ imageUrl: "" }}
                                            title={""}
                                            loading={true}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                sx={{}}
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >
                                {/* @ts-ignore */}
                                {immersiveVideos
                                    ?.filter((immersiveVideo: IMediaItem) => {
                                        return (
                                            (nameBoxChecked &&
                                                immersiveVideo.name
                                                    ?.toLowerCase()
                                                    .includes(filterValue.toLowerCase())) ||
                                            (tagsBoxChecked &&
                                                immersiveVideo.tags.filter((tag: string) => {
                                                    return tag.toLowerCase().includes(filterValue.toLowerCase());
                                                }).length > 0)
                                        );
                                    })
                                    .sort((a: IMediaItem, b: IMediaItem) => {
                                        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                    })
                                    .map((immersiveVideo: any, i: number) => (
                                        <Grid
                                            key={immersiveVideo.id}
                                            sm={12}
                                            md={6}
                                            lg={4}
                                            xl={3}
                                            xs={12}
                                            item
                                            container
                                            justifyContent={"center"}
                                        >
                                            {(deleteLoading || immersiveVideoUpdateLoading) &&
                                            changedItem === immersiveVideo.id ? (
                                                <VTCard
                                                    sx={{ maxWidth: "100%", width: "100%" }}
                                                    mediaType={{ imageUrl: "" }}
                                                    title={""}
                                                    loading={true}
                                                />
                                            ) : (
                                                <VTCard
                                                    ref={i === 0 ? vtCardRef : undefined}
                                                    titleCutOffPoint={titleCutOffPoint}
                                                    onTitleEdited={(editedTitle: string) => {
                                                        setChangedItem(immersiveVideo.id);
                                                        updateAllImmersiveVideos({
                                                            id: immersiveVideo.id,
                                                            name: editedTitle,
                                                            media_type: EMedia.VIDEO_360,
                                                        });
                                                    }}
                                                    key={immersiveVideo.id}
                                                    sx={{ width: "100%", maxWidth: "none" }}
                                                    title={immersiveVideo.name ? immersiveVideo.name : "no name"}
                                                    mediaType={{
                                                        showPreview: true,
                                                        preview: immersiveVideo.preview
                                                            ? `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}_preview.png`
                                                            : "",
                                                        videoUrl: `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}.${immersiveVideo.filetype}`,
                                                    }}
                                                    onWholeCardClicked={() => {}}
                                                    menuOptions={{
                                                        menuOptionsList: [
                                                            // {
                                                            //     component: (
                                                            //         <VTButton
                                                            //             type={"list"}
                                                            //             startIcon={<MyLocationIcon />}
                                                            //             disabled={true}
                                                            //             onClick={() => {}}
                                                            //         >
                                                            //             Set start location
                                                            //         </VTButton>
                                                            //     ),
                                                            // },
                                                            {
                                                                component: (
                                                                    <>
                                                                        {organisation === "virtus-tech" && (
                                                                            <VTButton
                                                                                type={"list"}
                                                                                startIcon={<DownloadIcon />}
                                                                                onClick={() => {
                                                                                    const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/three_sixty_video/${immersiveVideo.id}/${immersiveVideo.id}.${immersiveVideo.filetype}`;

                                                                                    const link =
                                                                                        document.createElement("a");
                                                                                    link.href = imageUrl;

                                                                                    document.body.appendChild(link);
                                                                                    link.click();

                                                                                    document.body.removeChild(link);
                                                                                }}
                                                                            >
                                                                                Download
                                                                            </VTButton>
                                                                        )}
                                                                    </>
                                                                ),
                                                            },
                                                            {
                                                                component: (
                                                                    <VTButton
                                                                        type={"list"}
                                                                        startIcon={<DeleteIcon />}
                                                                        disabled={false}
                                                                        onClick={() => {
                                                                            setChangedItem(immersiveVideo.id);
                                                                            deleteMediaItem(
                                                                                EMedia.VIDEO_360,
                                                                                userId,
                                                                                immersiveVideo.id,
                                                                            );
                                                                        }}
                                                                    >
                                                                        Delete
                                                                    </VTButton>
                                                                ),
                                                            },
                                                        ],
                                                        menuPopOutZIndex: 100,
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    ))}
                            </Grid>
                        )}
                    </>
                )}

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Video upload */}
                {activeButton === 1 && (
                    <Grid container justifyContent={"center"} alignContent={"center"}>
                        {capturePreview && selectedVideo ? (
                            <Grid
                                item
                                container
                                sx={{
                                    marginTop: "20px",
                                    padding: "20px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    height: "calc(100vh - 300px)",
                                    width: "100%",
                                }}
                            >
                                <Grid item xs={12} sx={{ height: "300px", position: "relative" }}>
                                    <VTCanvas
                                        ref={cameraControlsRef}
                                        screenShot={(preview) => setVideoPreview(preview)}
                                        style={{ borderRadius: "15px" }}
                                    >
                                        <VTSphere
                                            video={video}
                                            setVideo={setVideo}
                                            videoSrc={`${
                                                process.env.REACT_APP_NINJA_MEDIA
                                            }/${organisation}/three_sixty_video/${videoId}/${videoId}.${
                                                selectedVideo!.type.split("/")[1]
                                            }`}
                                            setVideoCurrentTime={setVideoCurrentTime}
                                        />
                                    </VTCanvas>
                                    <VTVideoControls
                                        videoCurrentTime={videoCurrentTime}
                                        video={video}
                                        videoCurrentState={videoCurrentState}
                                        setVideoCurrentState={setVideoCurrentState}
                                    />
                                </Grid>
                                <Grid item container xs={12} sx={{ height: "200px", paddingTop: "10px" }}>
                                    {preSignedUrlLoading ? (
                                        <Grid
                                            xs={12}
                                            sx={{
                                                margin: "10px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <>
                                            {mediaUploading && (
                                                <Grid
                                                    xs={12}
                                                    sx={{
                                                        margin: "10px 0",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={mediaUploadPercentage}
                                                    />
                                                </Grid>
                                            )}

                                            {mediaUploadCompleted ? (
                                                <>
                                                    <Grid item xs={6}>
                                                        <VTButton
                                                            color={"secondary"}
                                                            fullWidth={true}
                                                            onClick={() => {
                                                                setSelectedVideo(null);
                                                                setVideoId(uuid());
                                                                setVideoName("");
                                                                dispatch(setMediaUploadCleanUp());
                                                                setCapturePreview(false);
                                                            }}
                                                        >
                                                            Upload Another
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <VTButton
                                                            fullWidth={true}
                                                            onClick={() => {
                                                                handleUploadCleanUp();
                                                            }}
                                                        >
                                                            Done
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item container xs={6} justifyContent={"flex-end"}>
                                                        <VTButton
                                                            color={"secondary"}
                                                            onClick={() => handleUploadCleanUp()}
                                                            sx={{ height: "35px", width: "200px" }}
                                                        >
                                                            Cancel
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <VTButton
                                                            disabled={mediaUploading}
                                                            onClick={() => {
                                                                if (cameraControlsRef.current) {
                                                                    cameraControlsRef.current.takeScreenShot();
                                                                    handlePreviewUpload();
                                                                }
                                                            }}
                                                            sx={{ height: "35px", width: "200px" }}
                                                        >
                                                            Save Preview
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        ) : (
                            <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                                {selectedVideo ? (
                                    <Grid container>
                                        <Grid item xs={12} sx={{ margin: "10px 5px" }}>
                                            <VTTextField
                                                value={videoName}
                                                onChange={(value) => setVideoName(value)}
                                                disabled={mediaUploading || mediaUploadCompleted}
                                            />
                                        </Grid>
                                        {!checked && (
                                            <Grid item xs={12} sx={{ margin: "10px 5px" }}>
                                                <video width="100%" height="100%" controls>
                                                    <source src={URL.createObjectURL(selectedVideo)} type="video/mp4" />
                                                </video>
                                            </Grid>
                                        )}

                                        {preSignedUrlLoading ? (
                                            <Grid
                                                xs={12}
                                                item
                                                sx={{
                                                    margin: "10px 5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <CircularProgress />
                                            </Grid>
                                        ) : (
                                            <>
                                                {mediaUploading || mediaUploadCompleted ? (
                                                    <>
                                                        {mediaUploadError ? (
                                                            <>
                                                                <Grid
                                                                    xs={12}
                                                                    item
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            color: "red",
                                                                        }}
                                                                        align="center"
                                                                    >
                                                                        Error Uploading Image
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    xs={12}
                                                                    item
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        onClick={() => handleUploadCleanUp()}
                                                                    >
                                                                        Cancel
                                                                    </VTButton>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Grid
                                                                    xs={12}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        width: "100%",
                                                                    }}
                                                                >
                                                                    <LinearProgress
                                                                        variant="determinate"
                                                                        value={mediaUploadPercentage}
                                                                    />
                                                                </Grid>
                                                                {mediaUploadCompleted ? (
                                                                    <>
                                                                        <Grid
                                                                            xs={checked ? 12 : 6}
                                                                            item
                                                                            sx={{
                                                                                margin: "10px 0",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                padding: "2px",
                                                                            }}
                                                                        >
                                                                            <VTButton
                                                                                color={"secondary"}
                                                                                fullWidth={true}
                                                                                onClick={() => {
                                                                                    handleUploadCleanUp();
                                                                                }}
                                                                            >
                                                                                Done
                                                                            </VTButton>
                                                                        </Grid>
                                                                        {!checked && (
                                                                            <Grid
                                                                                xs={6}
                                                                                item
                                                                                sx={{
                                                                                    margin: "10px 0",
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    padding: "2px",
                                                                                }}
                                                                            >
                                                                                <VTButton
                                                                                    color={
                                                                                        mediaUploadCompleted
                                                                                            ? "primary"
                                                                                            : "secondary"
                                                                                    }
                                                                                    fullWidth={true}
                                                                                    onClick={() => {
                                                                                        setCapturePreview(true);
                                                                                        dispatch(
                                                                                            setMediaUploadCleanUp(),
                                                                                        );
                                                                                        handleGetPreviewURL();
                                                                                    }}
                                                                                >
                                                                                    Set Preview
                                                                                </VTButton>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <Grid
                                                                        xs={12}
                                                                        item
                                                                        sx={{
                                                                            margin: "10px 0",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            padding: "2px",
                                                                        }}
                                                                    >
                                                                        <VTButton
                                                                            color={"secondary"}
                                                                            fullWidth={true}
                                                                            onClick={() => handleUploadCleanUp()}
                                                                        >
                                                                            Cancel
                                                                        </VTButton>
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={6}
                                                            item
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => setSelectedVideo(null)}
                                                                fullWidth={true}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                        <Grid
                                                            xs={6}
                                                            item
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                onClick={() => {
                                                                    if (videoName.length > 0) {
                                                                        handleVideoUpload();
                                                                    } else {
                                                                        dispatch(
                                                                            setCurrentToast({
                                                                                id: "360 videos",
                                                                                message:
                                                                                    "must enter a name for 360 video",
                                                                            }),
                                                                        );
                                                                    }
                                                                }}
                                                                fullWidth={true}
                                                            >
                                                                upload
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid
                                        container
                                        //TODO: user this as an overlay
                                        onDragEnter={() => setHighlight(true)}
                                        onDragLeave={() => setHighlight(false)}
                                        sx={{
                                            border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                            borderRadius: "15px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Typography sx={{ marginTop: "6px" }}>Procces 360 Video</Typography>
                                            <Switch
                                                checked={checked}
                                                onChange={handleSwitchChange}
                                                inputProps={{ "aria-label": "controlled" }}
                                            />
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "50px 0px",
                                            }}
                                        >
                                            <img
                                                src={"/media/images/upload.svg"}
                                                alt="upload media"
                                                style={{ maxHeight: "200px" }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography align="center">
                                                Drop or choose video to upload (.mp4 format)
                                            </Typography>

                                            {selectedVideoError && (
                                                <Typography align="center" sx={{ color: "red" }}>
                                                    file type not accepted
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid
                                            xs={12}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "50px 0px",
                                            }}
                                        >
                                            <input
                                                accept="video/mp4"
                                                type="file"
                                                id="file"
                                                ref={inputFileRef}
                                                style={{ display: "none" }}
                                                onChange={(e) => handleSelect(e)}
                                            />
                                            <VTButton
                                                onClick={() => {
                                                    if (inputFileRef && inputFileRef.current) {
                                                        inputFileRef.current.click();
                                                    }
                                                }}
                                            >
                                                Choose File
                                            </VTButton>
                                        </Grid>
                                    </Grid>
                                )}
                            </div>
                        )}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
