import { LoginContainer } from "../containers/LoginContainer";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage } from "../../store/slices/current.slice";
import ReactGA from "react-ga4";

// Only display layouts in pages
export default function LoginPage() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("login"));

        ReactGA.send({ hitType: "pageview", page: "/Login", title: "Login" });
    }, []);

    return <LoginContainer />;
}
