import { EMedia } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IHotspot } from "./hotspot.models";
import { Vector3 } from "three";

export enum EScenarioTypes {
    DEFAULT = "default",
    INVENTORY = "inventory",
}
export interface IScenarioOverview {
    id: string;
    title: string;
    type: string;
    description: string;
    previewImage: string;
    admins: string[];
    viewers: string[];
    tags: string[];
    locked: boolean;
    owner: string;
    publishedVersion: string;
    date: Date;
    inventory?: { postcode?: string; address?: string; location?: string };
}

export interface IScenario extends IScenarioOverview {
    scenes: IScene[];
}

export interface IScene {
    id: string;
    name: string;
    src: string;
    mediaType: EMedia;
    hotspots: IHotspot[];
    context: TSceneContext;
    previewImage?: string;
    // TODO; make vector 3
    startLocation: any[];
    audio?: string;
    stepper?: boolean;
}

export type TSceneContext = IThreeSixtyVideoContext | IThreeSixtyImageContext;

export interface IThreeSixtyVideoContext {
    startTime: number;
    endTime: number;
    quality: string;
}

export interface IThreeSixtyImageContext {
    quality?: string;
}

export interface IStep {
    id: string;
    name: string;
    skippable: boolean;
}
