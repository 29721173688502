//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SvgIconProps, SxProps, Typography, useTheme } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";

// Custom components imports (and hooks and helpers)
import { ReactElement } from "react";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    tabName: string;
    icon: ReactElement<SvgIconProps<"svg", {}>>;
    setCloseDraw: () => void;
    setOpenDraw: (openDraw: string) => void;
    currentTab?: string | undefined;
    sx?: SxProps;
    disable?: boolean;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorTabOption({
    currentTab,
    tabName,
    icon,
    setCloseDraw,
    setOpenDraw,
    sx,
    disable = false,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const theme = useTheme();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <VTButton
            disabled={disable}
            sx={{
                "&.Mui-disabled": {
                    background: theme.palette.background.default,
                },
                height: 85,
                borderRadius: 0,
                backgroundColor:
                    currentTab === tabName ? theme.palette.background.paper : theme.palette.background.default,
                ":hover": {
                    backgroundColor: theme.palette.background.paper,
                },
                ...sx,
            }}
            onClick={() => (currentTab === tabName ? setCloseDraw() : setOpenDraw(tabName))}
        >
            {
                <Grid
                    container
                    sx={{ ...(disable && { opacity: "0.3" }) }}
                    justifyContent={"center"}
                    alignContent={"center"}
                >
                    <Grid item sx={{ color: theme.palette.text.primary }}>
                        {icon}
                    </Grid>
                    <Grid item sx={{ color: theme.palette.text.primary }}>
                        <Typography variant="caption">{tabName}</Typography>
                    </Grid>
                </Grid>
            }
        </VTButton>
    );
}
