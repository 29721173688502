//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { Grid, SxProps, TextField, useMediaQuery } from "@mui/material";
import { VTButton } from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";

// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    currentImage: string;
    setCurrentImage: (currentPreviewImage: string) => void;
    disabled?: boolean;
    sx?: SxProps;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImageDropper({ currentImage, setCurrentImage, disabled = false, sx }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Render for the tab buttons

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show creator page tabs options
    return (
        <Grid item container xs={12} justifyContent={"center"} sx={{ marginTop: "20px", ...sx }}>
            {/*----------------------------------------------------------------------------------------*/}
            {/* Relative container to allow the drop box to overlap the image */}
            <Grid item container sx={{ position: "relative" }} xs={12} justifyContent={"center"}>
                <>
                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Shows the current preview image or a default image is none set */}
                    {!disabled && (
                        <img
                            src={
                                currentImage === ""
                                    ? "https://virtus-ninja-dev.s3.eu-west-1.amazonaws.com/virtus_tech/images/noImage.png"
                                    : currentImage
                            }
                            style={{
                                width: "80%",
                                height: "193px",
                                objectFit: "cover",
                                borderRadius: 15,
                            }}
                        />
                    )}

                    {/*-------------------------------------------------------------------------------------*/}
                    {/* Drop field, accepts urls from dragged images to set image, style to be less visible */}

                    {currentSidePanel.width !== 0 && currentSidePanel.currentTab === "Images" && (
                        <TextField
                            value={""}
                            onChange={(e) => {
                                if (!disabled) {
                                    setCurrentImage(e.target.value);
                                }
                            }}
                            sx={{
                                width: "80%",
                                position: "absolute",
                                top: 0,
                                backgroundColor: "rgba(0,0,0,0)",
                                color: "red",
                            }}
                            inputProps={{ style: { color: "rgba(0,0,0,0)" } }}
                            multiline
                            rows={7}
                        />
                    )}
                </>

                <Grid item container xs={12} justifyContent={"space-evenly"}>
                    {/*----------------------------------------------------------------------------*/}
                    {/* Button to open the image side panel */}
                    {(currentSidePanel.width === 0 || currentSidePanel.currentTab !== "Images") && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() =>
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Images",
                                            width: 400,
                                            option: undefined,
                                        }),
                                    )
                                }
                            >
                                Choose image
                            </VTButton>
                        </Grid>
                    )}

                    {currentImage !== "" && (
                        <Grid item xs={5}>
                            <VTButton
                                sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                    marginTop: "15px",
                                }}
                                onClick={() => setCurrentImage("")}
                            >
                                Remove image
                            </VTButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
