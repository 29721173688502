import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { pink } from "@mui/material/colors";
import { useEffect, useRef, useState } from "react";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    Box,
    Breakpoint,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    LinearProgress,
    SxProps,
    Typography,
} from "@mui/material";
import {
    VTAudioItem,
    VTButton,
    VTDialog,
    VTMenu,
    VTTextField,
    VTTypography,
    VTAudioPlayerCard,
    useGetMuiBreakPointLabel,
    useGetVTCardTitleCutOffPoint,
} from "@virtus-tech-repository/virtus-tech-repository";
import { MicRounded, SearchRounded } from "@mui/icons-material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import NoAudios from "../../assets/images/NoAudios.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from "uuid";
import {
    useCreateAllAudioMutation,
    useDeleteMediaMutation,
    usePreSignedUrlMutation,
    useUpdateAllAudioMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import ReactAudioPlayer from "react-audio-player";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import UploadIcon from "@mui/icons-material/Upload";
import VTAudioRecord from "../components/VTAudioRecord";
import { setCurrentToast } from "../../store/slices/current.slice";
interface IProps {
    audio: IMediaItem[] | undefined;
    leftDrawWidth: number;
    loading: boolean;
    deleteLoading: boolean;
    sx?: SxProps;
    deleteMediaItem: (mediaType: EMedia, collectionId: string, mediaId: string) => void;
}

export default function AudioCardContainer({
    audio,
    leftDrawWidth,
    loading,
    sx,
    deleteMediaItem,
    deleteLoading,
}: IProps) {
    const { organisation, id: userId } = useAppSelector((state) => state.userReducer);

    const [nameBoxChecked, setNameBoxChecked] = useState<boolean>(true);
    const [tagsBoxChecked, setTagsBoxChecked] = useState<boolean>(false);

    const [filterValue, setFilterValue] = useState<string>("");

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [updateAllAudio, { isLoading: updateAllAudioIsLoading }] = useUpdateAllAudioMutation();
    const [createAllAudio] = useCreateAllAudioMutation();

    const [selectedAudio, setSelectedAudio] = useState<File | null>(null);
    const [selectedAudioError, setSelectedAudioError] = useState<boolean>(false);
    const [audioName, setAudioName] = useState<string>("");
    const [audioId, setAudioId] = useState<string>(uuid());
    const [changedItem, setChangedItem] = useState<string>("");

    const [highlight, setHighlight] = useState(false);
    const [recordModelOpen, setRecordModelOpen] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const { currentQuestion } = useAppSelector((state) => state.currentReducer);

    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const currentScreenWidthBreakpoint = useGetMuiBreakPointLabel();
    const breakPointsToHideUploadTextOn: Breakpoint[] = ["md", "sm", "xs"];

    const [vtCardRef, titleCutOffPoint] = useGetVTCardTitleCutOffPoint();


    useEffect(() => {
        if (mediaUploadCompleted && selectedAudio) {
            createAllAudio({
                id: userId,
                media_id: audioId,
                media_type: "audio",
                filetype: selectedAudio.type.split("/")[1],
                name: audioName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);

    useEffect(() => {
        if (!deleteLoading && !updateAllAudioIsLoading) {
            setChangedItem("");
        }
    }, [deleteLoading, updateAllAudioIsLoading]);

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(audioFile: File) {
        // TODO: in the future only allow certain image types
        if (audioFile.type.includes("audio/") && ["mp3"].includes(audioFile.name.split(".")[1])) {
            setSelectedAudioError(false);
            setSelectedAudio(audioFile);
            setAudioName(audioFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "audio",
                media_id: `${audioId}`,
                filetype: audioFile.type.split("/")[1],
            });
        } else {
            setSelectedAudioError(true);
        }
    }

    // Function that handle audio added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle audio added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleAudioUpload() {
        if (uploadUrl && selectedAudio) {
            dispatch(
                uploadMedia({
                    media: selectedAudio,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedAudio(null);
        setAudioId(uuid());
        setAudioName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    return (
        <Grid container xs={12} alignItems={"flex-start"}>
            <Grid
                // item
                container
                xs={12}
                spacing={1}
                sx={{
                    margin: 5,
                    marginTop: "110px",
                    // maxWidth: "100%",
                    marginLeft: leftDrawWidth / 8 + 5,
                    transition: "0.5s",
                }}
            >
                {/* <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        border: "2px solid blue",
                        display: "flex",
                        alignItems: "center",
                    }}
                > */}
                <Grid
                    xs={12}
                    // spacing={1}
                    container
                    item
                    sx={{ marginBottom: "25px" }}
                    justifyContent={"space-between"}
                >
                    {activeButton === 0 && (
                        <Grid item xs={6}>
                            <VTTextField
                                value={filterValue}
                                onChange={(text: string) => setFilterValue(text)}
                                placeholder="Search Audio"
                                endIcon={<SearchRounded />}
                                sx={{ width: "100%", maxWidth: "350px" }}
                            />
                        </Grid>
                    )}

                    <Grid container item xs={activeButton === 0 ? 6 : 12} justifyContent={"end"}>
                        <VTButton
                            onClick={() => setRecordModelOpen(true)}
                            sx={{
                                borderRight: "1px solid rgb(150, 28, 255)",
                                borderBottom: "none",
                                maxWidth: "100%",
                                height: "60px",
                            }}
                            type="underline"
                            endIcon={<MicRounded sx={{ marginLeft: "10px" }} />}
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>Record Audio</Typography>
                            )}
                        </VTButton>
                        <VTButton
                            onClick={() => {
                                if (activeButton === 0) {
                                    setActiveButton(1);
                                } else {
                                    setActiveButton(0);
                                }
                            }}
                            sx={{
                                borderBottom: "none",
                                maxWidth: "100%",
                                height: "60px",
                            }}
                            type="underline"
                            endIcon={
                                activeButton === 0 ? (
                                    <UploadIcon sx={{ marginLeft: "10px" }} />
                                ) : (
                                    <KeyboardReturnIcon sx={{ marginLeft: "10px" }} />
                                )
                            }
                        >
                            {!breakPointsToHideUploadTextOn.includes(currentScreenWidthBreakpoint) && (
                                <Typography>{activeButton === 0 ? "Upload Audio" : "Return"}</Typography>
                            )}
                        </VTButton>
                    </Grid>
                </Grid>

                <VTAudioRecord open={recordModelOpen} handleClose={() => setRecordModelOpen(false)} />

                {/*-------------------------------------------------------------------------------------*/}
                {/* Mapping the audio data */}
                {activeButton === 0 && (
                    <>
                        {audio && audio.length === 0 ? (
                            <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "50px" }}>
                                <img src={NoAudios} />
                                <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                    To get started, add first audio file (.mp3 format)
                                </VTTypography>
                            </Grid>
                        ) : (
                            <>
                                {loading ? (
                                    <Grid
                                        sx={{}}
                                        item
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        <Grid item sm={6} md={4} lg={3} xs={12}>
                                            <VTAudioPlayerCard
                                                sx={{ maxWidth: "100%" }}
                                                audioSrc={""}
                                                title={""}
                                                loading={true}
                                            />
                                        </Grid>
                                        <Grid item sm={6} md={4} lg={3} xs={12}>
                                            <VTAudioPlayerCard
                                                sx={{ maxWidth: "100%" }}
                                                audioSrc={""}
                                                title={""}
                                                loading={true}
                                            />
                                        </Grid>
                                        <Grid item sm={6} md={4} lg={3} xs={12}>
                                            <VTAudioPlayerCard
                                                sx={{ maxWidth: "100%" }}
                                                audioSrc={""}
                                                title={""}
                                                loading={true}
                                            />
                                        </Grid>
                                        {currentScreenWidthBreakpoint !== "md" && (
                                            <Grid item sm={6} md={4} lg={3} xs={12}>
                                                <VTAudioPlayerCard
                                                    sx={{ maxWidth: "100%" }}
                                                    audioSrc={""}
                                                    title={""}
                                                    loading={true}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : (
                                    <Grid
                                        sx={{}}
                                        item
                                        container
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >
                                        {/* @ts-ignore */}
                                        {audio
                                            ?.filter((audio: IMediaItem) => {
                                                return (
                                                    (nameBoxChecked &&
                                                        audio.name
                                                            ?.toLowerCase()
                                                            .includes(filterValue.toLocaleLowerCase())) ||
                                                    (tagsBoxChecked &&
                                                        audio.tags.filter((tag: string) => {
                                                            return tag
                                                                .toLowerCase()
                                                                .includes(filterValue.toLowerCase());
                                                        }).length > 0)
                                                );
                                            })
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((audio: IMediaItem, i: number) => (
                                                <Grid item sm={6} md={4} lg={3} xs={12} key={audio.id}>
                                                    <VTAudioPlayerCard
                                                        ref={i === 0 ? vtCardRef : undefined}
                                                        sx={{ maxWidth: "100%" }}
                                                        key={audio.id}
                                                        audioSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${audio.id}.${audio.filetype}`}
                                                        // title={item.name ? item.name : ""}
                                                        // audioSrc={audio.src}
                                                        loading={
                                                            (deleteLoading || updateAllAudioIsLoading) &&
                                                            changedItem === audio.id
                                                        }
                                                        title={audio.name ? audio.name : "no name"}
                                                        titleCutOffPoint={titleCutOffPoint}
                                                        menuOptions={{
                                                            menuOptionsList: [
                                                                {
                                                                    component: (
                                                                        <>
                                                                            {organisation === "virtus-tech" && (
                                                                                <VTButton
                                                                                    disabled
                                                                                    type={"list"}
                                                                                    startIcon={<DownloadIcon />}
                                                                                    onClick={() => {
                                                                                        // const imageUrl = `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${audio.id}.${audio.filetype}`;
                                                                                        // const link =
                                                                                        //     document.createElement("a");
                                                                                        // link.href = imageUrl;
                                                                                        // document.body.appendChild(link);
                                                                                        // link.click();
                                                                                        // document.body.removeChild(link);
                                                                                    }}
                                                                                >
                                                                                    Download
                                                                                </VTButton>
                                                                            )}
                                                                        </>
                                                                    ),
                                                                },

                                                                {
                                                                    component: (
                                                                        <VTButton
                                                                            type={"list"}
                                                                            startIcon={<DeleteIcon />}
                                                                            disabled={false}
                                                                            onClick={() => {
                                                                                // setShowSkeletonForCardById(video.id);
                                                                                // deleteMediaItem(
                                                                                //     EMedia.VIDEO,
                                                                                //     userId,
                                                                                //     video.id,
                                                                                // );
                                                                                setChangedItem(audio.id);

                                                                                deleteMediaItem(
                                                                                    EMedia.AUDIO,
                                                                                    userId,
                                                                                    audio.id,
                                                                                );
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </VTButton>
                                                                    ),
                                                                },
                                                            ],
                                                            menuPopOutZIndex: 100,
                                                        }}
                                                        onTitleEdited={(editedTitle: string) => {
                                                            setChangedItem(audio.id);

                                                            updateAllAudio({
                                                                id: audio.id,
                                                                name: editedTitle,
                                                                media_type: EMedia.AUDIO,
                                                            });
                                                        }}
                                                    />
                                                    {/* <VTAudioItem
                                                audioItemTitle={item.name}
                                                audioItemSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                               
                                            /> */}
                                                </Grid>
                                            ))}
                                    </Grid>
                                )}
                            </>
                        )}
                    </>
                )}

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Audio upload */}
                {activeButton === 1 && (
                    <Grid container justifyContent={"center"} alignContent={"center"}>
                        <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                            {selectedAudio ? (
                                <Grid container>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <VTTextField
                                            value={audioName}
                                            onChange={(value) => setAudioName(value)}
                                            disabled={mediaUploading || mediaUploadCompleted}
                                        />
                                    </Grid>
                                    <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                        <ReactAudioPlayer
                                            src={URL.createObjectURL(selectedAudio)}
                                            autoPlay={false}
                                            controls
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    {preSignedUrlLoading ? (
                                        <Grid
                                            xs={12}
                                            sx={{
                                                margin: "10px 5px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <CircularProgress />
                                        </Grid>
                                    ) : (
                                        <>
                                            {mediaUploading || mediaUploadCompleted ? (
                                                <>
                                                    {mediaUploadError ? (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <Typography
                                                                    style={{
                                                                        color: "red",
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    Error Uploading Audio
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    Cancel
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Grid
                                                                xs={12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                <LinearProgress
                                                                    variant="determinate"
                                                                    value={mediaUploadPercentage}
                                                                />
                                                            </Grid>
                                                            {mediaUploadCompleted && (
                                                                <Grid
                                                                    xs={6}
                                                                    sx={{
                                                                        margin: "10px 0",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        padding: "2px",
                                                                    }}
                                                                >
                                                                    <VTButton
                                                                        color={"secondary"}
                                                                        fullWidth={true}
                                                                        onClick={() => {
                                                                            setSelectedAudio(null);
                                                                            setAudioId(uuid());
                                                                            setAudioName("");
                                                                            dispatch(setMediaUploadCleanUp());
                                                                        }}
                                                                    >
                                                                        Upload Another
                                                                    </VTButton>
                                                                </Grid>
                                                            )}
                                                            <Grid
                                                                xs={mediaUploadCompleted ? 6 : 12}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={
                                                                        mediaUploadCompleted ? "primary" : "secondary"
                                                                    }
                                                                    fullWidth={true}
                                                                    onClick={() => handleUploadCleanUp()}
                                                                >
                                                                    {mediaUploadCompleted ? "Done" : "Cancel"}
                                                                </VTButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            color={"secondary"}
                                                            onClick={() => setSelectedAudio(null)}
                                                            fullWidth={true}
                                                        >
                                                            Cancel
                                                        </VTButton>
                                                    </Grid>
                                                    <Grid
                                                        xs={6}
                                                        sx={{
                                                            margin: "10px 0",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            padding: "2px",
                                                        }}
                                                    >
                                                        <VTButton
                                                            onClick={() => {
                                                                if (audioName.length > 0) {
                                                                    handleAudioUpload();
                                                                } else {
                                                                    dispatch(
                                                                        setCurrentToast({
                                                                            id: "audio",
                                                                            message: "must enter a name for audio",
                                                                        }),
                                                                    );
                                                                }
                                                            }}
                                                            fullWidth={true}
                                                        >
                                                            upload
                                                        </VTButton>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid
                                    container
                                    //TODO: user this as an overlay
                                    onDragEnter={() => setHighlight(true)}
                                    onDragLeave={() => setHighlight(false)}
                                    sx={{
                                        border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                        borderRadius: "15px",
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <img
                                            src={"/media/images/upload.svg"}
                                            alt="upload media"
                                            style={{ maxHeight: "200px" }}
                                        />
                                    </Grid>
                                    <Grid xs={12}>
                                        <Typography align="center">
                                            Drop or choose Audio to upload (.mp3 format)
                                        </Typography>

                                        {selectedAudioError && (
                                            <Typography align="center" sx={{ color: "red" }}>
                                                file type not accepted
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0px",
                                        }}
                                    >
                                        <input
                                            accept="audio/*"
                                            type="file"
                                            id="file"
                                            ref={inputFileRef}
                                            style={{ display: "none" }}
                                            onChange={(e) => handleSelect(e)}
                                        />
                                        <VTButton
                                            onClick={() => {
                                                if (inputFileRef && inputFileRef.current) {
                                                    inputFileRef.current.click();
                                                }
                                            }}
                                        >
                                            Choose File
                                        </VTButton>
                                    </Grid>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
