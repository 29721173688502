export enum EDragContentOptions {
    IMAGE = "images",
    IMAGE_360 = "three_sixty_image",
    AUDIO = "audio",
    VIDEO = "video",
    VIDEO_360 = "three_sixty_video",
    MODEL_3D_BASE = "three_d_model",
    IMMERSIVE_MODEL = "immersive_model",
    DOCUMENT = "document",
    PASSTHROUGH = "passthrough",
    MOVEMENT = "movement",
    INFORMATION = "information",
    ANSWER = "answer",
    QUESTION = "question",
    INTERFACE = "interface",
    SCENE = "scene",
    MOVEMENT_3D = "movement_3D",
    PROCEED = "proceed",
    COMPLETE = "complete",
}
