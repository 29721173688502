//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect, useState } from "react";

// Material ui Imports

// Custom components imports (and hooks and helpers)
import HomeScenarioDialog from "../components/HomeScenarioDialog";
import {
    useDeleteScenarioMutation,
    useGetAllScenarioOverviewQuery,
    useGetScenarioQuery,
    useUpdateScenarioOverviewMutation,
} from "../../services/scenario.service";
import { useGetAllGroupsQuery, useGetAllUsersQuery } from "../../services/users.service";
import { useGetAllImagesQuery } from "../../services/media.service";
import { useGetLanguageQuery } from "../../services/language.service";
import HomeSidePanelContainer from "../containers/HomeSidePanelContainer";
import HomeCardGridContainer from "../containers/HomeCardGridContainer";
import Scenario360ImageCardsContainer from "../containers/Scenes360ImageCardsContainer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { element } from "prop-types";
import { EScenarioTypes, IScenarioOverview } from "../../models/scenario.models";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { Grid } from "@mui/material";
import { IUserInGroup } from "../../models/user.model";
import { VTLandingOptions } from "@virtus-tech-repository/virtus-tech-repository";
// Media imports
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { blankScenarioOverview } from "../../helpers/scenarioDataGenerator";
import ReactGA from "react-ga4";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeContentWrapper() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const { id: userId } = useAppSelector((state) => state.userReducer);
    const dispatch = useAppDispatch();

    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { data, isLoading, error } = useGetAllScenarioOverviewQuery(userId);

    const users = useGetAllUsersQuery();
    const groups = useGetAllGroupsQuery(userId);
    const images = useGetAllImagesQuery(userId);
    const language = useGetLanguageQuery();

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterValue, setFilterValue] = useState<"All Scenarios" | "Your Scenarios" | "Shared With" | "New Scenario">(
        "All Scenarios",
    );
    const [scenarioDialog, setScenarioDialog] = useState<IScenarioOverview | undefined>(undefined);

    const [currentPreviewImage, setCurrentPreviewImage] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    const [updateScenarioOverview, { isLoading: scenarioUpdate }] = useUpdateScenarioOverviewMutation();
    const [deleteScenarioOverview] = useDeleteScenarioMutation();

    useEffect(() => {
        dispatch(
            setCurrentSidePanel({
                currentTab: "Routes",
                width: 200,
                option: undefined,
            }),
        );

        ReactGA.send({ hitType: "pageview", page: "/Home", title: "Home page" });
    }, []);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function handleSaveScenario(
        currentTitle: string,
        currentDescription: string,
        currentPreviewImage: string,
        currentAdmins: IUserInGroup[],
        currentViewers: IUserInGroup[],
        currentOwner: string,
        currentInventory?: { postcode?: string; address?: string; location?: string },
    ) {
        setScenarioDialog(undefined);

        if (scenarioDialog) {
            updateScenarioOverview({
                ...scenarioDialog,
                title: currentTitle,
                description: currentDescription,
                previewImage: currentPreviewImage,
                admins: currentAdmins.map((group) => group.id),
                viewers: currentViewers.map((group) => group.id),
                owner: currentOwner,
                date: new Date(),
                type: currentInventory ? EScenarioTypes.INVENTORY : EScenarioTypes.DEFAULT,
                inventory: currentInventory,
            });
        }
    }

    function handleDeleteScenario(id: string, accessId: string[]) {
        if (id) {
            accessId.forEach((element: string) => {
                deleteScenarioOverview({ scenarioId: id, accessId: element });
            });
        }
    }

    function newScenario() {
        dispatch(
            setCurrentSidePanel({
                currentTab: undefined,
                width: 0,
                option: undefined,
            }),
        );
        setScenarioDialog(blankScenarioOverview(userId));
    }

    let filters = [
        {
            title: "All Scenarios",
            icon: (
                <AppsRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("All Scenarios");
            },
        },
        {
            title: "Your Scenarios",
            icon: (
                <PersonRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            disabled: false,
            handleClick: () => {
                setFilterValue("Your Scenarios");
            },
        },
        {
            title: "Shared With",
            disabled: false,
            icon: (
                <PeopleRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            handleClick: () => {
                setFilterValue("Shared With");
            },
        },
        {
            title: "New Scenario",
            disabled: false,
            icon: (
                <AddRoundedIcon
                    style={{
                        fontSize: "1.7em",
                    }}
                />
            ),
            handleClick: () => {
                newScenario();
            },
        },
    ];

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            <Grid xs={12} container sx={{ overflowY: "auto", overflowX: "hidden", height: "100%" }}>
                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home landing section; search, filters and new scenario button */}
                <VTLandingOptions
                    reactiveWidth={1000}
                    filters={filters}
                    filterValue={filterValue}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    sideDrawWidth={currentSidePanel.width}
                />

                {/*-----------------------------------------------------------------------------------------*/}
                {/* Home side panel; page routes, preview image list */}
                <HomeCardGridContainer
                    data={
                        filterValue === "Your Scenarios"
                            ? data?.filter(
                                  //@ts-ignore
                                  (element: IScenario) => element.owner === userId,
                              )
                            : filterValue === "Shared With"
                            ? data?.filter(
                                  //@ts-ignore
                                  (element: IScenario) => element.owner !== userId,
                              )
                            : //@ts-ignore
                              data
                    }
                    isLoading={isLoading || scenarioUpdate}
                    error={error}
                    leftDrawWidth={currentSidePanel.width}
                    searchValue={searchValue}
                    setScenarioDialog={setScenarioDialog}
                    handleDeleteScenario={handleDeleteScenario}
                />
            </Grid>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Home side panel; page routes, preview image list */}
            <HomeSidePanelContainer
                images={images.data}
                scenarioDialog={scenarioDialog}
                setCurrentPreviewImage={setCurrentPreviewImage}
            />
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Scenario details / new scenario dialog */}
            <HomeScenarioDialog
                scenarioDialog={scenarioDialog}
                setScenarioDialog={setScenarioDialog}
                groups={groups.data}
                handleSaveScenario={handleSaveScenario}
                currentPreviewImage={currentPreviewImage}
                setCurrentPreviewImage={setCurrentPreviewImage}
            />
        </>
    );
}
