import HomeContentWrapper from "../layouts/HomeContentWrapper";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { setCurrentPage } from "../../store/slices/current.slice";

// Only display layouts in pages
export default function Home() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("home"));
    }, []);

    return (
        <>
            {/* Page content */}
            <HomeContentWrapper />
        </>
    );
}
