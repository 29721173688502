//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useState } from "react";

// Material ui Imports
import { Grid, ImageList, ImageListItem, SxProps, Tooltip, Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { VTButton, VTTextField } from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview, RadioButtonCheckedRounded } from "@mui/icons-material";
import { EDragContentOptions } from "../../models/hotspotDrag.models";

// Media imports
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { EHotspotType } from "../../models/hotspot.models";
import { IDragOptions } from "../../models/current.models";
import { useAppSelector } from "../../store/hooks";
import { ReactComponent as Movement } from "../components/movement.svg";
import SelectAllRoundedIcon from "@mui/icons-material/SelectAllRounded";
// const [searchValue, setSearchValue] = useState<string>("");

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDragContentOptions, src: string, preview?: string) => void;
    handleDragStop?: () => void;
    handleCanvasDrop: (Drag: IDragOptions) => void;
    handleQuestionCanvasDrop: (Drag: IDragOptions, hotspotId?: string) => void;
    videoCurrentState: "Paused" | "Playing" | "Question" | undefined;
}

export interface EDragOptions {}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTHotspotSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    sx,
    handleCanvasDrop,
    handleQuestionCanvasDrop,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentQuestion } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    function handleButtonClick(buttonIndex: number) {
        setActiveButton(buttonIndex);
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDragContentOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        console.log("BDGG: dragged", dragged, mediaType, src);
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string) {
        if (videoCurrentState === "Question") {
            handleQuestionCanvasDrop(
                {
                    mediaType: mediaType,
                    src: src,
                },
                currentQuestion.id,
            );
        } else {
            handleCanvasDrop({
                mediaType: mediaType,
                src: src,
            });
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images

    return (
        <Grid container xs={12} justifyContent={"center"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Hotspots"}
                    sx={{
                        height: "60px",

                        borderRadius: "10px",

                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    height: "80px",
                }}
            >
                <VTButton
                    onClick={() => handleButtonClick(0)}
                    sx={{
                        borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                        maxWidth: "100%",
                    }}
                    type="underline"
                >
                    <Typography>All Results</Typography>
                </VTButton>

                <VTButton
                    onClick={() => handleButtonClick(1)}
                    sx={{
                        borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                        maxWidth: "100%",
                    }}
                    disabled
                    type="underline"
                >
                    <Typography>Recently Used</Typography>
                </VTButton>
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Image list */}
            <Grid
                item
                container
                xs={12}
                sx={{
                    marginTop: "20px",
                    padding: "20px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "calc(100vh - 300px)",
                    width: "100%",
                }}
                alignContent={"flex-start"}
            >
                {"information".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Information hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/icons/Information.svg`}
                                    style={{ width: "70%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(
                                            true,
                                            EDragContentOptions.INFORMATION,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                            `${process.env.PUBLIC_URL}/icons/info.png`,
                                        )
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() =>
                                        handleDoubleClick(
                                            EDragContentOptions.INFORMATION,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                        )
                                    }
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Information</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"movement".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Movement hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="movement"
                                    src={`${process.env.PUBLIC_URL}/icons/movement.svg`}
                                    style={{ width: "100%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(
                                            true,
                                            EDragContentOptions.MOVEMENT,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                            `${process.env.PUBLIC_URL}/icons/circles.png`,
                                        )
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() =>
                                        handleDoubleClick(
                                            EDragContentOptions.MOVEMENT,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                        )
                                    }
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Movement</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"invisible".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Invisible hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/invisible.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(true, EDragContentOptions.ANSWER, `invisible`, ``)
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.ANSWER, `invisible`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Invisible Answer</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"answer".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Answer hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/answer.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() => localHandleDrag(true, EDragContentOptions.ANSWER, `blank`, ``)}
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.ANSWER, `blank`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Blank Answer</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"correct answer".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Correct answer hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="correct answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/correct.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() => localHandleDrag(true, EDragContentOptions.ANSWER, `correct`, ``)}
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.ANSWER, `correct`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Correct Answer</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"incorrect answer".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Incorrect answer hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="incorrect answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/Wrong.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(true, EDragContentOptions.ANSWER, `incorrect`, ``)
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.ANSWER, `incorrect`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Incorrect Answer</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"complete scenario".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Complete Scenario hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="incorrect answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/check_circle.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(true, EDragContentOptions.COMPLETE, `incorrect`, ``)
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.COMPLETE, `incorrect`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Complete Scenario</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {videoCurrentState === "Question" && "proceed video".includes(searchValue.toLocaleLowerCase()) && (
                    <Tooltip title={"Proceed Video hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="incorrect answer hotspot"
                                    src={`${process.env.PUBLIC_URL}/icons/arrow_right.svg`}
                                    style={{ width: "80%", cursor: "pointer" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(true, EDragContentOptions.PROCEED, `incorrect`, ``)
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() => handleDoubleClick(EDragContentOptions.PROCEED, `incorrect`)}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>Proceed Video</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}

                {"user interface".includes(searchValue.toLocaleLowerCase()) &&
                    (organisation === "virtus-tech" || organisation === "cardiff-uni") && (
                        <Tooltip title={"User Interface"}>
                            <Grid item xs={6} container sx={{ padding: "10px" }}>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                >
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/NoFilesImage.svg`}
                                        style={{ width: "80%", cursor: "pointer" }}
                                        draggable={true}
                                        onDragStart={() =>
                                            localHandleDrag(
                                                true,
                                                EDragContentOptions.INTERFACE,
                                                `${process.env.PUBLIC_URL}/icons/File.svg`,
                                                `${process.env.PUBLIC_URL}/icons/File.png`,
                                            )
                                        }
                                        onDragEnd={() => localHandleDrag(false)}
                                        onDoubleClick={() =>
                                            handleDoubleClick(
                                                EDragContentOptions.INTERFACE,
                                                `${process.env.PUBLIC_URL}/icons/File.svg`,
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "flex-end",
                                        marginTop: "10px",
                                    }}
                                >
                                    <Typography>Answer Interface</Typography>
                                </Grid>
                            </Grid>
                        </Tooltip>
                    )}

                {"movement".includes(searchValue.toLocaleLowerCase()) && organisation === "virtus-tech" && (
                    <Tooltip title={"Movement hotspot"}>
                        <Grid item xs={6} container sx={{ padding: "10px" }}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt="movement"
                                    src={`${process.env.PUBLIC_URL}/icons/Movement_3D.png`}
                                    style={{ width: "100%", cursor: "pointer", borderRadius: "15px" }}
                                    draggable={true}
                                    onDragStart={() =>
                                        localHandleDrag(
                                            true,
                                            EDragContentOptions.MOVEMENT_3D,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                            `${process.env.PUBLIC_URL}/icons/circles.png`,
                                        )
                                    }
                                    onDragEnd={() => localHandleDrag(false)}
                                    onDoubleClick={() =>
                                        handleDoubleClick(
                                            EDragContentOptions.MOVEMENT_3D,
                                            `${process.env.PUBLIC_URL}/icons/info.svg`,
                                        )
                                    }
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-end",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography>3D Movement</Typography>
                            </Grid>
                        </Grid>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
}
