import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { IUser, IUserInGroup } from "../../models/user.model";
import { setgroups } from "process";

interface ISelectAllTransferListProps {
    groups: IUserInGroup[];
    currentAdmins: IUserInGroup[];
    setCurrentAdmins: any;
}

export default function SelectAllTransferList({
    groups,
    currentAdmins,
    setCurrentAdmins,
}: ISelectAllTransferListProps) {
    useEffect(() => {
        const tempAdminIdArray: string[] = [...currentAdmins.map((element: IUserInGroup) => element.id)];
        setGroupsList(groups.filter((element: IUserInGroup) => !tempAdminIdArray.includes(element.id)));
    }, [groups, currentAdmins]);
    function not(a: readonly IUserInGroup[], b: readonly IUserInGroup[]) {
        return a.filter((value) => !b.includes(value));
    }
    function intersection(a: readonly IUserInGroup[], b: readonly IUserInGroup[]) {
        return a.filter((value) => b.includes(value));
    }

    function union(a: readonly IUserInGroup[], b: readonly IUserInGroup[]) {
        return [...a, ...not(b, a)];
    }

    const [checked, setChecked] = useState<IUserInGroup[]>([]);
    const [groupsList, setGroupsList] = useState<IUserInGroup[]>([]);

    const leftChecked = intersection(checked, groupsList);
    const rightChecked = intersection(checked, currentAdmins);

    const handleToggle = (value: IUserInGroup) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly IUserInGroup[]) => intersection(checked, items).length;

    const handleToggleAll = (items: readonly IUserInGroup[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setCurrentAdmins(currentAdmins.concat(leftChecked));
        setGroupsList(not(groupsList, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setGroupsList(groupsList.concat(rightChecked));
        setCurrentAdmins(not(currentAdmins, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title: React.ReactNode, items: IUserInGroup[]) => (
        <Card>
            <CardHeader
                sx={{ px: 12, py: 1, color: "red" }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "all items selected",
                        }}
                        sx={{ color: "white" }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 400,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component="div"
                role="list"
            >
                {items.map((value: any) => {
                    const labelId = `transfer-list-all-item-${value.id}-label`;

                    return (
                        <ListItemButton key={value.id} role="listitem" onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        "aria-labelledby": labelId,
                                    }}
                                    sx={{ color: "white" }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.title} />
                        </ListItemButton>
                    );
                })}
            </List>
        </Card>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{customList("Available Groups", not(groupsList, currentAdmins))}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 1, borderWidth: "3px", fontSize: "2em" }}
                        variant="outlined"
                        size="medium"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 1, borderWidth: "3px", fontSize: "2em" }}
                        variant="outlined"
                        size="medium"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                </Grid>
            </Grid>
            <Grid item>{customList("Selected Groups", currentAdmins)}</Grid>
        </Grid>
    );
}
