//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports

// Material ui Imports

// Custom components imports (and hooks and helpers)
import { VTButton, VTSidePanel } from "@virtus-tech-repository/virtus-tech-repository";
import HomeRouteSidePanel from "../components/HomeRouteSidePanel";
import { IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { IScenarioOverview } from "../../models/scenario.models";
import VTPanel from "../components/VTPanel";
import { ICurrentSidePanel, IDragOptions } from "../../models/current.models";
// Media imports
import MenuIcon from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import VTImageSidePanel from "../components/VTImageSidePanel";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Paper, Tooltip } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    images: IMediaItem[] | undefined;
    scenarioDialog: IScenarioOverview | undefined;
    setCurrentPreviewImage: (currentPreviewImage: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeSidePanelContainer({ images, scenarioDialog, setCurrentPreviewImage }: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    function handleImageDoubleClick(Drag: IDragOptions) {
        setCurrentPreviewImage(Drag.src);
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // call relevant data from api and get from store
    return (
        <>
            {/*-------------------------------------------------------------------------------------*/}
            {/* Image side panel; for adding preview images to the scenario dialog */}

            <Paper
                sx={{
                    zIndex: 1330,
                    position: "absolute",
                    top: "68px",
                    left: currentSidePanel.width ? 0 : scenarioDialog ? -400 : -200,
                    bottom: 0,
                    borderRadius: 0,
                    width: scenarioDialog ? 400 : 200,
                    transition: "0.5s",
                    backgroundColor: "#303030",
                }}
            >
                {currentSidePanel.width === 0 ? (
                    <VTButton
                        type={"icon"}
                        toolTipOptions={{
                            toolTipPlacement: "right",
                            toolTipText: "Expand",
                        }}
                        sx={{
                            position: "absolute",
                            top: "5px",
                            left: scenarioDialog ? 400 : 200,
                            width: "30px",
                            height: "30px",
                            borderRadius: "0px 15px 15px 0px",
                            zIndex: 10,
                            backgroundColor: "#444",
                            fontSize: "0.3em",
                            fontWeight: "bold",
                            ":hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() =>
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: scenarioDialog ? "Images" : "Routes",
                                    width: scenarioDialog ? 400 : 200,
                                    option: undefined,
                                }),
                            )
                        }
                    >
                        <KeyboardArrowRightRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                    </VTButton>
                ) : (
                    <VTButton
                        type={"icon"}
                        toolTipOptions={{
                            toolTipPlacement: "top-end",
                            toolTipText: "Collapse",
                        }}
                        sx={{
                            position: "absolute",
                            left: currentSidePanel.width,
                            top: "5.5px",
                            width: "30px",
                            height: "30px",
                            borderRadius: "0px 15px 15px 0px",
                            zIndex: 10,
                            backgroundColor: "#444",
                            fontSize: "0.3em",
                            ":hover": {
                                cursor: "pointer",
                            },
                        }}
                        onClick={() =>
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    width: 0,
                                    option: undefined,
                                }),
                            )
                        }
                    >
                        <CloseRoundedIcon sx={{ fontSize: "3.5em", marginLeft: "-4px" }} />
                    </VTButton>
                )}
                {scenarioDialog ? (
                    <VTImageSidePanel
                        sideDrawWidth={currentSidePanel.width}
                        imagesTitle={currentSidePanel.option ? currentSidePanel.option : ""}
                        handleCanvasDrop={handleImageDoubleClick}
                        handleQuestionCanvasDrop={handleImageDoubleClick}
                    />
                ) : (
                    <HomeRouteSidePanel />
                )}
            </Paper>
        </>
    );
}
