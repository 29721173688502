//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
import { CircularProgress, Grid, LinearProgress, Paper, Skeleton, SxProps, Tooltip, Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { v4 as uuid } from "uuid";

import { useEffect, useRef, useState } from "react";
import {
    VTButton,
    VTImmersiveImageSegment,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import VTImmersiveImageDraggable from "./VTImmersiveImageDraggable";
import { IDragOptions } from "../../models/current.models";
import {
    useCreatreAllImmersiveImagesMutation,
    useDeleteMediaMutation,
    useGetAll360ImagesQuery,
    usePreSignedUrlMutation,
    useUpdateAllImagesMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";

import NoScenarios from "../../assets/images/NoScenarios.svg";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import { CancelRounded } from "@mui/icons-material";
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sideDrawWidth: number;
    immersiveImagesTitle?: string | undefined;
    sx?: SxProps;
    handleDragStart?: (mediaType: EDragContentOptions, src: string) => void;
    handleDragStop?: () => void;
    handleTimelineDrop: (Drag: IDragOptions) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveImageSidePanel({
    sideDrawWidth,
    handleDragStart,
    handleDragStop,
    immersiveImagesTitle,
    handleTimelineDrop,
    sx,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const [creatreAllImmersiveImages] = useCreatreAllImmersiveImagesMutation();
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [selectedImageError, setSelectedImageError] = useState<boolean>(false);
    const [imageName, setImageName] = useState<string>("");
    const [imageId, setImageId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const dispatch = useAppDispatch();

    const { data: images, isLoading: imagesLoading } = useGetAll360ImagesQuery(userId);
    const { data: scenarioImages, isLoading: scenarioImagesLoading } = useGetAll360ImagesQuery(scenarioId!);

    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedImage) {
            creatreAllImmersiveImages({
                id: userId,
                media_id: imageId,
                media_type: "three_sixty_image",
                filetype: "jpg",
                name: imageName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: "",
                chopping: true,
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    // Handle the drag hooks
    function localHandleDrag(dragged: boolean, mediaType?: EDragContentOptions, src?: string) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string, preview?: string) {
        handleTimelineDrop({
            mediaType: mediaType,
            src: src,
            preview: preview,
        });
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(imageFile: File) {
        // TODO: in the future only allow certain image types
        if (imageFile.type.includes("image/") && !imageFile.type.includes("/svg")) {
            setSelectedImageError(false);
            setSelectedImage(imageFile);
            setImageName(imageFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "three_sixty_image",
                media_id: `${imageId}/raw`,
                filetype: "jpg",
            });
        } else {
            setSelectedImageError(true);
        }
    }

    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    // Function that handle image added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    function handleImageUpload() {
        if (uploadUrl && selectedImage) {
            dispatch(
                uploadMedia({
                    media: selectedImage,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedImage(null);
        setImageId(uuid());
        setImageName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available images

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    return (
        <Grid
            container
            xs={12}
            justifyContent={"center"}
            alignContent={"flex-start"}
            sx={{ overflowY: "hidden", userSelect: "none", ...sx }}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Images search */}
            <Grid item xs={11}>
                <VTTextField
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(text: string) => {
                        setSearchValue(text);
                    }}
                    placeholder={"Search 360 Images"}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    height: "80px",
                    overflowX: "auto",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Results</Typography>
                    </VTButton>
                </Grid>
                <Grid item xs={4}>
                    {scenarioImages && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? "3px solid rgb(150, 28, 255)" : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography>Scenario Images</Typography>
                        </VTButton>
                    )}
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Images</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {activeButton === 1 && (
                <>
                    <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                        {selectedImage ? (
                            <Grid container>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTTextField
                                        value={imageName}
                                        onChange={(value) => setImageName(value)}
                                        disabled={mediaUploading || mediaUploadCompleted}
                                    />
                                </Grid>
                                {/* <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTImageChopper src={URL.createObjectURL(selectedImage)} />
                                </Grid> */}
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        alt="Selected"
                                        width="100%"
                                        height="250px"
                                        style={{
                                            borderRadius: "15px",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Grid>
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Image
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedImage(null);
                                                                        setImageId(uuid());
                                                                        setImageName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                >
                                                                    Upload Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedImage(null)}
                                                        fullWidth={true}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton onClick={() => handleImageUpload()} fullWidth={true}>
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                //TODO: user this as an overlay
                                onDragEnter={() => setHighlight(true)}
                                onDragLeave={() => setHighlight(false)}
                                sx={{
                                    border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                    borderRadius: "15px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography align="center">Drop or choose image to upload</Typography>

                                    {selectedImageError && (
                                        <Typography align="center" sx={{ color: "red" }}>
                                            File types accepted: JPG, JPEG, PNG
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                    <VTButton
                                        onClick={() => {
                                            if (inputFileRef && inputFileRef.current) {
                                                inputFileRef.current.click();
                                            }
                                        }}
                                    >
                                        Choose File
                                    </VTButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </>
            )}
            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}

                    {imagesLoading ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!images || images.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoScenarios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",

                                        padding: "20px",
                                        height: "calc(100vh - 300px)",
                                    }}
                                >
                                    {images?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        images
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: any) => (
                                                <>
                                                    {item.chopping === "processing" || item.chopping === "error" ? (
                                                        <Tooltip
                                                            title={
                                                                item.chopping === "processing"
                                                                    ? "The image is being processed, please wait and refresh your page"
                                                                    : "There has been an error processing this image, please try again"
                                                            }
                                                        >
                                                            <Grid
                                                                key={item.id}
                                                                item
                                                                container
                                                                justifyContent={"center"}
                                                                alignContent={"center"}
                                                                sx={{
                                                                    width: "100%",
                                                                    backgroundColor: "#343537",
                                                                    borderRadius: "15px",
                                                                    height: "100px",
                                                                    position: "relative",
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sx={{
                                                                        position: "absolute",
                                                                        left: "0px",
                                                                        right: "0px",
                                                                        borderRadius: "15px 15px 0px 0px",
                                                                        userSelect: "none",
                                                                        height: "35px",
                                                                        backgroundColor: "rgba(22,22,22,0.8)",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            marginLeft: "5px",
                                                                            marginTop: "5px",
                                                                        }}
                                                                    >
                                                                        {item && item.name && item.name}
                                                                    </Typography>
                                                                </Grid>
                                                                {item.chopping === "processing" ? (
                                                                    <CircularProgress sx={{ marginTop: "20px" }} />
                                                                ) : (
                                                                    <CancelRounded sx={{ marginTop: "20px" }} />
                                                                )}
                                                            </Grid>
                                                        </Tooltip>
                                                    ) : (
                                                        <VTImmersiveImageDraggable
                                                            item={item}
                                                            organisation={organisation}
                                                            localHandleDrag={localHandleDrag}
                                                            handleDoubleClick={handleDoubleClick}
                                                        />
                                                    )}
                                                </>
                                            ))
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Image list */}
                    {scenarioImagesLoading ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioImages || scenarioImages.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoScenarios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first image file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        padding: "20px",
                                        height: "calc(100vh - 300px)",
                                    }}
                                >
                                    {scenarioImages?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        scenarioImages
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: any) => (
                                                <>
                                                    {(updateLoading || deleteLoading) &&
                                                    currentItemUpdate == item.id ? (
                                                        <Grid item xs={12}>
                                                            <Skeleton
                                                                sx={{
                                                                    height: "150px",
                                                                    margin: "10px",
                                                                    marginBlock: "-25px",
                                                                }}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            {item.chopping ? (
                                                                <Tooltip
                                                                    title={
                                                                        "The image is being processed, please wait and refresh your page"
                                                                    }
                                                                >
                                                                    <Grid
                                                                        key={item.id}
                                                                        item
                                                                        container
                                                                        justifyContent={"center"}
                                                                        alignContent={"center"}
                                                                        sx={{
                                                                            width: "100%",
                                                                            backgroundColor: "#343537",
                                                                            borderRadius: "15px",
                                                                            height: "100px",
                                                                            position: "relative",
                                                                        }}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            sx={{
                                                                                position: "absolute",
                                                                                left: "0px",
                                                                                right: "0px",
                                                                                borderRadius: "15px 15px 0px 0px",
                                                                                userSelect: "none",
                                                                                height: "35px",
                                                                                backgroundColor: "rgba(22,22,22,0.8)",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    marginLeft: "5px",
                                                                                    marginTop: "5px",
                                                                                }}
                                                                            >
                                                                                {item && item.name && item.name}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <CircularProgress sx={{ marginTop: "20px" }} />
                                                                    </Grid>
                                                                </Tooltip>
                                                            ) : (
                                                                <VTImmersiveImageDraggable
                                                                    item={item}
                                                                    organisation={organisation}
                                                                    localHandleDrag={localHandleDrag}
                                                                    handleDoubleClick={handleDoubleClick}
                                                                    addFunction={() => {
                                                                        setCurrentItemUpdate(item.id);
                                                                        updateAllScenarioMedia({
                                                                            id: userId,
                                                                            media_type: EMedia.IMAGE_360,
                                                                            media_ids: [item.id],
                                                                        });
                                                                    }}
                                                                    deleteFunction={() => {
                                                                        setCurrentItemUpdate(item.id);
                                                                        deleteAllScenarioMedia({
                                                                            mediaType: EMedia.IMAGE_360,
                                                                            collectionId: scenarioId!,
                                                                            mediaId: item.id,
                                                                        });
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ))
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
