//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import ReactAudioPlayer from "react-audio-player";

// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    List,
    Paper,
    Skeleton,
    SxProps,
    Typography,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { VTButton, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { VTAudioItem } from "./VTAudioItem";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllAudioMutation,
    useDeleteMediaMutation,
    useGetAllAudioQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { IDragOptions } from "../../models/current.models";
import NoAudios from "../../assets/images/NoAudios.svg";
import { Params, useParams } from "react-router-dom";

import SearchNotFound from "../../assets/images/NoAudios.svg";
import VTAudioRecord from "./VTAudioRecord";
import { moveCursorToEnd } from "../../utils/moveCursorToEndRichTextEditor";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    onHandleDragStart?: (mediaType: EDragContentOptions, src: string) => void;
    onHandleDragStop?: () => void;
    handleCanvasDrop?: (Drag: IDragOptions) => void;
    handleQuestionCanvasDrop?: (Drag: IDragOptions, hotspotId?: string) => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export function VTAudioSidePanel({
    sx,
    onHandleDragStart,
    onHandleDragStop,
    handleCanvasDrop,
    handleQuestionCanvasDrop,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const [createAllAudio] = useCreateAllAudioMutation();

    const [selectedAudio, setSelectedAudio] = useState<File | null>(null);
    const [selectedAudioError, setSelectedAudioError] = useState<boolean>(false);
    const [audioName, setAudioName] = useState<string>("");
    const [audioId, setAudioId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const dispatch = useAppDispatch();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentQuestion, editHotspotDialogIsCurrentlyOpen } = useAppSelector((state) => state.currentReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const { data: audios, isLoading: audiosLoading } = useGetAllAudioQuery(userId);
    const { data: scenarioAudios, isLoading: scenarioAudiosLoading } = useGetAllAudioQuery(scenarioId!);
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);
    const [recordModelOpen, setRecordModelOpen] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedAudio) {
            createAllAudio({
                id: userId,
                media_id: audioId,
                media_type: "audio",
                filetype: selectedAudio.type.split("/")[1],
                name: audioName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);
    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(audioFile: File) {
        // TODO: in the future only allow certain image types
        if (audioFile.type.includes("audio/")) {
            setSelectedAudioError(false);
            setSelectedAudio(audioFile);
            setAudioName(audioFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "audio",
                media_id: `${audioId}`,
                filetype: audioFile.type.split("/")[1],
            });
        } else {
            setSelectedAudioError(true);
        }
    }

    // Function that handle audio added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle audio added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleAudioUpload() {
        if (uploadUrl && selectedAudio) {
            dispatch(
                uploadMedia({
                    media: selectedAudio,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedAudio(null);
        setAudioId(uuid());
        setAudioName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string) {
        // if (editHotspotDialogIsCurrentlyOpen) {
        //     onHandleDragStart && onHandleDragStart(mediaType, src);

        //     window.setTimeout(() => {
        //         moveCursorToEnd("rich-text-editor");
        //         document.execCommand("insertHTML", false, `<div id="audio-drop-img" />`);
        //         moveCursorToEnd("rich-text-editor");
        //     }, 300);

        //     // 400ms after
        //     window.setTimeout(() => {
        //         onHandleDragStop && onHandleDragStop();
        //     }, 700);
        // }

        if (handleQuestionCanvasDrop && handleCanvasDrop) {
            if (videoCurrentState === "Question") {
                handleQuestionCanvasDrop(
                    {
                        mediaType: mediaType,
                        src: src,
                    },
                    currentQuestion.id,
                );
            } else {
                handleCanvasDrop({
                    mediaType: mediaType,
                    src: src,
                });
            }
        }
    }
    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##

    return (
        <Grid
            container
            xs={12}
            sx={{ ...sx, overflowY: "hidden", userSelect: "none" }}
            justifyContent={"center"}
            alignContent={"flex-start"}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Audio search */}
            <Grid item xs={11}>
                <VTTextField
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(text: string) => {
                        setSearchValue(text);
                    }}
                    placeholder={"Search Audio"}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    height: "80px",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Results</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    {scenarioAudios && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? "3px solid rgb(150, 28, 255)" : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography>Scenario Audio</Typography>
                        </VTButton>
                    )}
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Audio </Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/* -------------------------------------------------------------------------------------- */}
            {/* Use the ImageSidePanel to help you map the audio files. Put the Audio data in the storybook.  */}
            {/* display each audio item in a paper component */}
            {/* Have the audio display title and then use npm audio player */}
            {/* Have the values inside the paper component */}
            {/* -------------------------------------------------------------------------------------- */}

            <VTAudioRecord open={recordModelOpen} handleClose={() => setRecordModelOpen(false)} />

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Audio upload */}
            {activeButton === 1 && (
                <>
                    <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                        {selectedAudio ? (
                            <Grid container>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTTextField
                                        value={audioName}
                                        onChange={(value) => setAudioName(value)}
                                        disabled={mediaUploading || mediaUploadCompleted}
                                    />
                                </Grid>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <ReactAudioPlayer
                                        src={URL.createObjectURL(selectedAudio)}
                                        autoPlay={false}
                                        controls
                                        style={{ width: "100%" }}
                                    />
                                </Grid>
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Audio
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedAudio(null);
                                                                        setAudioId(uuid());
                                                                        setAudioName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                >
                                                                    Upload Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedAudio(null)}
                                                        fullWidth={true}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton onClick={() => handleAudioUpload()} fullWidth={true}>
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                //TODO: user this as an overlay
                                onDragEnter={() => setHighlight(true)}
                                onDragLeave={() => setHighlight(false)}
                                sx={{
                                    border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                    borderRadius: "15px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography align="center">Drop or choose Audio to upload</Typography>

                                    {selectedAudioError && (
                                        <Typography align="center" sx={{ color: "red" }}>
                                            File types accepted: MP4, MP3
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "50px",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <input
                                        accept="audio/*"
                                        type="file"
                                        id="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                    <VTButton
                                        onClick={() => {
                                            if (inputFileRef && inputFileRef.current) {
                                                inputFileRef.current.click();
                                            }
                                        }}
                                    >
                                        Choose File
                                    </VTButton>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography align="center"> or</Typography>
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "10px 0px",
                                    }}
                                >
                                    <VTButton
                                        onClick={() => setRecordModelOpen(true)}
                                        disabled={recordModelOpen}
                                        color={"secondary"}
                                    >
                                        <Typography>Record Audio </Typography>
                                    </VTButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </>
            )}

            {activeButton === 0 && (
                <>
                    {audiosLoading ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>

                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!audios || audios.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoAudios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first audio file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    sx={{
                                        marginTop: "20px",
                                        padding: "10px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                    item
                                    container
                                    alignContent={"flex-start"}
                                >
                                    {audios
                                        ?.filter(
                                            (item: IMediaItem) =>
                                                searchValue === "" ||
                                                item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                item
                                                    .description!.toLowerCase()
                                                    .includes(searchValue.toLocaleLowerCase()),
                                        )
                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                        }).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        audios
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: IMediaItem, index: number) => (
                                                // @ts-ignore
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        margin: "15px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <VTAudioItem
                                                        // sx={{width: "100%"}}
                                                        // sx={{border: "2px solid lime"}
                                                        onHandleDragStart={onHandleDragStart}
                                                        onHandleDragStop={onHandleDragStop}
                                                        audioItemTitle={item.name}
                                                        audioItemSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                                        handleDoubleClick={handleDoubleClick}
                                                    />
                                                </Grid>
                                            ))
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {scenarioAudiosLoading ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>

                            <Grid item xs={12}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioAudios || scenarioAudios.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoAudios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first audio file to the scenario
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    sx={{
                                        marginTop: "20px",
                                        padding: "10px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                    item
                                    container
                                    alignContent={"flex-start"}
                                >
                                    {/* @ts-ignore */}
                                    {scenarioAudios
                                        ?.filter(
                                            (item: IMediaItem) =>
                                                searchValue === "" ||
                                                item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                item
                                                    .description!.toLowerCase()
                                                    .includes(searchValue.toLocaleLowerCase()),
                                        )
                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                        }).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        scenarioAudios
                                            ?.filter(
                                                (item: IMediaItem) =>
                                                    searchValue === "" ||
                                                    item
                                                        .name!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()) ||
                                                    item
                                                        .description!.toLowerCase()
                                                        .includes(searchValue.toLocaleLowerCase()),
                                            )
                                            .sort((a: IMediaItem, b: IMediaItem) => {
                                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                            })
                                            .map((item: IMediaItem) => (
                                                <Grid
                                                    key={item.id}
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        margin: "15px",
                                                        position: "relative",
                                                    }}
                                                >
                                                    {(updateLoading || deleteLoading) &&
                                                    currentItemUpdate == item.id ? (
                                                        <Grid item xs={12}>
                                                            <Skeleton
                                                                sx={{
                                                                    height: "150px",
                                                                    margin: "10px",
                                                                    marginBlock: "-10px",
                                                                }}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        <VTAudioItem
                                                            // sx={{width: "100%"}}
                                                            // sx={{border: "2px solid lime"}
                                                            onHandleDragStart={onHandleDragStart}
                                                            onHandleDragStop={onHandleDragStop}
                                                            audioItemTitle={item.name}
                                                            audioItemSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                                            handleDoubleClick={handleDoubleClick}
                                                            addFunction={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                updateAllScenarioMedia({
                                                                    id: userId,
                                                                    media_type: EMedia.AUDIO,
                                                                    media_ids: [item.id],
                                                                });
                                                            }}
                                                            deleteFunction={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                deleteAllScenarioMedia({
                                                                    mediaType: EMedia.AUDIO,
                                                                    collectionId: scenarioId!,
                                                                    mediaId: item.id,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            ))
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
