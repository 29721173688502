//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { Dispatch, SetStateAction, useEffect, useState } from "react";
// Material ui Imports
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
// Custom components imports (and hooks and helpers)
import {
    VTAutoComplete,
    VTButton,
    VTDialog,
    VTHomeDialogOption,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { EScenarioTypes, IScenarioOverview } from "../../models/scenario.models";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { IGroup, IUserInGroup } from "../../models/user.model";
import HomeScenarioDialogInventoryContent from "./HomeScenarioDialogInventoryContent";
import HomeScenarioDialogDefaultContent from "./HomeScenarioDialogDefaultContent";
import { SettingsRounded, PublishRounded, PersonRounded, RemoveRounded } from "@mui/icons-material";
import { usePublishScenarioMutation } from "../../services/scenario.service";
import VTImageDropper from "./VTImageDropper";
import React from "react";
import SelectAllTransferList from "./VTSekectAllTansferList";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    scenarioDialog: IScenarioOverview | undefined;
    setScenarioDialog: Dispatch<SetStateAction<undefined | IScenarioOverview>>;
    groups: any;
    handleSaveScenario: (
        currentTitle: string,
        currentDescription: string,
        currentPreviewImage: string,
        currentAdmins: IUserInGroup[],
        currentViewers: IUserInGroup[],
        currentOwner: string,
        inventory?: { postcode?: string; address?: string; location?: string },
    ) => void;
    currentPreviewImage: string;
    setCurrentPreviewImage: (currentPreviewImage: string) => void;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
export default function HomeScenarioDialog({
    scenarioDialog,
    setScenarioDialog,
    groups,
    handleSaveScenario,
    currentPreviewImage,
    setCurrentPreviewImage,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useAppDispatch();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentSidePanel } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [currentDescription, setCurrentDescription] = useState<string>("");
    const [currentAdmins, setCurrentAdmins] = useState<IUserInGroup[]>([]);
    const [currentViewers, setCurrentViewers] = useState<IUserInGroup[]>([]);
    const [currentOwner, setCurrentOwner] = useState<string>("");

    const [publishScenario] = usePublishScenarioMutation();
    const [publishDialog, setPublishDialog] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(1);
    const [firstViewing, setFirstViewing] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (scenarioDialog) {
            setFirstViewing(false);
            if (scenarioDialog.previewImage === "" || scenarioDialog.previewImage === undefined) {
                dispatch(
                    setCurrentSidePanel({
                        currentTab: "Images",
                        width: 400,
                        option: undefined,
                    }),
                );
            }
            if (scenarioDialog.admins.length < 1) {
                setFirstViewing(true);
            }

            const adminUsers: IUserInGroup[] = scenarioDialog.admins.map((group: any) => {
                return {
                    id: group,
                    title: group.split("_")[1].replace(/-/g, " "),
                    role: "admin",
                };
            });
            const viewerUsers: IUserInGroup[] = scenarioDialog.viewers.map((group: any) => {
                return {
                    id: group,
                    title: group.split("_")[1].replace(/-/g, " "),
                    role: "viewer",
                };
            });
            setCurrentTitle(scenarioDialog.title);
            setCurrentDescription(scenarioDialog.description);
            setCurrentPreviewImage(scenarioDialog.previewImage);
            setCurrentOwner(scenarioDialog.owner);
            setCurrentAdmins(adminUsers);
            setCurrentViewers(viewerUsers);
            setActiveStep(1);
        }
    }, [scenarioDialog]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    return (
        <VTDialog
            disableEnforceFocus={true}
            title={"Scenario Overview"}
            sx={{ marginLeft: currentSidePanel.width / 8 + 5, zIndex: 5, marginTop: "70px" }}
            open={scenarioDialog !== undefined}
            handleClose={() => {
                setScenarioDialog(undefined);
                dispatch(
                    setCurrentSidePanel({
                        currentTab: "Routes",
                        width: 200,
                        option: undefined,
                    }),
                );

                setCurrentPreviewImage("");
            }}
            primaryArea={
                <Grid container justifyContent={"flex-end"} spacing={"20px"}>
                    {/*---------------------------------------------------------------------------------*/}
                    {/* Dialog cancel button */}
                    <VTButton
                        color={"secondary"}
                        sx={{
                            width: "200px",
                            height: "50px",
                            marginRight: "30px",
                        }}
                        onClick={() => {
                            setScenarioDialog(undefined);
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: "Routes",
                                    width: 200,
                                    option: undefined,
                                }),
                            );
                            setCurrentPreviewImage("");
                        }}
                    >
                        Cancel
                    </VTButton>

                    {/*---------------------------------------------------------------------------------*/}
                    {/* Save the scenario data changes */}
                    {publishDialog ? (
                        <VTButton
                            sx={{ height: "50px", width: "230px" }}
                            onClick={() => {
                                handleSaveScenario(
                                    currentTitle,
                                    currentDescription,
                                    currentPreviewImage,
                                    currentAdmins,
                                    currentViewers,
                                    currentOwner,
                                );
                                if (scenarioDialog) {
                                    publishScenario(scenarioDialog!.id);
                                }
                            }}
                        >
                            {/* @ts-ignore */}
                            {scenarioDialog && scenarioDialog.published ? "Re-Publish" : "Publish"}
                            <PublishRounded sx={{ marginLeft: "5px", marginTop: "-3px" }} />
                        </VTButton>
                    ) : (
                        <VTButton
                            sx={{
                                width: "200px",
                                height: "50px",
                                marginRight: "30px",
                                marginBottom: "20px",
                            }}
                            onClick={() => {
                                if (activeStep === 3) {
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Routes",
                                            width: 200,
                                            option: undefined,
                                        }),
                                    );

                                    handleSaveScenario(
                                        currentTitle,
                                        currentDescription,
                                        currentPreviewImage,
                                        currentAdmins,
                                        currentViewers,
                                        currentOwner,
                                    );
                                } else if (activeStep === 2 && firstViewing) {
                                    dispatch(
                                        setCurrentSidePanel({
                                            currentTab: "Routes",
                                            width: 200,
                                            option: undefined,
                                        }),
                                    );

                                    handleSaveScenario(
                                        currentTitle,
                                        currentDescription,
                                        currentPreviewImage,
                                        currentAdmins,
                                        currentViewers,
                                        currentOwner,
                                    );
                                } else {
                                    setActiveStep(activeStep + 1);
                                }
                            }}
                            disabled={currentTitle === "" || (currentAdmins.length === 0 && activeStep === 2)}
                        >
                            {activeStep === 3 || (activeStep === 2 && firstViewing) ? "Save Scenario" : "Next Step"}
                        </VTButton>
                    )}
                </Grid>
            }
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Content Wrapper */}

            <Grid item container xs={12} justifyContent={"center"}>
                <Grid container justifyContent={"center"} sx={{ marginTop: "17px" }}>
                    <Grid item>
                        <VTButton
                            type="icon"
                            onClick={() => setActiveStep(1)}
                            sx={{
                                borderRadius: "50%",
                                width: "70px",
                                height: "70px",
                                background: "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                                color: "#ffffff",
                                cursor: "pointer",
                                marginBottom: "5px",
                                transition: "0.4s",
                                ...(activeStep === 1 && { boxShadow: "0 0 15px #fff" }),
                                "&:hover": {
                                    boxShadow: "0 0 15px #fff",
                                },
                            }}
                        >
                            <SettingsRounded sx={{ fontSize: "1.7em" }} />
                        </VTButton>
                        <VTTypography align="center">Overview</VTTypography>
                    </Grid>

                    <Grid item>
                        <hr
                            style={{
                                color: "#fff",
                                backgroundColor: "#fff",
                                height: "3px",
                                width: "100px",
                                marginInline: "20px",
                                marginTop: "35px",
                                borderRadius: "40%",
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <VTButton
                            type="icon"
                            onClick={() => {
                                if (currentTitle !== "") {
                                    setActiveStep(2);
                                }
                            }}
                            sx={{
                                borderRadius: "50%",
                                width: "70px",
                                height: "70px",
                                background: "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                                color: "#ffffff",
                                cursor: "pointer",
                                marginBottom: "5px",
                                transition: "0.4s",
                                ...(activeStep === 2 && { boxShadow: "0 0 15px #fff" }),
                                "&:hover": {
                                    boxShadow: "0 0 15px #fff",
                                },
                            }}
                        >
                            <PersonRounded sx={{ fontSize: "1.7em" }} />
                        </VTButton>
                        <VTTypography align="center">Creators</VTTypography>
                    </Grid>
                    <Grid item>
                        <hr
                            style={{
                                color: "#fff",
                                backgroundColor: "#fff",
                                height: "3px",
                                width: "100px",
                                marginInline: "20px",
                                marginTop: "35px",
                                borderRadius: "40%",
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <VTButton
                            type="icon"
                            onClick={() => {
                                if (!firstViewing) {
                                    setActiveStep(3);
                                }
                            }}
                            sx={{
                                borderRadius: "50%",
                                width: "70px",
                                height: "70px",
                                background: firstViewing
                                    ? "linear-gradient(to bottom, #3aa5a7,#a955ca)"
                                    : "linear-gradient(to bottom, #0ab5a7,#8e25fa)",
                                color: "#ffffff",
                                cursor: "pointer",
                                marginBottom: "5px",
                                transition: "0.4s",
                                ...(activeStep === 3 && { boxShadow: "0 0 15px #fff" }),
                                "&:hover": {
                                    boxShadow: firstViewing ? "none " : "0 0 15px #fff",
                                },
                            }}
                        >
                            <PublishRounded sx={{ fontSize: "1.7em" }} />
                        </VTButton>
                        <VTTypography align="center" sx={{ color: firstViewing ? "#a9a9a9" : "#fff" }}>
                            Publishing
                        </VTTypography>
                    </Grid>
                </Grid>
            </Grid>
            {activeStep === 1 && (
                <Grid xs={12} container justifyContent={"center"} spacing={"5px"} sx={{ padding: "20px" }}>
                    {/*---------------------------------------------------------------------------------*/}
                    {/* Content Left */}
                    {/*---------------------------------------------------------------------------------*/}
                    <Grid xs={12} md={6} container item justifyContent={"center"}>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Maps the title */}
                        <Grid item xs={12} container justifyContent={"center"}>
                            <VTHomeDialogOption
                                value={currentTitle}
                                setValue={setCurrentTitle}
                                title={"Title"}
                                lines={2}
                            />
                        </Grid>

                        {/*-------------------------------------------------------------------------------*/}
                        {/* Maps the description  */}
                        <Grid item xs={12} container justifyContent={"center"}>
                            <VTHomeDialogOption
                                value={currentDescription}
                                setValue={setCurrentDescription}
                                title={"Description"}
                                lines={6}
                            />
                        </Grid>
                    </Grid>

                    {/*--------------------------------------------------------------------------------*/}
                    {/* Content Right */}
                    {/*--------------------------------------------------------------------------------*/}
                    <Grid xs={12} md={6} container item justifyContent={"center"} sx={{ marginTop: "10px" }}>
                        {/*-------------------------------------------------------------------------------*/}
                        {/* Maps the Admins and viewer user fields  */}

                        {/*-------------------------------------------------------------------------------*/}
                        {/* Preview image */}
                        <VTImageDropper currentImage={currentPreviewImage} setCurrentImage={setCurrentPreviewImage} />
                        <Grid
                            xs={12}
                            container
                            item
                            justifyContent={"center"}
                            alignItems={"center"}
                            marginBottom={"5px"}
                        >
                            <Typography>Drop and drag an image from the image sidebar</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {activeStep === 2 && (
                <Grid xs={12} container justifyContent={"center"} spacing={"5px"} sx={{ padding: "20px" }}>
                    <VTTypography align="center" sx={{ width: "100%", marginBlock: "20px" }}>
                        Assign Groups <span style={{ color: "#f44336" }}>*</span>
                    </VTTypography>

                    <SelectAllTransferList
                        groups={
                            groups &&
                            groups.filter((element: any) => {
                                if (element.role === "admin") {
                                    if (currentAdmins.length) {
                                        return currentAdmins.some((admin) => {
                                            return admin.id !== element.id;
                                        });
                                    } else {
                                        return element;
                                    }
                                }
                            })
                        }
                        currentAdmins={currentAdmins}
                        setCurrentAdmins={setCurrentAdmins}
                    />
                </Grid>
            )}

            {activeStep === 3 && (
                <Grid xs={12} container spacing={"5px"} sx={{ padding: "20px" }}>
                    <Grid item xs={12}>
                        <VTTypography align="center" sx={{ width: "100%", marginBlock: "20px" }}>
                            Assign Viewer groups <span style={{ color: "#f44336" }}>*</span>
                        </VTTypography>

                        <SelectAllTransferList
                            groups={
                                groups &&
                                groups.filter((element: any) => {
                                    if (element.role === "viewer") {
                                        if (currentViewers.length) {
                                            return currentViewers.some((viewer) => {
                                                return viewer.id !== element.id;
                                            });
                                        } else {
                                            return element;
                                        }
                                    }
                                })
                            }
                            currentAdmins={currentViewers}
                            setCurrentAdmins={setCurrentViewers}
                        />
                    </Grid>
                    <Grid item sx={{ marginBlock: "20px" }} xs={12} container justifyContent={"center"}>
                        <VTButton
                            sx={{ height: "50px", width: "230px", marginRight: "25px" }}
                            onClick={() => {
                                publishScenario(scenarioDialog!.id);
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: "Routes",
                                        width: 200,
                                        option: undefined,
                                    }),
                                );

                                handleSaveScenario(
                                    currentTitle,
                                    currentDescription,
                                    currentPreviewImage,
                                    currentAdmins,
                                    currentViewers,
                                    currentOwner,
                                );
                            }}
                        >
                            {/* @ts-ignore */}
                            {scenarioDialog && scenarioDialog.published ? "Re-Publish" : "Publish"}
                            <PublishRounded sx={{ marginLeft: "5px", marginTop: "-5px" }} />
                        </VTButton>
                    </Grid>
                </Grid>
            )}
        </VTDialog>
    );
}
