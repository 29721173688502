//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useEffect } from "react";
import AppRoutes from "./views/AppRoutes";
import { darkTheme } from "./themes/dark.theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { lightTheme } from "./themes/light.theme";
import { useAppSelector } from "./store/hooks";
import { SelectedTheme } from "./store/slices/theme.slice";
import ReactGA from "react-ga4";
// AWS Amplify imports

// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// DigiDeck, hosting set theme ccs and routes
function DigiDeck() {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { theme } = useAppSelector((state) => state.themeReducer);

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##

    useEffect(() => {
        console.log("setting up ga *************");
        ReactGA.initialize([
            {
                trackingId: "G-5YHHTR9NKB",
                gaOptions: { testMode: true },
            },
        ]);
    }, []);
    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Gets the user details from the login and stores in redux
    return (
        <ThemeProvider theme={theme === SelectedTheme.DARK ? darkTheme : lightTheme}>
            <CssBaseline />
            <AppRoutes />
        </ThemeProvider>
    );
}

// Export app
export default DigiDeck;
