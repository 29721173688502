//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
// Material ui Imports
import {
    CircularProgress,
    Grid,
    ImageList,
    ImageListItem,
    LinearProgress,
    Skeleton,
    SxProps,
    Tooltip,
    Typography,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NoVideos from "../../assets/images/NoVideos.svg";
// Custom components imports (and hooks and helpers)
import { VTButton, VTTextField, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";
import { EMedia, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import { Preview } from "@mui/icons-material";
import { EDragContentOptions } from "../../models/hotspotDrag.models";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllVideosMutation,
    useDeleteMediaMutation,
    useGetAllVideos2dQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import VTVideoDraggable from "./VTVideoDraggable";
import { IDragOptions } from "../../models/current.models";
import { Params, useParams } from "react-router-dom";
import SearchNotFound from "../../assets/images/NoVideos.svg";
import { moveCursorToEnd } from "../../utils/moveCursorToEndRichTextEditor";
// Media imports

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components
//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    handleDragStart: any;
    handleDragStop: () => void;
    handleCanvasDrop?: (Drag: IDragOptions) => void;
    handleQuestionCanvasDrop?: (Drag: IDragOptions, hotspotId?: string) => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTImmersiveVideoSidePanel({
    handleDragStart,
    handleDragStop,
    sx,
    handleCanvasDrop,
    handleQuestionCanvasDrop,
    videoCurrentState,
}: any) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const [createAllVideos] = useCreateAllVideosMutation();

    const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
    const [selectedVideoError, setSelectedVideoError] = useState<boolean>(false);
    const [videoName, setVideoName] = useState<string>("");
    const [videoId, setVideoId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const { data: videos, isLoading: videosLoading } = useGetAllVideos2dQuery(userId);
    const { data: scenarioVideos, isLoading: scenarioVideosLoading } = useGetAllVideos2dQuery(scenarioId!);

    const dispatch = useAppDispatch();
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    const { currentQuestion, editHotspotDialogIsCurrentlyOpen } = useAppSelector((state) => state.currentReducer);
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedVideo) {
            createAllVideos({
                id: userId,
                media_id: videoId,
                media_type: "video",
                filetype: selectedVideo.type.split("/")[1],
                name: videoName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    //-------------------------------------------------------------------------------------------------
    // Function to get src and size images based on size
    function srcset(image: string, size: number, rows = 1, cols = 1) {
        return {
            src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
            srcSet: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format&dpr=2 2x`,
        };
    }

    //-------------------------------------------------------------------------------------------------
    // Function to assign image widths
    function colSizeSwitch(param: number) {
        switch (param) {
            case 0:
                return 2;
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 2;
            case 4:
                return 2;
            case 5:
                return 2;
            case 6:
                return 1;
            case 7:
                return 1;
            case 8:
                return 2;
        }
    }

    //-------------------------------------------------------------------------------------------------
    // Function to assign image heights
    function rowSizeSwitch(param: number) {
        switch (param) {
            case 0:
                return 2;
            case 1:
                return 1;
            case 2:
                return 1;
            case 3:
                return 1;
            case 4:
                return 1;
            case 5:
                return 2;
            case 6:
                return 1;
            case 7:
                return 1;
            case 8:
                return 1;
        }
    }

    function localHandleDrag(
        dragged: boolean,
        mediaType?: EDragContentOptions,
        src?: string,
        preview?: string | undefined,
    ) {
        if (dragged && handleDragStart && mediaType && src) {
            handleDragStart(mediaType, src, preview);
        } else if (!dragged && handleDragStop) {
            handleDragStop();
        }
    }

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleFileAdded(videoFile: File) {
        // TODO: in the future only allow certain video types
        if (videoFile.type.includes("video/")) {
            setSelectedVideoError(false);
            setSelectedVideo(videoFile);
            setVideoName(videoFile.name.split(".")[0]);

            // Get presigned url to upload video
            await preSignedUrl({
                organisation,
                media_type: "video",
                media_id: `${videoId}`,
                filetype: videoFile.type.split("/")[1],
            });
        } else {
            setSelectedVideoError(true);
        }
    }

    // Function that handle video added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle video added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleVideoUpload() {
        if (uploadUrl && selectedVideo) {
            dispatch(
                uploadMedia({
                    media: selectedVideo,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedVideo(null);
        setVideoId(uuid());
        setVideoName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDragContentOptions, src: string) {
        // console.log("handle double click");

        // console.log("media type", mediaType);
        // console.log("src", src);

        if (editHotspotDialogIsCurrentlyOpen) {
            handleDragStart(mediaType, src);

            window.setTimeout(() => {
                moveCursorToEnd("rich-text-editor");
                document.execCommand("insertHTML", false, `<video id="video-drop-img" />`);
                moveCursorToEnd("rich-text-editor");
            }, 300);

            // 400ms after
            window.setTimeout(() => {
                handleDragStop();
            }, 2000);
        }

        if (handleQuestionCanvasDrop && handleCanvasDrop) {
            if (videoCurrentState === "Question") {
                handleQuestionCanvasDrop(
                    {
                        mediaType: mediaType,
                        src: src,
                    },
                    currentQuestion.id,
                );
            } else {
                handleCanvasDrop({
                    mediaType: mediaType,
                    src: src,
                });
            }
        }
    }

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // Show available Videos

    return (
        <Grid container xs={12} justifyContent={"center"} alignContent={"flex-start"} sx={{ height: "100%", ...sx }}>
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video search */}
            <Grid item xs={11}>
                <VTTextField
                    placeholder={"Search Videos"}
                    sx={{
                        height: "60px",
                        borderRadius: "10px",
                        marginTop: "40px",
                    }}
                    value={searchValue}
                    onChange={(textInput: string) => setSearchValue(textInput)}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Filters */}
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    height: "80px",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>All Results</Typography>
                    </VTButton>
                </Grid>
                <Grid item xs={4}>
                    {scenarioVideos && (
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? "3px solid rgb(150, 28, 255)" : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography>Scenario Videos</Typography>
                        </VTButton>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? "3px solid rgb(150, 28, 255)" : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography>Upload Videos</Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {activeButton === 0 && (
                <>
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}

                    {videosLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!videos || videos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoVideos} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {videos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <>
                                            {videos
                                                ?.filter(
                                                    (item: IMediaItem) =>
                                                        searchValue === "" ||
                                                        item
                                                            .name!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()) ||
                                                        item
                                                            .description!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()),
                                                )
                                                .map((item: IMediaItem, index: number) => (
                                                    <VTVideoDraggable
                                                        item={item}
                                                        organisation={organisation}
                                                        localHandleDrag={localHandleDrag}
                                                        handleDoubleClick={handleDoubleClick}
                                                    />
                                                ))}
                                        </>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Video upload */}
            {activeButton === 1 && (
                <>
                    <div onDrop={handleDrop} onDragOver={preventDefaults} style={{ padding: "20px" }}>
                        {selectedVideo ? (
                            <Grid container>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <VTTextField
                                        value={videoName}
                                        onChange={(value) => setVideoName(value)}
                                        disabled={mediaUploading || mediaUploadCompleted}
                                    />
                                </Grid>
                                <Grid xs={12} sx={{ margin: "10px 5px" }}>
                                    <video width="100%" height="100%" controls>
                                        <source src={URL.createObjectURL(selectedVideo)} type="video/mp4" />
                                    </video>
                                </Grid>
                                {preSignedUrlLoading ? (
                                    <Grid
                                        xs={12}
                                        sx={{
                                            margin: "10px 5px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Grid>
                                ) : (
                                    <>
                                        {mediaUploading || mediaUploadCompleted ? (
                                            <>
                                                {mediaUploadError ? (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <Typography
                                                                style={{
                                                                    color: "red",
                                                                }}
                                                                align="center"
                                                            >
                                                                Error Uploading Video
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={"secondary"}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                Cancel
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid
                                                            xs={12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <LinearProgress
                                                                variant="determinate"
                                                                value={mediaUploadPercentage}
                                                            />
                                                        </Grid>
                                                        {mediaUploadCompleted && (
                                                            <Grid
                                                                xs={6}
                                                                sx={{
                                                                    margin: "10px 0",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    padding: "2px",
                                                                }}
                                                            >
                                                                <VTButton
                                                                    color={"secondary"}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        setSelectedVideo(null);
                                                                        setVideoId(uuid());
                                                                        setVideoName("");
                                                                        dispatch(setMediaUploadCleanUp());
                                                                    }}
                                                                >
                                                                    Upload Another
                                                                </VTButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            xs={mediaUploadCompleted ? 6 : 12}
                                                            sx={{
                                                                margin: "10px 0",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                padding: "2px",
                                                            }}
                                                        >
                                                            <VTButton
                                                                color={mediaUploadCompleted ? "primary" : "secondary"}
                                                                fullWidth={true}
                                                                onClick={() => handleUploadCleanUp()}
                                                            >
                                                                {mediaUploadCompleted ? "Done" : "Cancel"}
                                                            </VTButton>
                                                        </Grid>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton
                                                        color={"secondary"}
                                                        onClick={() => setSelectedVideo(null)}
                                                        fullWidth={true}
                                                    >
                                                        Cancel
                                                    </VTButton>
                                                </Grid>
                                                <Grid
                                                    xs={6}
                                                    sx={{
                                                        margin: "10px 0",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        padding: "2px",
                                                    }}
                                                >
                                                    <VTButton onClick={() => handleVideoUpload()} fullWidth={true}>
                                                        upload
                                                    </VTButton>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                //TODO: user this as an overlay
                                onDragEnter={() => setHighlight(true)}
                                onDragLeave={() => setHighlight(false)}
                                sx={{
                                    border: highlight ? "3px dashed #7315ce" : "1px dashed #f7f7f7",
                                    borderRadius: "15px",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <img src={"/media/images/upload.svg"} alt="upload media" width={"50%"} />
                                </Grid>
                                <Grid xs={12}>
                                    <Typography align="center">Drop or choose Video to upload</Typography>

                                    {selectedVideoError && (
                                        <Typography align="center" sx={{ color: "red" }}>
                                            File types accepted: MP4
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: "50px 0px",
                                    }}
                                >
                                    <input
                                        accept="video/mp4"
                                        type="file"
                                        id="file"
                                        ref={inputFileRef}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleSelect(e)}
                                    />
                                    <VTButton
                                        onClick={() => {
                                            if (inputFileRef && inputFileRef.current) {
                                                inputFileRef.current.click();
                                            }
                                        }}
                                    >
                                        Choose File
                                    </VTButton>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </>
            )}

            {activeButton === 2 && (
                <>
                    {" "}
                    {/*-----------------------------------------------------------------------------------------*/}
                    {/* Video list */}
                    {scenarioVideosLoading ? (
                        <Grid container>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Skeleton sx={{ height: "300px", margin: "10px" }} />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioVideos || scenarioVideos.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"}>
                                    <img src={NoVideos} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first video file to the scenario
                                    </VTTypography>
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    container
                                    sx={{
                                        marginTop: "20px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        height: "calc(100vh - 300px)",
                                        width: "100%",
                                    }}
                                >
                                    {scenarioVideos?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <>
                                            {scenarioVideos
                                                ?.filter(
                                                    (item: IMediaItem) =>
                                                        searchValue === "" ||
                                                        item
                                                            .name!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()) ||
                                                        item
                                                            .description!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()),
                                                )
                                                .sort((a: IMediaItem, b: IMediaItem) => {
                                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                })
                                                .map((item: IMediaItem) => (
                                                    <>
                                                        {(updateLoading || deleteLoading) &&
                                                        currentItemUpdate == item.id ? (
                                                            <Grid item xs={6}>
                                                                <Skeleton
                                                                    sx={{
                                                                        height: "300px",
                                                                        margin: "10px",
                                                                        marginBlock: "-25px",
                                                                        marginTop: "-55px",
                                                                    }}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            <VTVideoDraggable
                                                                key={item.id}
                                                                item={item}
                                                                organisation={organisation}
                                                                localHandleDrag={localHandleDrag}
                                                                handleDoubleClick={handleDoubleClick}
                                                                addFunction={() => {
                                                                    setCurrentItemUpdate(item.id);
                                                                    updateAllScenarioMedia({
                                                                        id: userId,
                                                                        media_type: EMedia.VIDEO,
                                                                        media_ids: [item.id],
                                                                    });
                                                                }}
                                                                deleteFunction={() => {
                                                                    setCurrentItemUpdate(item.id);
                                                                    deleteAllScenarioMedia({
                                                                        mediaType: EMedia.VIDEO,
                                                                        collectionId: scenarioId!,
                                                                        mediaId: item.id,
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </>
                                                ))}
                                        </>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
