import { useState } from "react";
import { Grid, Paper, SxProps, Tooltip, Typography } from "@mui/material";
import ReactAudioPlayer from "react-audio-player";
import { EDragContentOptions } from "../../models/hotspotDrag.models";

import AudioDrag from "../../assets/images/AudioDrag.png";
import { AddRounded, DeleteRounded } from "@mui/icons-material";

export interface VTAudioItemProps {
    audioItemSrc: string;
    onHandleDragStart?: (mediaType: EDragContentOptions, src: string) => void;
    onHandleDragStop?: () => void;
    audioItemTitle?: string;
    sx?: SxProps;
    handleDoubleClick: (mediaType: EDragContentOptions, src: string) => void;
    addFunction?: any;
    deleteFunction?: any;
}

export function VTAudioItem({
    onHandleDragStart,
    onHandleDragStop,
    audioItemSrc,
    audioItemTitle,
    sx,
    handleDoubleClick,
    addFunction,
    deleteFunction,
}: VTAudioItemProps) {
    const [dragging, setDragging] = useState<boolean>(false);

    function localHandleDrag(dragged: boolean, mediaType?: EDragContentOptions, src?: string) {
        if (dragged && onHandleDragStart && mediaType && src) {
            setDragging(true);
            onHandleDragStart(mediaType, src);
        } else if (!dragged && onHandleDragStop) {
            setDragging(false);
            onHandleDragStop();
        }
    }

    return (
        <Paper
            sx={{
                cursor: "pointer",
                backgroundColor: "#393939",
                padding: "10px",
                paddingTop: "6px",
                display: "inline-block",
                borderRadius: "30px",
                width: "100%",
                position: "relative",
                height: "95px",
                ...sx,
            }}
            draggable={onHandleDragStart ? true : false}
            onDragStart={(e) => {
                localHandleDrag(true, EDragContentOptions.AUDIO, audioItemSrc);
            }}
            onDragEnd={() => localHandleDrag(false)}
            title={audioItemTitle}
            onDoubleClick={() => handleDoubleClick(EDragContentOptions.AUDIO, audioItemSrc)}
        >
            <Typography
                sx={
                    addFunction || deleteFunction
                        ? { marginBottom: "5px", marginRight: "25px" }
                        : { marginBottom: "5px" }
                }
                textAlign={"center"}
            >
                {audioItemTitle}
            </Typography>

            <Grid
                item
                xs={12}
                sx={{
                    position: "absolute",
                    top: 5,
                    right: 15,
                    userSelect: "none",
                    height: "35px",
                    zIndex: 10000,
                }}
            >
                {deleteFunction && (
                    <Tooltip title={"Delete from scenario media"}>
                        <DeleteRounded
                            onClick={deleteFunction}
                            sx={{
                                backgroundColor: "rgba(22,22,22,0.8)",
                                borderRadius: "50%",
                                cursor: "pointer",
                            }}
                        />
                    </Tooltip>
                )}
                {addFunction && (
                    <Tooltip title={"Add to your media"}>
                        <AddRounded
                            onClick={addFunction}
                            sx={{
                                backgroundColor: "rgba(22,22,22,0.8)",
                                borderRadius: "50%",
                                cursor: "pointer",
                                marginLeft: "5px",
                            }}
                        />
                    </Tooltip>
                )}
            </Grid>

            <img
                id="audio-drop-img"
                src={AudioDrag}
                draggable={true}
                onDragStart={(e) => {
                    // console.log("on drag start target", e.target.src);
                    e.dataTransfer.setData("mediaType", "audio");
                    // @ts-ignore
                    // e.dataTransfer.setData("mediaSrc", e.target.src);
                }}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    borderRadius: "35px",
                    width: "100%",
                    height: "100%",
                    opacity: dragging ? 1 : 0,
                }}
            />

            <ReactAudioPlayer
                style={{
                    position: "absolute",
                    width: "calc(100% - 30px)",
                    top: "30px",
                    bottom: "10px",
                    left: "15px",
                    right: "15px",
                    cursor: "auto",
                }}
                controls
                title={audioItemTitle}
                src={audioItemSrc}
            />
        </Paper>
    );
}
