//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
// Material ui Imports
// Custom components imports (and hooks and helpers)
// Media imports

import { Grid, LinearProgress, Slider, SxProps, TextField, Tooltip } from "@mui/material";
import { VTButton, VTTypography } from "@virtus-tech-repository/virtus-tech-repository";

import PauseRounded from "@mui/icons-material/PauseRounded";
import PlayArrowRounded from "@mui/icons-material/PlayArrowRounded";
import FastRewindRounded from "@mui/icons-material/FastRewindRounded";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import {
    AddRounded,
    CancelRounded,
    SaveRounded,
    DeleteRounded,
    VolumeOffRounded,
    VolumeUpRounded,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { IHotspot } from "../../models/hotspot.models";
import { IScene } from "../../models/scenario.models";
import { setCurrentHotspot } from "../../store/slices/current.slice";
import { useDispatch } from "react-redux";
import { getTimeElapsedInStopWatchFormatString } from "../../utils/getTimeElapsedInStopWatchFormattedString";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
// Optional variables towards the bottom
interface IProps {
    videoCurrentTime: number;
    videoCurrentState: string | undefined;
    setVideoCurrentState: any;
    video: HTMLVideoElement;
    videoNextQuestion: IHotspot | undefined;
    getNextQuestion: (
        time: number,
        hotspots: IHotspot[],
        setVideoNextQuestion: (nextQuestion: IHotspot | undefined) => void,
    ) => void;
    setVideoNextQuestion: (videoNextQuestion: IHotspot | undefined) => void;
    handleAddStopPoint: (sceneId: string, time: number, setVideoNextQuestion: any) => void;
    scene: IScene;
    setTimelineMode: (timelineMode: "Scenes" | "Timeline" | "Stepper") => void;
    handleDeleteStopPoint: (sceneId: string, hotspotId: string) => void;
    currentInterfaceTrue: boolean;
    sx?: SxProps;
    currentQuestionsTimes: number[];
    bottomDrawWidth: number;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export default function VTCreatorVideoControls({
    videoCurrentTime,
    videoCurrentState,
    setVideoCurrentState,
    video,
    videoNextQuestion,
    getNextQuestion,
    setVideoNextQuestion,
    handleAddStopPoint,
    scene,
    setTimelineMode,
    handleDeleteStopPoint,
    sx,
    currentInterfaceTrue,
    currentQuestionsTimes,
    bottomDrawWidth,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const dispatch = useDispatch();

    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##

    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [currentTimeEditing, setCurrentTimeEditing] = useState<boolean>(false);
    const [currentTimeEditInput, setCurrentTimeEditInput] = useState<number>(videoCurrentTime);
    const [currentlyMuted, setCurrentMuted] = useState<boolean>(false);
    const [previousQuestionTime, setPreviousQuestionTime] = useState<number>(0);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (video.muted !== currentlyMuted) {
            setCurrentMuted(video.muted);
        }
    }, [video.muted]);

    useEffect(() => {
        if (videoNextQuestion) {
            let currentQuestionIndex = currentQuestionsTimes.findIndex(
                (element: number) => element === videoNextQuestion.contents.preClickContent.question?.time,
            );

            if (currentQuestionIndex === 0) {
                setPreviousQuestionTime(0);
            } else {
                setPreviousQuestionTime(currentQuestionsTimes[currentQuestionIndex - 1]);
            }
        } else if (currentQuestionsTimes.length > 0) {
            setPreviousQuestionTime(currentQuestionsTimes[currentQuestionsTimes.length - 1]);
        } else {
            setPreviousQuestionTime(0);
        }
    }, [videoNextQuestion]);

    let BufferedZones = () => {
        return (
            <>
                {Array.from(Array(video.buffered.length), (e, i) => {
                    return (
                        <div
                            style={{
                                position: "absolute",
                                left: `${(video.buffered.start(i) / video.duration) * 100}%`,
                                right: `${100 - (video.buffered.end(i) / video.duration) * 100}%`,
                                backgroundColor: "#ae45ff",
                                height: "4px",
                                top: "-2px",
                            }}
                        />
                    );
                })}
            </>
        );
    };

    let SliderZone = () => {
        return (
            <Slider
                sx={{
                    width: "calc(100% - " + 100 + "px)",
                    zIndex: 1000,
                }}
                value={videoCurrentTime}
                max={video.duration}
                onChange={(_, value) => {
                    video.currentTime = value as number;
                    getNextQuestion(value as number, scene.hotspots, setVideoNextQuestion);
                    if (videoCurrentState === "Question") {
                        setVideoCurrentState("Paused");
                    }
                    dispatch(
                        setCurrentHotspot({
                            currentHotspot: undefined,
                            open: false,
                        }),
                    );
                }}
                step={0.001}
            />
        );
    };

    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    //-------------------------------------------------------------------------------------------------
    // ## RETURN ##
    // If page is large render all buttons

    return (
        <Grid
            container
            sx={{
                ...sx,
                position: "absolute",
                bottom: bottomDrawWidth === 0 ? "35px" : "12px",
                width: currentInterfaceTrue ? "calc(100% - 400px)" : "100%",
                zIndex: 1000,
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "-30px",
                    borderRadius: "15px",
                }}
            >
                {video.buffered.length >= 1 && (
                    <Grid
                        sx={{
                            width: "calc(100% - " + 100 + "px)",
                            zIndex: 800,
                            position: "relative",
                        }}
                    >
                        <BufferedZones />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <SliderZone />
            </Grid>

            {/*-------------------------------------------------------------------------------*/}
            {/* Rewind button */}
            <Grid item container xs={12} alignItems={"center"} justifyContent={"center"}>
                <VTButton
                    sx={{ marginRight: "5px" }}
                    type={"icon"}
                    onClick={() => {
                        video.currentTime = videoCurrentTime - 5;
                        getNextQuestion(videoCurrentTime - 5, scene.hotspots, setVideoNextQuestion);
                        if (videoCurrentState === "Question") {
                            setVideoCurrentState("Paused");
                        }
                    }}
                    disabled={videoCurrentTime <= 5 && true}
                >
                    <FastRewindRounded />
                </VTButton>

                {/*-----------------------------------------------------------------------------*/}
                {/* Play / Pause controls */}
                {videoCurrentState === "Playing" ? (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            video.pause();
                            setVideoCurrentState("Paused");
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            );
                        }}
                    >
                        <PauseRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        sx={{ marginRight: "5px" }}
                        type={"icon"}
                        onClick={() => {
                            if (videoCurrentState === "Question") {
                                getNextQuestion(videoCurrentTime + 0.01, scene.hotspots, setVideoNextQuestion);
                            } else {
                                getNextQuestion(videoCurrentTime, scene.hotspots, setVideoNextQuestion);
                            }
                            setVideoCurrentState("Playing");
                            video.play();
                            dispatch(
                                setCurrentHotspot({
                                    currentHotspot: undefined,
                                    open: false,
                                }),
                            );
                        }}
                    >
                        <PlayArrowRounded />
                    </VTButton>
                )}

                {/*-----------------------------------------------------------------------------*/}
                {/* Fast forward controls */}
                <VTButton
                    sx={{ marginRight: "27px" }}
                    type={"icon"}
                    onClick={() => {
                        video.currentTime = videoCurrentTime + 5;
                        getNextQuestion(videoCurrentTime + 5, scene.hotspots, setVideoNextQuestion);
                        if (videoCurrentState === "Question") {
                            setVideoCurrentState("Paused");
                        }
                        dispatch(
                            setCurrentHotspot({
                                currentHotspot: undefined,
                                open: false,
                            }),
                        );
                    }}
                    disabled={video.duration - videoCurrentTime <= 5 && true}
                >
                    <FastForwardRounded />
                </VTButton>

                {/*-----------------------------------------------------------------------------*/}
                {/* Current time / duration */}

                {currentTimeEditing ? (
                    <>
                        <form
                            onSubmit={() => {
                                video.pause();
                                video.currentTime = currentTimeEditInput;
                                getNextQuestion(currentTimeEditInput, scene.hotspots, setVideoNextQuestion);
                                setVideoCurrentState("Paused");
                                setCurrentTimeEditing(false);
                                dispatch(
                                    setCurrentHotspot({
                                        currentHotspot: undefined,
                                        open: false,
                                    }),
                                );
                            }}
                        >
                            <TextField
                                sx={{
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                    height: "33px",
                                    width: "130px",
                                    borderRadius: "15px",
                                }}
                                autoFocus
                                type={"number"}
                                inputProps={{
                                    style: {
                                        // Set the content width; effects functionality not styling
                                        height: "1px",
                                        border: "none",
                                    },
                                }}
                                value={currentTimeEditInput}
                                onChange={(e: any) => {
                                    setCurrentTimeEditInput(e.target.value);
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                }}
                            />
                            <CancelRounded
                                sx={{
                                    marginTop: "10px",
                                }}
                                color={"error"}
                                onClick={() => {
                                    setCurrentTimeEditInput(videoCurrentTime);
                                    setCurrentTimeEditing(false);
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                }}
                            />
                            <SaveRounded
                                sx={{
                                    marginTop: "10px",
                                }}
                                color={"success"}
                                type={"submit"}
                                onClick={() => {
                                    video.pause();
                                    video.currentTime = currentTimeEditInput;
                                    getNextQuestion(currentTimeEditInput, scene.hotspots, setVideoNextQuestion);
                                    setVideoCurrentState("Paused");
                                    setCurrentTimeEditing(false);
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                }}
                            />
                        </form>
                    </>
                ) : (
                    <div
                        style={{
                            fontFamily: "Digit",
                            fontSize: "0.9em",
                        }}
                        // TODO; re-enable time seeking
                        // onClick={() => {
                        //     video.pause();
                        //     setVideoCurrentState("Paused");
                        //     setCurrentTimeEditInput(videoCurrentTime);
                        //     setCurrentTimeEditing(true);
                        // }}
                    >
                        {getTimeElapsedInStopWatchFormatString(videoCurrentTime * 1000)}
                    </div>
                )}

                <span
                    style={{
                        fontSize: "1.3",
                        marginLeft: "6px",
                        marginRight: "2px",
                        marginTop: "2px",
                    }}
                >
                    /
                </span>

                <div style={{ fontFamily: "Digit", fontSize: "0.9em" }}>
                    {video.duration ? getTimeElapsedInStopWatchFormatString(video.duration * 1000) : "00:00:00"}
                </div>

                {video && currentlyMuted ? (
                    <VTButton
                        onClick={() => {
                            video.muted = false;
                            localStorage.setItem("video_muted", "false");
                            setCurrentMuted(false);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeOffRounded />
                    </VTButton>
                ) : (
                    <VTButton
                        onClick={() => {
                            video.muted = true;
                            localStorage.setItem("video_muted", "true");
                            setCurrentMuted(true);
                        }}
                        sx={{ marginLeft: "15px" }}
                        type={"icon"}
                    >
                        <VolumeUpRounded />
                    </VTButton>
                )}
                {videoNextQuestion && videoCurrentState === "Question" ? (
                    <VTButton
                        sx={{
                            marginLeft: "30px",
                        }}
                        onClick={() => {
                            setVideoCurrentState("Paused");
                            let tempTime = videoNextQuestion.contents.preClickContent.question?.time!;
                            handleDeleteStopPoint(scene.id, videoNextQuestion.id);
                            video.currentTime = tempTime;
                            getNextQuestion(videoCurrentTime + 0.01, scene.hotspots, setVideoNextQuestion);
                        }}
                    >
                        <DeleteRounded sx={{ marginRight: "10px" }} />
                        Delete Stop Point
                    </VTButton>
                ) : (
                    <Tooltip
                        title={
                            videoCurrentTime <= 0.1
                                ? "Please let the video play before creating a stop point"
                                : "Please increase the time between stop points to create"
                        }
                        disableHoverListener={
                            !(
                                videoCurrentTime <= 0.5 ||
                                (videoNextQuestion
                                    ? videoCurrentTime >=
                                      videoNextQuestion.contents.preClickContent.question!.time! - 0.2
                                    : false) ||
                                (previousQuestionTime ? videoCurrentTime <= previousQuestionTime! + 0.2 : false)
                            )
                        }
                    >
                        <Grid item>
                            <VTButton
                                sx={{ marginLeft: "30px" }}
                                onClick={() => {
                                    video.pause();
                                    handleAddStopPoint(scene.id, videoCurrentTime, setVideoNextQuestion);
                                    setVideoCurrentState("Question");
                                    dispatch(
                                        setCurrentHotspot({
                                            currentHotspot: undefined,
                                            open: false,
                                        }),
                                    );
                                    setTimelineMode("Timeline");
                                }}
                                disabled={
                                    videoCurrentTime <= 0.5 ||
                                    (videoNextQuestion
                                        ? videoCurrentTime >=
                                              videoNextQuestion.contents.preClickContent.question!.time! - 0.2 &&
                                          videoCurrentTime < videoNextQuestion.contents.preClickContent.question!.time!
                                        : false) ||
                                    (previousQuestionTime
                                        ? videoCurrentTime <= previousQuestionTime! + 0.2 &&
                                          videoCurrentTime > previousQuestionTime!
                                        : false)
                                }
                            >
                                <AddRounded sx={{ marginRight: "10px" }} /> Add Stop Point
                            </VTButton>
                        </Grid>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
}
