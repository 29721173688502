import CreatorContentWrapper from "../layouts/CreatorContentWrapper";
import { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setCurrentPage, setEditHotspotDialogIsCurrentlyOpen } from "../../store/slices/current.slice";

// Only display layouts in pages
export default function Creator() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("creator"));
        // on component dismount
        return () => {
            dispatch(setEditHotspotDialogIsCurrentlyOpen(false));
        };
    }, []);

    return (
        <>
            {/* Page content */}
            <CreatorContentWrapper />
        </>
    );
}
